<template>
  <BittsLoading is-loading />
</template>

<script setup lang="ts">
import { BittsLoading } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { crossbeamApi } from '@/api';
import { getAndClearLSNextUrl } from '@/local_storage';
import { useRootStore } from '@/stores/RootStore';

useHead({
  title: 'PartnerStack Callback - Crossbeam',
});

const { loadUserProfile } = useRootStore();

const route = useRoute();
const router = useRouter();
onMounted(async () => {
  const query = {
    code: route.query.code as string,
    state: route.query.state as string,
  };
  // Successful OAuth
  if (query.code) {
    await crossbeamApi.POST('/v0.1/partnerstack/auth/success', {
      params: { query },
    });
    await loadUserProfile();
  }

  const nextFromLS = getAndClearLSNextUrl();
  router.push(nextFromLS || { name: 'integrations' });
});
</script>
