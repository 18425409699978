<template>
  <BittsTooltip
    v-if="displayPermission.level != 'manage'"
    class="c-permission-indicator"
  >
    <slot>
      <div
        class="border-2 border-neutral-100 rounded text-sm py-6 px-16 text-brand-navy flex flex-none"
        data-testid="permission-indicator-copy"
      >
        {{ displayPermission.displayLevel }}
        <FontAwesomeIcon
          :icon="['fak', 'question-circle']"
          :style="{ height: '14px', width: '14px', color: 'currentColor' }"
          class="text-neutral-400 mt-2 ml-4 cursor-pointer"
        />
      </div>
    </slot>
    <template #title>
      {{ displayPermission.description }}
    </template>
  </BittsTooltip>
  <div v-else>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { BittsTooltip } from '@crossbeam/bitts';
import { Nullable } from '@crossbeam/types';

import { computed } from 'vue';

import useAuth from '@/composables/useAuth';

const { resource = '' } = defineProps<{ resource: string }>();

const DISPLAY_PERMISSIONS: Record<
  string,
  {
    displayLevel: string;
    description: Nullable<string>;
  }
> = {
  manage: {
    displayLevel: 'Manage',
    description: null,
  },
  participate: {
    displayLevel: 'Participate only',
    description:
      'You have "participate only" access to this resource based on your user role. ' +
      'Contact an admin to request additional access.',
  },
  view: {
    displayLevel: 'View only',
    description:
      'You have "view only" access to this resource based on your user role. ' +
      'Contact an admin to request additional access.',
  },
  'view-only': {
    displayLevel: 'View only',
    description:
      'You have "view only" access to this resource based on your user role. ' +
      'Contact an admin to request additional access.',
  },
};

const { resourcePermission } = useAuth();

const displayPermission = computed(() => {
  const permission = resourcePermission(resource);
  const level = permission?.level || '';
  return {
    level,
    displayLevel: DISPLAY_PERMISSIONS[level]?.displayLevel,
    description: DISPLAY_PERMISSIONS[level]?.description,
  };
});
</script>

<style lang="pcss">
.c-permission-indicator {
  @apply cursor-pointer;
}
</style>
