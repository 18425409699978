import { computed } from 'vue';
import type { LocationQuery } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';

import useAuth from './useAuth';
import useMinimalApp from './useMinimalApp';

export function useRouterWrapper() {
  const router = useRouter();
  const route = useRoute();
  const { isMinimalView } = useMinimalApp();
  const { currentOrg } = useAuth();

  type RouteResult = {
    name?: string;
    query?: LocationQuery;
    params?: Record<string, string | number>;
    hash?: string;
  };
  const buildExistingRoute = ({
    query = {},
    name,
    params = {},
    hash = undefined,
  }: RouteResult) => ({
    name: name || undefined,
    params: Object.keys(params).length ? params : undefined,
    query: { ...route.query, ...query },
    hash,
  });

  const pushWithExistingQuery = async (
    { query = {}, name = '', params = {} }: RouteResult,
    ...queryParamsToRemove: (string | number)[]
  ) => {
    const result = buildExistingRoute({ name, query, params });

    const resultQuery = result.query ?? {};
    const filteredQuery = Object.keys(resultQuery).reduce((acc, key) => {
      if (!queryParamsToRemove.includes(key) && resultQuery[key]) {
        acc[key] = resultQuery[key];
      }
      return acc;
    }, {} as LocationQuery);

    result.query = filteredQuery || {};

    await router.push(result);
  };

  function navigateOrOpen(routeInfo: Partial<RouteResult>) {
    if (!isMinimalView) {
      router.push(routeInfo);
      return;
    }

    const newRoute = {
      ...routeInfo,
      query: {
        ...routeInfo.query,
        organization_id: currentOrg.value.id,
      },
    };

    // noopener needed to make sure minimalView session storage is reset
    window.open(router.resolve(newRoute).href, '_blank', 'noopener');
  }

  const baseRoute = computed(() => route.matched?.at(1)?.name);

  return {
    pushWithExistingQuery,
    buildExistingRoute,
    navigateOrOpen,
    baseRoute,
    route,
    router,
  };
}
