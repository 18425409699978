<template>
  <BittsTooltip :mount-to-body="true" class="reports-table-cell__wrapper">
    <BittsTag :color="REPORT_TYPE_TO_TAG[params.value.reportType]?.color">
      {{ REPORT_TYPE_TO_TAG[params.value.reportType]?.text }}
    </BittsTag>
    <template #title>
      {{ reportTypeToTooltipText[params.value.reportType] }}
    </template>
  </BittsTooltip>
</template>

<script setup lang="ts">
import { BittsTag, BittsTooltip } from '@crossbeam/bitts';

import { ICellRendererParams } from '@ag-grid-community/core';
import { computed } from 'vue';

import {
  ALL_PARTNERS_TYPE,
  CUSTOM_TYPE,
  GREENFIELD_TYPE,
  OWN_GREENFIELD,
  PARTNER_TAGS_TYPE,
  POTENTIAL_REVENUE_TYPE,
  SINGLE_OVERLAPS_TYPE,
} from '@/constants/reports';

const { params } = defineProps<{ params: ICellRendererParams }>();

const REPORT_TYPE_TO_TAG: Record<
  string,
  {
    color: string;
    text: string;
  }
> = {
  [ALL_PARTNERS_TYPE]: { color: 'neutral-gradient', text: 'All Partners' },
  [CUSTOM_TYPE]: { color: 'neutral-gradient', text: 'Custom' },
  [GREENFIELD_TYPE]: { color: 'success-gradient', text: 'Greenfield' },
  [PARTNER_TAGS_TYPE]: { color: 'info-gradient', text: 'Partner Tag' },
  [POTENTIAL_REVENUE_TYPE]: { color: 'upsell', text: 'Pipeline' },
  [SINGLE_OVERLAPS_TYPE]: { color: 'info-gradient', text: 'Overlaps' },
};

const reportTypeToTooltipText = computed<Record<string, string>>(() => {
  return {
    [ALL_PARTNERS_TYPE]:
      'Your populations mapped against any standard populations of all partners',
    [CUSTOM_TYPE]: 'Custom mapping of your populations vs. partners',
    [GREENFIELD_TYPE]:
      params.value.report.consolidated_report_type === OWN_GREENFIELD
        ? 'Your accounts that do not overlap with your partners’'
        : 'Partners’ accounts that do not overlap with your accounts',
    [PARTNER_TAGS_TYPE]:
      'Your populations mapped against any standard populations of partners with a specific partner tag',
    [POTENTIAL_REVENUE_TYPE]:
      'Your populations mapped against populations of partners to find highest ROI opportunities',
    [SINGLE_OVERLAPS_TYPE]:
      'Your populations mapped against populations of a single partner',
  };
});
</script>
