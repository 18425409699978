<template>
  <div class="overlaps-table-overlaps-cell">
    <div class="overlaps-table-overlaps-cell__overlaps">
      {{ overlapNumber }}
    </div>
    <button
      class="overlaps-table-overlaps-cell__link-to-sharing"
      @click="viewSharing"
      type="button"
    >
      View Sharing
    </button>
  </div>
</template>

<script setup lang="ts">
import { ICellRendererParams } from '@ag-grid-community/core';
import Humanize from 'humanize-plus';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const { params } = defineProps<{ params: ICellRendererParams }>();

const overlaps = ref(0);
const id = ref(0);

const overlapNumber = computed(() =>
  overlaps.value ? Humanize.intComma(overlaps.value) : '--',
);

onMounted(() => {
  overlaps.value = params.data.overlaps || 0;
  id.value = params.data.org.id;
});

const router = useRouter();
async function viewSharing() {
  await router.push({
    name: 'partner_details',
    params: { partner_org_id: id.value },
    query: { tab: 'sharing' },
  });
}
</script>

<style lang="pcss">
.overlaps-table-overlaps-cell {
  @apply h-full w-full flex items-center justify-between;
}
.overlaps-table-overlaps-cell__link-to-sharing {
  @apply text-sm text-neutral-text-button font-bold;
}
.overlaps-table-overlaps-cell__overlaps {
  @apply text-neutral-text text-m;
}
</style>
