<template>
  <BittsLoading :with-transition="false" :is-loading="loading">
    <div class="c-customize-fields">
      <div class="c-customize-fields__content">
        <div class="c-customize-fields__content__templates">
          <h2 class="text-neutral-text-strong font-bold"> Data Presets </h2>
          <p class="text-neutral-text">
            Quickly add data that corresponds to direct use cases and Crossbeam
            functionality.
          </p>
          <div class="flex flex-col gap-8 pb-24 mt-16">
            <TemplateSelector
              v-for="template in injectedTemplates"
              :key="`${template.type}`"
              :template="template"
              :feed="props.feed"
              :crm="crm"
              :active-templates="activeTemplates"
              :satisfied-templates="satisfiedTemplates"
              :unsatisifed-template-metadata="unsatisfiedTemplateMetadata"
              @select="handleAddTemplate"
            />
          </div>
        </div>
        <SyncTree
          :field-limit="fieldLimit"
          :feed-id="props.feed.id"
          :objects="objects"
          :active-templates="activeTemplates"
          :source-field-map="sourceFieldMap"
          :lookup-field-ids="lookupFieldIds"
          :checked-lookup-ids="checkedLookupIds"
          :checked-reference-ids="checkedReferenceIds"
          :reference-field-ids="referenceFieldIds"
          :all-checked-field-ids="allCheckedFieldIds"
          @update="handleChangedField"
          @bulk-update="handleChangedFields"
        />
      </div>
      <div class="c-customize-fields__footer">
        <BittsButton
          size="large"
          text="Go Back"
          :left-icon="['fak', 'arrow-left']"
          data-testid="back-button"
          type="neutral"
          @click="handleGoBack"
        />
        <component :is="overFieldLimit ? BittsTooltip : 'div'">
          <div>
            <BittsButton
              size="large"
              text="Review Fields"
              data-testid="customize-review-button"
              :disabled="overFieldLimit"
              @click="handleReviewCustomization"
            />
          </div>
          <template #title>
            You may only sync up to {{ fieldLimit }} fields at a time
          </template>
        </component>
      </div>
    </div>
  </BittsLoading>
</template>
<script setup>
import { BittsButton, BittsTooltip } from '@crossbeam/bitts';

import { uniq } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted, provide, ref, watch } from 'vue';

import SyncTree from '@/components/data-sources/data-templates/SyncTree.vue';
import TemplateSelector from '@/components/data-sources/data-templates/TemplateSelector.vue';

import { crossbeamApi } from '@/api';
import {
  MAX_FIELDS,
  SOURCE_STATUS_MAP,
  getRequiredColumns,
  isInPopDefinition,
  isValidSourceField,
} from '@/constants/data_sources';
import {
  usePopulationsStore,
  usePotentialRevenueStore,
  useReportsStore,
  useSourcesStore,
} from '@/stores';

const props = defineProps({
  feed: {
    type: Object,
    required: true,
  },
  templates: {
    type: Array,
    required: true,
  },
  /* This is not relevant unless a user navigates backward */
  controlledCheckedKeys: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['review', 'back']);
const populations = ref([]);

const sourcesStore = useSourcesStore();
const sources = computed(() => sourcesStore.getSourcesByFeedId(props.feed.id));

const satisfiedTemplates = ref([]);
const loading = ref(true);
const requiredColumns = ref({});
const objects = ref([]);

const populationsStore = usePopulationsStore();
const { populations: storePopulations } = storeToRefs(populationsStore);

const reportsStore = useReportsStore();
const { refreshReportsStore } = reportsStore;
const { reports } = storeToRefs(reportsStore);

const potentialRevenueStore = usePotentialRevenueStore();
const piAmountColumnIdSet = computed(() => {
  const columnSettings = potentialRevenueStore.potentialRevenueColumnSettings;
  return new Set(Object.values(columnSettings));
});

/* Many of these data structures are lookups to let us be more efficient.
We populate them during the initial component mount */
/* We are injecting these dependencies to be able
to mock and manipulate them in tests and avoid prop drilling */
const injectedTemplates = ref([]);
provide('templates', injectedTemplates);
const templateToFieldMap = ref(
  props.templates.reduce((acc, t) => {
    acc[t.type] = [];
    return acc;
  }, {}),
);
provide('templateToFieldMap', templateToFieldMap);
const unsatisfiedTemplateMetadata = ref([]);
provide('unsatisfiedTemplateMetadata', unsatisfiedTemplateMetadata);
const crm = computed(() => props.feed.integration.type);
provide('crm', crm.value);

const sourceFieldMap = ref({});
const referenceFieldIds = ref([]);
const lookupFieldIds = ref([]);
const fieldLimit = ref(MAX_FIELDS);

onMounted(async () => {
  const promises = [
    refreshReportsStore(),
    potentialRevenueStore.readySync,
    sourcesStore.refreshSourcesStore(),
    crossbeamApi.GET('/v0.1/feeds/limit'),
  ];

  /* We allow some organizations to have more than 100 fields */
  const results = await Promise.all(promises);
  const { data } = results.at(-1);
  fieldLimit.value = data.syncable_field_limit;

  requiredColumns.value = await getRequiredColumns(props.feed);
  injectedTemplates.value = joinTemplatesWithRequiredFields(
    props.templates,
    requiredColumns.value,
    crm.value,
  ).filter((template) => Object.keys(template[crm.value]).length > 0);

  populations.value = storePopulations.value;

  /* Populate satisfied and unsatisfied template information */
  for (const template of injectedTemplates.value) {
    const missingTemplateInfo = getMissingTemplateInfo({
      template,
      sources: sources.value,
      crm: crm.value,
    });
    if (Object.keys(missingTemplateInfo).length === 0) {
      satisfiedTemplates.value.push(template.type);
    } else {
      unsatisfiedTemplateMetadata.value.push({
        type: template.type,
        missingTemplateInfo,
      });
    }
  }

  /* Populate IDs, lookup field IDs, and the template to field lookup map */
  const tempSet = new Set([]);
  for (const source of sources.value) {
    if (SOURCE_STATUS_MAP.missing.includes(source.status)) {
      tempSet.add(source);
    }
    for (const sourceField of source.fields) {
      if (!isValidSourceField(sourceField)) continue;
      tempSet.add(source);
      for (const template of injectedTemplates.value) {
        if (template[crm.value][source.table]?.includes(sourceField.column)) {
          templateToFieldMap.value[template.type].push(sourceField.id);
        }
      }
      if (sourceField.copies_source_field_id) {
        referenceFieldIds.value.push(sourceField.copies_source_field_id);
        lookupFieldIds.value.push(sourceField.id);
      }
    }
  }

  objects.value = Array.from(tempSet).sort();

  /* Calculate all attributes for each leaf */
  const allTemplateFields = Object.values(templateToFieldMap.value).reduce(
    (acc, ids) => {
      acc.push(...ids);
      return acc;
    },
    [],
  );
  sourceFieldMap.value = sources.value.reduce((acc, source) => {
    for (const sourceField of source.fields) {
      if (!isValidSourceField(sourceField)) continue;

      const filtersInSource = reports.value.reduce((acc, { filters, id }) => {
        for (const filter of filters) {
          if (source.id === filter.source_id)
            acc.push({ ...filter, report_id: id });
        }
        return acc;
      }, []);

      const processedField = processSourceField({
        sourceField,
        sourceId: source.id,
        populations: populations.value,
        object: source.table,
        requiredColumns: requiredColumns.value[source.table],
        referenceFieldIds: referenceFieldIds.value,
        potentialInfluenceAmountColumnIdSet: piAmountColumnIdSet.value,
        allTemplateFields,
        filtersInSource,
      });
      acc[processedField.id] = processedField;
    }
    return acc;
  }, {});

  loading.value = false;
});

const checkedLookupIds = computed(() => {
  return lookupFieldIds.value.filter(
    (id) => sourceFieldMap.value[id]?.isChecked,
  );
});

const checkedReferenceIds = computed(() => {
  return referenceFieldIds.value.filter(
    (id) => sourceFieldMap.value[id]?.isChecked,
  );
});

const allCheckedFieldIds = computed(() => {
  return Object.entries(sourceFieldMap.value).reduce(
    (acc, [id, sourceField]) => {
      if (sourceField.isChecked) acc.push(Number(id));
      return acc;
    },
    [],
  );
});

const overFieldLimit = computed(
  () => allCheckedFieldIds.value.length > fieldLimit.value,
);

const activeTemplates = computed(() => {
  return satisfiedTemplates.value.filter((type) => {
    const sourceFieldIds = templateToFieldMap.value[type];
    if (!sourceFieldIds || sourceFieldIds.length === 0) return false;
    for (const id of sourceFieldIds) {
      const sourceField = sourceFieldMap.value[id];
      if (!sourceField.isChecked) {
        return false;
      }
    }
    return true;
  });
});

/* This value is updated when a user changes views, and we need
to bulk update the sourceFieldMap. For instance, if they click "Customize"
after choosing a preselection, we need to propagate those changes
into our lookup structures */
const controlledCheckedKeys = computed(() => {
  return props.controlledCheckedKeys;
});
watch(controlledCheckedKeys, (newValue) => {
  for (let id of Object.keys(sourceFieldMap.value)) {
    id = Number(id);
    sourceFieldMap.value[id].isChecked = newValue.includes(id);
  }
});

function handleReviewCustomization() {
  emit('review', {
    newCheckedFields: allCheckedFieldIds.value,
    activeTemplates: activeTemplates.value,
  });
}

function handleAddTemplate({ type }) {
  const sourceFieldIds = templateToFieldMap.value[type];
  for (const id of sourceFieldIds) {
    const newSourceField = { ...sourceFieldMap.value[id], isChecked: true };
    sourceFieldMap.value[id] = newSourceField;
  }
}

/* Update the value in the source field map directly */
function handleChangedField({ sourceField }) {
  sourceFieldMap.value[sourceField.id] = sourceField;
}

function handleChangedFields({ sourceFields }) {
  for (const sourceField of sourceFields) {
    sourceFieldMap.value[sourceField.id] = sourceField;
  }
}

function handleGoBack() {
  emit('back');
}
</script>
<script>
/* Checks to see whether the fields/objects exist to satisfy a template */
export function getMissingTemplateInfo({ template, sources, crm }) {
  const t = template[crm];
  return Object.entries(t).reduce((acc, [object, columns]) => {
    const existingObject = sources.find(
      (source) =>
        source.table === object &&
        !SOURCE_STATUS_MAP.missing.includes(source.status),
    );
    if (!existingObject) {
      acc[object] = [];
      return acc;
    }

    const missingColumns = columns.filter(
      (column) => !fieldPresentAndSyncing(column, existingObject),
    );
    if (missingColumns.length > 0) {
      acc[object] = missingColumns;
      return acc;
    }

    return acc;
  }, {});
}

export function fieldPresentAndSyncing(column, existingObject) {
  return !!existingObject.fields.find((field) => field.column === column);
}

export function processSourceField({
  sourceField,
  populations,
  object,
  requiredColumns,
  referenceFieldIds,
  allTemplateFields,
  filtersInSource,
  potentialInfluenceAmountColumnIdSet,
}) {
  const isTemplateField = allTemplateFields.includes(sourceField.id);
  const isChecked = sourceField.is_visible && sourceField.is_filterable;
  const isLookupField =
    sourceField.relationship_id || sourceField.copies_source_field_id;
  const isReferenceField = referenceFieldIds.includes(sourceField.id);
  const isPotInfAmountField = potentialInfluenceAmountColumnIdSet.has(
    sourceField.id,
  );
  const searchName = sourceField.display_name.toLowerCase();
  const blockedPops = populations.filter((pop) =>
    isInPopDefinition(pop, sourceField.id),
  );
  const isRequiredForObject = requiredColumns.includes(sourceField.column);
  const reportFilters = filtersInSource.filter((filter) =>
    filter.filter_parts.some((part) => part.source_field_id === sourceField.id),
  );

  return {
    ...sourceField,
    reportFilters,
    object,
    blockedPops,
    isChecked,
    isTemplateField,
    isRequiredForObject,
    isLookupField,
    isReferenceField,
    isPotInfAmountField,
    searchName,
  };
}
/* This utility function adds the required fields for each object to the template
schemas. We need to do this so that when someone enables a template we not only
sync the fields it contains, but also any fields required for those objects to sync */
export function joinTemplatesWithRequiredFields(
  templates,
  requiredColumns,
  crm,
) {
  const newTemplates = JSON.parse(JSON.stringify(templates));
  for (const template of newTemplates) {
    for (const [object, columns] of Object.entries(requiredColumns)) {
      if (template[crm][object]) {
        const newFields = [...template[crm][object], ...columns];
        template[crm][object] = uniq(newFields);
      }
    }
  }
  return newTemplates;
}
</script>
<style lang="pcss" scoped>
.c-customize-fields__content {
  @apply pl-24 grid;
  border-top: 1px solid theme('colors.neutral.border');
  grid-template-columns: 1fr 2fr;
}

.c-customize-fields__content__templates {
  @apply pr-24 pt-16;
  border-right: 1px solid theme('colors.neutral.border');
  max-height: 70vh;
  overflow: auto;
}

.c-customize-fields__footer {
  border-top: 1px solid theme('colors.neutral.border');
  @apply flex justify-between px-24 pt-16;
}
</style>
