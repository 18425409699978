<template>
  <BittsPopover
    v-if="showButton"
    v-click-away="onClickAway"
    :show-menu="groupSelectorVisible"
    :destroy-on-close="true"
    class="report-filters__group-selector"
  >
    <BittsButton
      v-if="showButton"
      :active="groupSelectorVisible"
      size="x-small"
      text="New Object Filter"
      type="neutral"
      :variant="groupSelectorVisible ? 'fill' : 'outline'"
      :right-icon="['fas', 'angle-down']"
      @click="groupSelectorVisible = !groupSelectorVisible"
    />
    <template #content>
      <ReportFilterGroupSelector
        :is-own="isOwn"
        :source-list="sources"
        @field-chosen="onFilterGroupAdded"
      />
    </template>
  </BittsPopover>
</template>

<script setup>
import { BittsButton, BittsPopover } from '@crossbeam/bitts';

import { ref } from 'vue';
import { directive as vClickAway } from 'vue3-click-away';

import ReportFilterGroupSelector from '@/components/reports/ReportFilterGroupSelector.vue';

defineProps({
  showButton: {
    type: Boolean,
    default: true,
  },
  isOwn: {
    type: Boolean,
    default: true,
  },
  sources: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['filter-group-added']);

const groupSelectorVisible = ref(false);

function onClickAway() {
  groupSelectorVisible.value = false;
}
function onFilterGroupAdded(sourceInfo) {
  emit('filter-group-added', sourceInfo);
  groupSelectorVisible.value = false;
}
</script>
