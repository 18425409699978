import { uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { crossbeamApi } from '@/api';
import usePopulations from '@/composables/usePopulations';
import { MDM_TYPES } from '@/constants/mdm';
import {
  emptyPopulation,
  getPopulationTypeName,
} from '@/constants/standard_populations';
import { SharedField } from '@/interfaces/data_shares';
import { useFlashesStore, useSourcesStore } from '@/stores';
import {
  Combinator,
  EmptyPopulation,
  FilterPart,
  Population,
  StandardPopulationType,
} from '@/types/populations';
import { Source } from '@/types/sources';

import { FilterGroupType, FilterType } from '../types';

export default function ({ feedId }: { feedId: number }) {
  const route = useRoute();
  const flashesStore = useFlashesStore();
  const { allPopulations, getRecordNumber, populationsReady } =
    usePopulations();

  const loading = ref(true);
  const filterableSources = ref<Source[]>([]);
  const filterGroupIds = ref<number[]>([]);
  const filterGroups = ref<Record<number, FilterGroupType>>({});

  const populationId = computed(() => route.params.population_id);
  const population = computed<Population | EmptyPopulation>(() => {
    if (allPopulations.value && populationId.value) {
      const pop = allPopulations.value.find(
        (p) => p.id === Number(populationId.value),
      );
      return pop;
    }
    return emptyPopulation(route.params.type as StandardPopulationType);
  });
  const recordCount = computed<number | null>(
    () => getRecordNumber(population.value) || null,
  );
  const populationTypeName = computed(() => {
    return population.value?.standard_type
      ? getPopulationTypeName(population.value.standard_type)
      : '';
  });

  const remainingSources = computed(() => {
    return filterableSources.value
      .filter(
        (source: Source) =>
          !filterGroupIds.value.some(
            (id) => id.toString() === source.id?.toString(),
          ),
      )
      .map((source: Source) => {
        // need to convert it to the form that BittsDropdown expects
        return { value: source, display: source.table };
      });
  });

  onMounted(async () => {
    await populationsReady;
    if (!populationId.value) {
      initNewPopulation();
    }
    await loadSources();
    setFilterData();
    loading.value = false;
  });

  function initNewPopulation() {
    const sourcesStore = useSourcesStore();
    const sources = computed(() =>
      sourcesStore
        .getSourcesByFeedId(feedId)
        .filter((source) => source.is_base_table && source.sync_enabled),
    );
    if (sources.value.length === 1) {
      population.value.source_id = sources.value[0]?.id;
    } else {
      console.log('TODO MANAGE MULTIPLE SOURCES');
    }
  }

  async function loadSources() {
    if (!population.value.source_id) return;

    const { data, error } = await crossbeamApi.GET(
      '/v0.2/sources/{id}/filterable-sources',
      {
        params: {
          path: { id: population.value.source_id },
        },
      },
    );
    if (error) {
      flashesStore.addUnhandledError(new Error(error));
      return;
    }
    const forbiddenMdmTypes = [MDM_TYPES.DEAL_CONTACT];
    filterableSources.value = uniqBy(data.items, 'id').filter(
      (source) => !forbiddenMdmTypes.includes(source.mdm_type),
    );
  }

  function setFilterData() {
    const filterExpressions = population.value.source_filter_expressions;
    if (!filterExpressions) return;
    Object.keys(filterExpressions).forEach((table: string) => {
      const source = filterableSources.value.find((s) => s.table === table);
      const sourceId = source?.id;
      const filterExpression = filterExpressions[table]?.filter_expression;
      const filters: FilterType[] = [];

      if (!sourceId) return;

      let combinator: string | null = null;
      filterExpression?.forEach((expressionItem: string) => {
        if (['AND', 'OR'].includes(expressionItem)) {
          combinator = expressionItem;
        } else {
          const associatedFilterPart = filterExpressions[
            table
          ]?.filter_parts.find((part) => part.label === expressionItem);
          if (associatedFilterPart) {
            if (combinator && associatedFilterPart) {
              associatedFilterPart.combinator = combinator as Combinator;
              combinator = null;
            }
            filters.push(associatedFilterPart);
          }
        }
      });
      filterGroups.value[sourceId] = { source, filters };
      filterGroupIds.value.push(sourceId);
    });
  }

  function addNewFilterGroup({
    source,
    field,
  }: {
    source: Source;
    field: SharedField;
  }) {
    const filter: FilterType = {
      label: uuidv4(),
      source_field_id: field.id,
      operator: 'in',
    };
    addFilterGroup(source, filter);
  }

  function addFilterGroup(source: Source, filter: FilterType) {
    const { id } = source;
    if (id) {
      filterGroups.value[id] = { source, filters: [filter] };
      filterGroupIds.value.push(id);
    }
  }

  function addFilterToGroup(sourceId: number, combinator: string) {
    const label = uuidv4();
    filterGroups.value[sourceId]?.filters.push({
      label,
      combinator,
    });
  }

  function deleteGroup(sourceId: number) {
    const { [sourceId]: _, ...newFilterGroups } = filterGroups.value;
    filterGroups.value = newFilterGroups;
    filterGroupIds.value = filterGroupIds.value.filter(
      (oId) => oId !== sourceId,
    );
  }

  function deleteFilterFromGroup(sourceId: number, index: number) {
    const group = filterGroups.value[sourceId];
    if (!group) return;

    group.filters.splice(index, 1);

    if (!group.filters.length) deleteGroup(sourceId);
  }

  function updateFilter(
    sourceId: number,
    index: number,
    property: keyof FilterPart,
    value: unknown,
  ) {
    const group = filterGroups.value[sourceId];
    if (!group || !group.filters[index]) return;

    group.filters[index] = {
      ...group.filters[index],
      [property]: value,
    };

    filterGroups.value = { ...filterGroups.value };
  }

  return {
    remainingSources,
    filterGroupIds,
    filterGroups,
    recordCount,
    populationTypeName,
    loading,
    addNewFilterGroup,
    addFilterToGroup,
    deleteGroup,
    deleteFilterFromGroup,
    updateFilter,
  };
}
