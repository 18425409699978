<template>
  <ReportCellWrapper
    :trial-over="params.value.trialOver"
    :to="params.value.reportLink"
  >
    <div class="partners-in-report">
      <div
        v-if="isPartnerTagReport"
        data-testid="partner-tag-cell"
        class="text-neutral-text flex gap-8 items-center"
      >
        <FontAwesomeIcon
          :icon="['fak', 'tag']"
          :style="{ height: '12px', width: '12px', color: 'currentColor' }"
          class="text-neutral-text-button"
        />
        {{ tagInfo?.label }}
      </div>
      <div
        v-else-if="isAllPartnersReport"
        data-testid="all-partners-cell"
        class="text-neutral-text"
      >
        All Partners
      </div>
      <div
        v-else-if="!params.value.partnersInReport.length"
        data-testid="empty-cell"
        class="text-neutral-text-placeholder"
      >
        --
      </div>
      <BittsAvatarStack
        v-else
        :org-list="orgsForStack"
        :include-hover="true"
        :transparent="false"
      />
    </div>
  </ReportCellWrapper>
</template>

<script setup lang="ts">
import { BittsAvatarStack } from '@crossbeam/bitts';

import { ICellRendererParams } from '@ag-grid-community/core';
import { computed } from 'vue';

import ReportCellWrapper from '@/components/reports/ReportCellWrapper.vue';

import { ALL_PARTNERS_TYPE, ECOSYSTEM } from '@/constants/reports';
import { usePartnersStore } from '@/stores';
import { Partner } from '@/types/partners';

const { params } = defineProps<{ params: ICellRendererParams }>();

const partnersStore = usePartnersStore();

const isAllPartnersReport = computed(() => {
  return (
    params.value.report.report_type === ALL_PARTNERS_TYPE ||
    params.value.report.consolidated_report_type === ECOSYSTEM
  );
});
const isPartnerTagReport = computed(() => params.value.report.tag_id);

const orgsForStack = computed(() => {
  return params.value.partnersInReport.map((org: Partner) => {
    return { ...org, showInitials: true };
  });
});

const tagInfo = computed(() => {
  if (!isPartnerTagReport.value) return null;
  return partnersStore.getPartnerTagById(params.value.report.tag_id) || null;
});
</script>

<style lang="pcss">
.partners-in-report {
  @apply flex flex-row items-center justify-start;
}
</style>
