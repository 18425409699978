<template>
  <ReportCellWrapper
    :trial-over="params.value.trialOver"
    :to="params.value.reportLink"
    :use-tooltip="true"
    :class="{ 'cursor-not-allowed': !!params.value.trialOver }"
    class="flex flex-row justify-between items-center max-w-full"
  >
    <div
      :class="{
        'w-[91.5%]': isInErrorState,
        'w-full': !isInErrorState,
        'pointer-events-none': !!params.value.trialOver,
      }"
      class="flex flex-col justify-center pl-8 gap-8"
    >
      <component
        :is="tooltipOrDiv"
        v-bind="tooltipProps"
        class="flex items-center"
      >
        <div
          :draggable="isDraggable"
          class="report-name-cell__report-name"
          :class="{ 'report-name-cell__report-name-draggable': isDraggable }"
          @drag="onDrag"
          @dragstart="onDragStart"
        >
          {{ reportName }}
        </div>
        <template #title>
          {{ reportName }}
        </template>
      </component>
      <div v-if="!folderId" class="report-name-cell__unfiled"> Unfiled </div>
      <div v-else class="flex items-center">
        <span class="text-neutral-500 text-base leading-none"> in </span>
        <FontAwesomeIcon
          :icon="['fak', 'folder']"
          :style="{ height: '16px', width: '16px', color: 'currentColor' }"
          class="text-info-accent mx-4"
        />
        <span class="report-name-cell__folder-name">
          {{ getFolderName(folderId) }}
        </span>
      </div>
    </div>
    <BittsTooltip
      v-if="isInErrorState"
      :class="{ 'pointer-events-none': !!params.value.trialOver }"
      :mount-to-body="true"
      placement="bottom"
    >
      <FontAwesomeIcon
        :icon="['fas', 'diamond-exclamation']"
        class="text-danger-accent h-16 w-24"
      />
      <template #title>
        {{ ERROR_STATE_TO_TOOLTIP_TEXT[errorState as string] }}
      </template>
    </BittsTooltip>
  </ReportCellWrapper>
</template>

<script setup lang="ts">
import { BittsTooltip } from '@crossbeam/bitts';

import { ICellRendererParams } from '@ag-grid-community/core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import ReportCellWrapper from '@/components/reports/ReportCellWrapper.vue';

import {
  INVALID_OWN_FILTERS,
  INVALID_PARTNER_FILTERS,
  NO_OWN_POPULATIONS,
  NO_PARTNER_POPULATIONS,
} from '@/constants/reports';
import { useReportsStore } from '@/stores';

const { params } = defineProps<{ params: ICellRendererParams }>();
const MAX_REPORT_NAME_LENGTH = 27;
const BOTH_FILTERS_INVALID = 'both-filters-invalid';

const ERROR_STATE_TO_TOOLTIP_TEXT: Record<string, string> = {
  [NO_OWN_POPULATIONS]:
    'You have no populations in this report. Add populations to this report to view records',
  [NO_PARTNER_POPULATIONS]:
    'There are no partner’s populations in this report. Edit this report and add partner populations to view records',
  [BOTH_FILTERS_INVALID]:
    "Some of your filters were removed because your partner's data sharing settings and the fields you are syncing changed",
  [INVALID_OWN_FILTERS]:
    'Some of your filters were removed because the fields you are syncing from your data source changed',
  [INVALID_PARTNER_FILTERS]:
    "Some of your filters were removed because your partner's data sharing settings changed",
};

const isInErrorState = computed(
  () => params.value.report.status?.errors.length,
);
const errors = computed(() => {
  if (!isInErrorState.value) return [];
  return params.value.report.status.errors.map(
    (err: { type: string }) => err.type,
  );
});
const errorState = computed(() => {
  if (!isInErrorState.value) return null;
  if (errors.value.includes(NO_OWN_POPULATIONS)) return NO_OWN_POPULATIONS;
  if (errors.value.includes(NO_PARTNER_POPULATIONS))
    return NO_PARTNER_POPULATIONS;
  const invalidOwnFilters = errors.value.includes(INVALID_OWN_FILTERS);
  if (invalidOwnFilters && errors.value.includes(INVALID_PARTNER_FILTERS))
    return BOTH_FILTERS_INVALID;
  return invalidOwnFilters ? INVALID_OWN_FILTERS : INVALID_PARTNER_FILTERS;
});

const { folders } = storeToRefs(useReportsStore());
const foldersElement = computed(() => {
  if (document) return document.getElementById('report-folders');
  return {} as HTMLElement;
});
const folderId = computed(() => params.value.report.folder_uuid);
const reportName = computed(() => params.value.report.name);
function getFolderName(uuid: string) {
  const folder = folders.value.find((folder) => folder.id === uuid);
  return folder?.name || 'Unfiled';
}

const showTooltip = computed(() => {
  if (isInErrorState.value) return false;
  if (reportName.value) return reportName.value.length > MAX_REPORT_NAME_LENGTH;
  return false;
});
const tooltipOrDiv = computed(() => (showTooltip.value ? BittsTooltip : 'div'));
const tooltipProps = computed(() => {
  if (!showTooltip.value) return {};
  return {
    trigger: 'hover',
    placement: 'bottom',
    mountToBody: true,
    defaultSlotClasses: 'report-name-cell-report-name-tooltip-trigger',
  };
});

const isDraggable = computed(() => {
  return params.value.isOnReportsPage && params.value.hasWritePermissions;
});

function onDragStart(ev: DragEvent) {
  ev.dataTransfer?.setData('text', params.value.report.id);
}
function onDrag() {
  foldersElement.value?.scrollIntoView({ behavior: 'smooth' });
}
</script>

<style lang="pcss">
.report-name-cell__folder-name {
  @apply text-neutral-500 text-base leading-none truncate;
  max-width: 140px;
}

.report-name-cell__report-name {
  @apply rounded-bts-md leading-none max-w-full truncate;
}

.report-name-cell__report-name-draggable {
  cursor: grab;
}

.report-name-cell-report-name-tooltip-trigger {
  @apply max-w-full;
}

.report-name-cell__unfiled {
  @apply text-neutral-500 text-base opacity-50 leading-none;
}
</style>
