<template>
  <div
    :class="{
      'pb-16': feedIsErrored,
      disabled: feedIsDeleting,
    }"
    class="c-generic-feed c-feed-list__feed"
  >
    <div class="flex justify-between p-16 ml-24">
      <div class="flex items-center gap-8">
        <slot name="left-content" :no-grow="true" />
        <a
          v-if="crmLink"
          :href="crmLink.url"
          target="_blank"
          rel="noopener"
          class="text-neutral-text"
          data-testid="feedlist-link-to-crm"
        >
          {{ crmLink.text }}
          <FontAwesomeIcon
            :icon="['fak', 'external-link']"
            :style="{ height: '12px', width: '12px', color: 'neutral-400' }"
            class="text-neutral-400 ml-4"
          />
        </a>
        <div v-if="hasPipedriveBeta" class="mr-4">
          <BittsTag variant="rounded" size="tiny" text="Beta" color="beta" />
        </div>
        <div v-if="isSfSandbox" class="mr-4">
          <BittsTag color="neutral-light" variant="category" size="small">
            Sandbox
          </BittsTag>
        </div>
      </div>
      <div class="flex items-center" />
      <div class="flex">
        <div v-if="feedStatus" class="flex items-center gap-10">
          <slot
            name="status"
            :icon-class="feedStatus.bgColor"
            :text="feedStatus.text"
          />
          <BittsButton
            v-if="showDunsButton"
            class="mr-10"
            text="Map DUNS"
            type="neutral"
            variant="outline"
            size="x-small"
            @click="showMapDuns()"
          />
        </div>
        <slot name="feed-settings-popover" :show-nickname="showNickname" />
      </div>
    </div>
    <slot v-if="feedDeletionFailed" name="feed-deletion-failed" />
    <slot v-if="feedIsErrored" name="feed-error" />
  </div>
</template>

<script>
/* This component handles Salesforce, Microsoft Dynamics,
and Hubspot feed types */
import { BittsButton, BittsTag } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import useFeedDetails from '@/composables/useFeedDetails';
import useFeedSyncing from '@/composables/useFeedSyncing';
import {
  MD_DATA_SOURCE_TYPE,
  PIPEDRIVE_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import {
  MULTI_SELECT_SF,
  TEMP_DUNS,
  TEMP_PIPEDRIVE_BETA,
} from '@/constants/feature_flags';
import { useFeatureFlagStore } from '@/stores';

export default {
  name: 'GenericFeed',
  components: {
    BittsTag,
    BittsButton,
  },
  props: {
    feed: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const {
      feedStatus,
      feedIsDeleting,
      feedDeletionFailed,
      crmLinkInfo,
      feedIsErrored,
      sources,
    } = useFeedDetails({ feedId: props.feed.id });

    const showDunsButton = computed(() => {
      const accountFields =
        sources.value.find((source) => source.mdm_type === 'account')?.fields ??
        [];
      const hasDunsAlreadyMapped = accountFields.some(
        (field) => field.mdm_property === 'company_duns_number',
      );
      const excludedColumns = [
        'File Name',
        'Upload Time',
        'id',
        'createdAt',
        'created_at',
      ];
      const hasPotentialDunsFields = accountFields.some(
        (field) =>
          field.is_visible &&
          field.mdm_property === null &&
          !excludedColumns.includes(field.column),
      );

      return (
        hasDunsFeatureFlag.value &&
        !hasDunsAlreadyMapped &&
        hasPotentialDunsFields
      );
    });

    /* Polls the feed when it goes into a syncing state */
    useFeedSyncing({ feed: props.feed });

    /* MD */
    const isMicrosoftDynamics = computed(
      () => props.feed.integration.type === MD_DATA_SOURCE_TYPE,
    );

    /* Salesforce */
    const isSalesforce = computed(
      () => props.feed.integration.type === SALESFORCE_DATA_SOURCE_TYPE,
    );
    const isSfSandbox = computed(
      () => isSalesforce.value && props.feed.is_sandbox,
    );
    const showNickname = computed(
      () => hasMultiCRM.value && isSalesforce.value,
    );

    const featureFlagStore = useFeatureFlagStore();
    const hasMultiCRM = computed(() =>
      featureFlagStore.hasFeatureFlag(MULTI_SELECT_SF),
    );

    const hasPipedriveBeta = computed(
      () =>
        props.feed.integration.type === PIPEDRIVE_DATA_SOURCE_TYPE &&
        featureFlagStore.hasFeatureFlag(TEMP_PIPEDRIVE_BETA),
    );

    const crmLink = computed(() => {
      if (isMicrosoftDynamics.value) return crmLinkInfo.value;
      if (isSalesforce.value && hasMultiCRM.value) return crmLinkInfo.value;
      return null;
    });

    const hasDunsFeatureFlag = computed(() =>
      featureFlagStore.hasFeatureFlag(TEMP_DUNS),
    );
    function showMapDuns() {
      router.push({
        name: 'map-duns-columns',
        params: {
          id: sources.value.find((source) => source.mdm_type == 'account').id,
        },
      });
    }

    return {
      feedStatus,
      feedIsErrored,
      feedIsDeleting,
      feedDeletionFailed,
      crmLink,
      isMicrosoftDynamics,
      isSfSandbox,
      showNickname,
      hasPipedriveBeta,
      showDunsButton,
      showMapDuns,
    };
  },
};
</script>
