<template>
  <div class="c-populations-tab">
    <BittsAlert
      color="info"
      message="Help us identify your Customers, Prospects, Open opportunities."
      description="These populations will be used as filters across the platform.<br />
        You will be able to define which of these populations you share on a per-partner basis."
    />
    <BittsContainer class="c-populations-tab_container">
      <div
        v-for="(population, i) in allPopulations"
        :key="`populations-${population.id}`"
      >
        <PopulationRow
          :population="population"
          :record-count="getRecordNumber(population)"
        />
        <BittsDivider v-if="i < allPopulations.length - 1" />
      </div>
    </BittsContainer>
  </div>
</template>

<script setup lang="ts">
import { BittsAlert, BittsContainer, BittsDivider } from '@crossbeam/bitts';

import PopulationRow from '@/components/data-sources/SettingsModal/PopulationRow.vue';

import usePopulations from '@/composables/usePopulations';

const { allPopulations, getRecordNumber } = usePopulations();
</script>

<style scoped lang="pcss">
.c-populations-tab {
  @apply flex flex-col gap-20;
}
.c-populations-tab_container {
  @apply p-0;
}
</style>
