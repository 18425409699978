<template>
  <BittsPopover
    :mount-to-body="true"
    trigger="click"
    placement="right"
    class="report-overflow-menu"
  >
    <FontAwesomeIcon
      :icon="['fak', 'menu']"
      :style="{ height: '12px', width: '12px' }"
      class="report-overflow-menu__button"
    />
    <template #content>
      <div class="flex flex-col text-brand-navy text-sm my-4">
        <RouterLink
          v-if="!errors.length && !trialOver"
          :to="duplicateReportRoute"
          class="report-overflow-menu__overflow-item"
        >
          Duplicate Report
        </RouterLink>
        <button
          v-if="!hideExport && !errors.length"
          class="report-overflow-menu__overflow-item"
          @click="onExport"
          type="button"
        >
          Export
        </button>
        <RouterLink
          :to="deleteReportRoute"
          class="report-overflow-menu__overflow-item"
        >
          Delete Report
        </RouterLink>
      </div>
    </template>
  </BittsPopover>
</template>

<script setup lang="ts">
import { BittsPopover } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { DateTime } from 'luxon';
import { computed } from 'vue';

import useExport from '@/composables/useExport';
import useIteratively from '@/composables/useIteratively';
import {
  ECOSYSTEM,
  EXPORT_IMPACT_TO_NAME_LOOKUP,
  GREENFIELD_TYPE,
} from '@/constants/reports';
import { CHILD_ROUTES } from '@/constants/routes';
import { Report, ReportFilterGroup } from '@/types/reports';
import urls from '@/urls';

const {
  errors = [],
  hideExport = false,
  isOnReportsPage = true,
  trialOver = false,
  report,
  reportType = '',
  partnerOrgIds = [],
} = defineProps<{
  errors?: string[];
  hideExport?: boolean;
  isOnReportsPage?: boolean;
  trialOver?: boolean;
  report: Report;
  reportType?: string;
  partnerOrgIds?: number[];
}>();

const { performExport } = useExport();

const deleteReportRoute = computed(() => {
  if (isOnReportsPage) {
    return {
      name: CHILD_ROUTES.reports.REPORTS_DELETE,
      params: { id: report.id },
      query: { id: report.id },
    };
  }
  return {
    name: CHILD_ROUTES.folder.REPORTS_DELETE,
    params: { folder_id: report.folder_uuid },
    query: { id: report.id },
  };
});
const duplicateReportRoute = computed(() => {
  if (isOnReportsPage) {
    return {
      name: CHILD_ROUTES.reports.REPORTS_DUPLICATE,
      params: { id: report.id },
      query: { id: report.id },
    };
  }
  return {
    name: CHILD_ROUTES.folder.REPORTS_DUPLICATE,
    params: { folder_id: report.folder_uuid },
    query: { id: report.id },
  };
});

function transformFilter(
  filter: ReportFilterGroup & {
    report_id?: string;
    id?: string;
    tableName?: string;
  },
) {
  const newFilter = { ...filter };
  delete newFilter.report_id;
  delete newFilter.id;
  delete newFilter.tableName;
  return newFilter;
}
const exportQueryParams = computed(() => {
  let exportName = report.name;
  if (report.partner_impact_scores?.length) {
    // we can only do one at at a time, for now
    const impactFilter = report.partner_impact_scores[0] as string;
    exportName += ` ${EXPORT_IMPACT_TO_NAME_LOOKUP[impactFilter]}`;
  }
  const params = {
    consolidated_report_type: report.consolidated_report_type,
    our_population_ids: report.our_population_ids,
    partner_population_ids: report.partner_population_ids,
    columns: report.columns.map((col) => {
      if (!col.source_field_id) {
        return {
          column_order: col.column_order,
          column_type: col.column_type,
        };
      }
      return col;
    }),
    filters: report.filters.map(transformFilter),
    partner_standard_populations: report.partner_standard_populations || [],
    exported_at: DateTime.local().toFormat('f'),
    export_name: exportName,
    report_id: report.id,
  };
  const isAllPartnersType = report.consolidated_report_type === ECOSYSTEM;
  if (isAllPartnersType) params.partner_population_ids = [];
  return params;
});

const analyticsPayload = computed(() => {
  const payload: {
    population_id: number[];
    partner_id: number[];
    location: string;
    report_id: string;
    report_name: string;
    report_type: string;
    partner_impact_scores: string[];
    event_site: string;
    greenfield_type?: string;
  } = {
    population_id: exportQueryParams.value.our_population_ids,
    partner_id: partnerOrgIds,
    location: 'ellipsis_out_report',
    report_id: report.id,
    report_name: report.name,
    report_type: reportType,
    partner_impact_scores: report.partner_impact_scores || [],
    event_site: EVENT_SITES.REPORT_OVERFLOW_MENU,
  };
  if (reportType === GREENFIELD_TYPE) {
    payload.greenfield_type = report.consolidated_report_type;
  }
  return payload;
});

const { iteratively } = useIteratively();
const exportUrl = computed(() => urls.reportData.export);
async function onExport() {
  const params = exportQueryParams.value;
  await performExport(params, exportUrl.value);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  iteratively.userExportedReport(analyticsPayload as any);
}
</script>

<style lang="pcss">
.report-overflow-menu {
  @apply flex items-center justify-end h-full mb-4;
}
.report-overflow-menu__button {
  @apply text-neutral-400 p-4 rounded-bts-sm hover:bg-primary-background-medium
  hover:bg-blend-overlay hover:bg-opacity-[.08];
}
.report-overflow-menu__overflow-item {
  @apply cursor-pointer px-16 py-4 text-left;
}

.report-overflow-menu__overflow-item:hover {
  @apply bg-primary-background-weak;
}
</style>
