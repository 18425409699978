<template>
  <div class="folder-dropdown">
    <div
      v-if="moveToUnfiled"
      class="folder-dropdown__folder-item"
      @click="onSelectItem({ name: 'Move To Unfiled' } as ReportFolder)"
    >
      <FontAwesomeIcon
        :icon="['fak', 'move']"
        :style="{ height: '16px', width: '16px', color: 'currentColor' }"
        class="text-neutral-400 mr-8"
      />
      <div class="folder-dropdown__folder-item-name"> Move to Unfiled </div>
    </div>
    <div
      v-else
      class="folder-dropdown__folder-item"
      @click="onSelectItem({ name: 'New Folder' } as ReportFolder)"
    >
      <FontAwesomeIcon
        :icon="['fas', 'folder-plus']"
        :style="{ height: '16px', width: '16px', color: 'currentColor' }"
        class="text-info-accent mr-8"
      />
      <div class="folder-dropdown__folder-item-name"> New Folder </div>
    </div>
    <div v-if="folders.length">
      <BittsDivider class="my-4" />
      <div
        v-for="folder in folders"
        :key="folder.id"
        class="folder-dropdown__folder-item"
        @click="onSelectItem(folder)"
      >
        <FontAwesomeIcon
          :icon="['fak', 'folder']"
          :style="{ height: '16px', width: '16px', color: 'currentColor' }"
          class="text-info-accent mr-8"
        />
        <div class="folder-dropdown__folder-item-name">
          {{ folder.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BittsDivider } from '@crossbeam/bitts';

import { ReportFolder } from '@/interfaces/reports';

const { folders = [], moveToUnfiled = false } = defineProps<{
  folders: ReportFolder[];
  moveToUnfiled?: boolean;
}>();

const emit = defineEmits<(e: 'item-selected', item: ReportFolder) => void>();

function onSelectItem(item: ReportFolder) {
  emit('item-selected', item);
}
</script>
<style lang="pcss">
.folder-dropdown {
  @apply py-4;
  width: 200px;
  .folder-dropdown__folder-item {
    @apply pl-16 flex items-center py-2;
    &:hover {
      @apply bg-neutral-50 cursor-pointer;
    }
  }
  .folder-dropdown__folder-item-name {
    @apply text-neutral-600 text-base;
  }
}
</style>
