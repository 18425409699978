<template>
  <ReportCellWrapper
    :to="params.value.reportLink"
    :trial-over="params.value.trialOver"
  >
    <div class="flex flex-col">
      <span class="reports-user-cell__edited-text">
        {{ `Edited ${getRelativeTime}` }}
      </span>
      <div class="flex flex-row items-center">
        <BittsAvatar
          :user="user"
          :show-initials="true"
          size="x-small"
          class="mr-8 leading-none"
        />
        <span class="reports-user-cell__user-text">
          {{ `${user.first_name} ${user.last_name}` }}
        </span>
      </div>
    </div>
  </ReportCellWrapper>
</template>

<script setup lang="ts">
import { BittsAvatar } from '@crossbeam/bitts';

import { ICellRendererParams } from '@ag-grid-community/core';
import { DateTime } from 'luxon';
import { computed } from 'vue';

import ReportCellWrapper from '@/components/reports/ReportCellWrapper.vue';

const { params } = defineProps<{ params: ICellRendererParams }>();

const getRelativeTime = computed(() =>
  DateTime.fromISO(params.value.report.updated_at).toRelative(),
);
const user = computed(() => params.value.updatedByUser);
</script>

<style lang="pcss">
.reports-user-cell__edited-text {
  @apply text-sm text-neutral-800 leading-none mb-8;
}

.reports-user-cell__user-text {
  @apply text-base text-neutral-500 leading-none truncate;
}
</style>
