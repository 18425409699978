<template>
  <BittsModal
    title="Delete Report?"
    :loading="loading"
    :content-text="`Are you sure you want to delete ${reportName}?`"
    save-text="Yes, Delete"
    cancel-text="No, Don't Delete"
    :visible="showDeleteReportModal"
    confirm-type="danger"
    @saved="onDelete(reportToDelete as Report)"
    @closed="onCloseModal"
  />
</template>

<script setup lang="ts">
import { BittsModal } from '@crossbeam/bitts';

import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { BASE_ROUTES } from '@/constants/routes';
import { useFlashesStore, useReportsStore } from '@/stores';
import { Report } from '@/types/reports';

const loading = ref(false);
const showDeleteReportModal = ref(false);

const route = useRoute();
const router = useRouter();

const reportsStore = useReportsStore();
const flashesStore = useFlashesStore();

const reportToDelete = computed(() => {
  const currentReportId = route.query.id;
  return reportsStore.getByReportId(currentReportId as string);
});

const reportName = computed(() => reportToDelete.value?.name || '');

const folderId = computed(() => route.params.folder_id);
const closeRoute = computed(() => {
  if (!folderId.value) return { name: BASE_ROUTES.REPORTS };
  return {
    name: BASE_ROUTES.REPORT_FOLDER,
    params: { folder_id: folderId.value },
  };
});

onMounted(async () => {
  loading.value = true;
  await reportsStore.refreshReportsStore();
  showDeleteReportModal.value = true;
  loading.value = false;
});

async function onCloseModal() {
  showDeleteReportModal.value = false;
  await router.push(closeRoute.value);
}

async function onDelete(report: Report) {
  loading.value = true;
  try {
    await reportsStore.deleteReport(report);
    flashesStore.addSuccessFlash({
      message: `"${report.name}" report deleted`,
    });
    await reportsStore.refreshReportsStore();
  } catch (_err) {
    flashesStore.addErrorFlash({ message: 'Failed to delete report' });
  } finally {
    onCloseModal();
    loading.value = false;
  }
}
</script>
