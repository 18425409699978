import axios from 'axios';
import { ComputedRef, computed, inject, ref } from 'vue';
import { useRouter } from 'vue-router';

import {
  useConnectionsStore,
  useFeedsStore,
  useIntegrationProfileStore,
  usePartnerCloudStore,
} from '@/stores';
import urls from '@/urls';

import useAuth from './useAuth';
import useIntegrationProfile from './useIntegrationProfile';

export default function useSnowflake() {
  const feedsStore = useFeedsStore();
  const connectionsStore = useConnectionsStore();
  const partnerCloudStore = usePartnerCloudStore();
  const { hasPermission } = useAuth();

  const canManageIntegrations = computed(() =>
    hasPermission('manage:integrations'),
  );

  const updatingSnowflakeConnection = ref(false);
  const hasErrors = ref(false);

  const exportLimitReached = inject<ComputedRef<boolean>>('exportLimitReached');

  const profileStore = useIntegrationProfileStore();
  const integrationProfileSettings = computed(() =>
    profileStore.getIntegrationProfileByType('snowflake'),
  );

  const { isSaving } = useIntegrationProfile('snowflake', {
    initOnMount: false,
  });

  const snowflakeConnection = computed(() =>
    connectionsStore.getConnectionByType('snowflake'),
  );
  const hasSnowflakeFeed = computed(() => {
    return !!feedsStore.getFeedByDataSourceType('snowflake');
  });
  const hasSnowflakeConnection = computed(() => !!snowflakeConnection.value);

  const hasConnectionError = computed(
    () => !!snowflakeConnection.value?.error_message,
  );
  const isActive = computed(() => {
    return !!snowflakeConnection.value && !snowflakeConnection.value?.is_paused;
  });

  const connectionStatus = computed(() => {
    if (
      exportLimitReached?.value ||
      hasConnectionError.value ||
      !hasSnowflakeConnection.value
    ) {
      return 'action_needed';
    }
    if (
      hasSnowflakeConnection.value &&
      !integrationProfileSettings.value &&
      !isSaving.value
    ) {
      return 'setting_available';
    }
    if (hasErrors.value) return 'error';
    if (!isActive.value) return 'paused';
    if (hasSnowflakeConnection.value) return 'active';
    return '';
  });

  const calloutText = computed(() => {
    if (exportLimitReached?.value) {
      return 'Reach out to <a target="_blank" href="mailto:support@crossbeam.com" class="text-secondary-text">support@crossbeam.com</a> to increase your export limit';
    }
    if (hasConnectionError.value) {
      return 'There is an error with the Snowflake Custom Object connection. Reach out to <a target="_blank" href="mailto:support@crossbeam.com" class="text-secondary-text">support@crossbeam.com</a> for further assistance';
    }
    if (!hasSnowflakeConnection.value) {
      return 'Click the Set Up button to connect to Snowflake and start pushing overlaps';
    }
    if (hasSnowflakeConnection.value && !integrationProfileSettings.value) {
      return 'All overlaps are currently being pushed to Snowflake. Click the Settings button if you would like to configure your push settings.';
    }
    return '';
  });

  const calloutTitle = computed(() => {
    if (exportLimitReached?.value) {
      return 'Export Limit Reached';
    }
    if (hasConnectionError.value) {
      return 'Snowflake Custom Object connection failed';
    }
    if (!hasSnowflakeConnection.value) {
      return 'Snowflake Custom Object connection not set up';
    }
    if (!integrationProfileSettings.value && !isSaving.value) {
      return 'Snowflake Custom Object push settings have not been created';
    }
    return '';
  });

  const integrationApp = computed(() => {
    return partnerCloudStore.getAvailableIntegrationByType('snowflake');
  });

  const isPushDisabled = computed(() => {
    return (
      (!hasSnowflakeFeed.value && !hasSnowflakeConnection.value) ||
      updatingSnowflakeConnection.value ||
      hasConnectionError.value
    );
  });

  const router = useRouter();
  // @ts-expect-error no config type yet
  const createSnowflakeConnection = async (snowflakeConfig) => {
    updatingSnowflakeConnection.value = true;
    try {
      await axios.post(urls.connections.snowflake, snowflakeConfig);
      hasErrors.value = false;
      router.push({ name: 'snowflake_push_settings' });
    } catch (_e) {
      hasErrors.value = true;
    } finally {
      await connectionsStore.refreshConnectionsStore();
      updatingSnowflakeConnection.value = false;
    }
  };
  const toggleSnowflakeConnection = async () => {
    updatingSnowflakeConnection.value = true;
    try {
      if (hasSnowflakeConnection.value) {
        const connection = snowflakeConnection.value;
        await axios.patch(urls.connections.patch(connection?.id), {
          is_paused: !connection?.is_paused,
        });
      }
    } finally {
      await connectionsStore.refreshConnectionsStore();
      updatingSnowflakeConnection.value = false;
    }
  };

  const createOrToggleSnowflakeConnection = async () => {
    if (hasSnowflakeConnection.value) {
      await toggleSnowflakeConnection();
    } else {
      await createSnowflakeConnection({});
    }
  };

  return {
    canManageIntegrations,
    createSnowflakeConnection,
    toggleSnowflakeConnection,
    createOrToggleSnowflakeConnection,
    snowflakeConnection,
    hasSnowflakeFeed,
    hasSnowflakeConnection,
    hasConnectionError,
    isActive,
    isPushDisabled,
    integrationApp,
    connectionStatus,
    updatingSnowflakeConnection,
    calloutText,
    calloutTitle,
    integrationProfileSettings,
  };
}
