<template>
  <BittsModal
    title="Duplicate Report"
    save-text="Save a Copy"
    :visible="showDuplicateReportModal"
    :loading="loading"
    :disabled="isNameInvalid"
    :saving="isReportSaving"
    @saved="saveCopy"
    @closed="onModalClose"
  >
    <template #content>
      <p class="text-neutral-text-strong mb-8">
        Please specify a unique name for a copy of
        <strong>{{ report ? report.name : '' }}</strong>
      </p>
      <BittsInput
        v-model="name"
        name="report-name"
        placeholder="Report Name"
        :status="isNameInvalid ? 'danger' : 'default'"
        :danger-text="
          !name
            ? 'A name for the report is required'
            : 'This report must have a unique name'
        "
      />
    </template>
  </BittsModal>
</template>
<script setup lang="ts">
import { BittsInput, BittsModal } from '@crossbeam/bitts';

import axios from 'axios';
import { Nullable } from 'vitest';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { ECOSYSTEM } from '@/constants/reports';
import { BASE_ROUTES } from '@/constants/routes';
import { captureException } from '@/errors';
import { useReportsStore } from '@/stores';
import {
  Report,
  ReportColumn,
  ReportFilterGroup,
  ReportFilterPart,
  ReportSort,
} from '@/types/reports';
import urls from '@/urls';

const loading = ref(true);
const showDuplicateReportModal = ref(false);
const isReportSaving = ref(false);

const route = useRoute();
const router = useRouter();

const reportsStore = useReportsStore();

const report = computed(() => {
  const currentReportId = route.query.id;
  return reportsStore.getByReportId(currentReportId as string);
});

const name = ref<string | undefined>(undefined);
function setName() {
  let nameWithCopy = `${report.value?.name} (Copy)`;
  let reportWithName = reportsStore.getByReportName(nameWithCopy);
  if (!reportWithName) {
    name.value = nameWithCopy;
    return;
  }

  let copyNumber = 1;
  while (reportWithName) {
    nameWithCopy = `${report.value?.name} (Copy ${copyNumber})`;
    reportWithName = reportsStore.getByReportName(nameWithCopy);
    copyNumber++;
  }
  name.value = nameWithCopy;
}
const isNameInvalid = computed(() => {
  if (!name.value) return true;
  const report = reportsStore.getByReportName(name.value || '');
  return !!report;
});

const folderId = computed(() => route.params.folder_id);
const closeRoute = computed(() => {
  if (!folderId.value) return { name: BASE_ROUTES.REPORTS };
  return {
    name: BASE_ROUTES.REPORT_FOLDER,
    params: { folder_id: folderId.value },
  };
});
function editLink(report: Report) {
  return {
    name: 'edit_report',
    params: { report_id: report.id },
  };
}

type Column = Record<string, Nullable<string | number[] | number>>;
function removeNullColumnFields(columns: ReportColumn[]) {
  return columns.map((column) => {
    const validColumn: Column = { ...column };

    const filteredColumn = Object.keys(validColumn).reduce(
      (acc: Column, field) => {
        const { [field]: _, ...rest } = acc;
        return validColumn[field] === null ? (rest as ReportColumn) : acc;
      },
      validColumn,
    );

    return filteredColumn;
  });
}
type FilterGroup = ReportFilterGroup & {
  report_id?: string;
  id?: number;
};
type SaveReportPayload = {
  name: string;
  consolidated_report_type: string;
  columns: ReportColumn[];
  our_population_ids: number[];
  partner_standard_populations: string[];
  sort: ReportSort;
  filters: ReportFilterGroup[];
  partner_population_ids?: number[];
  partner_impact_scores?: string[];
  tag_id?: string;
};
const saveReportPayload = computed(() => {
  if (!report.value?.id) return {};
  const payload: SaveReportPayload = {
    name: name.value as string,
    consolidated_report_type: report.value.consolidated_report_type,
    columns: removeNullColumnFields(report.value.columns) as ReportColumn[],
    our_population_ids: report.value.our_population_ids,
    partner_standard_populations: report.value
      .partner_standard_populations as string[],
    sort: report.value.sort,
    filters: report.value.filters.map((filter) => {
      const newFilter: FilterGroup = {
        ...filter,
        filter_parts: filter.filter_parts.map((filterPart) => {
          const output: ReportFilterPart & {
            id?: number;
            report_population_id?: number;
          } = { ...filterPart };
          delete output.id;
          delete output.report_population_id;
          return output;
        }),
      };
      delete newFilter.report_id;
      delete newFilter.id;
      return newFilter;
    }),
  };
  const isAllPartnersType = payload.consolidated_report_type === ECOSYSTEM;
  if (report.value?.tag_id) payload.tag_id = report.value.tag_id;
  if (!isAllPartnersType)
    payload.partner_population_ids = report.value.partner_population_ids;
  else if (report.value.partner_impact_scores) {
    payload.partner_impact_scores = report.value.partner_impact_scores;
  }
  return payload;
});

onMounted(async () => {
  showDuplicateReportModal.value = true;
  await reportsStore.refreshReportsStore();
  setName();
  loading.value = false;
});

async function saveCopy() {
  if (isNameInvalid.value) return;
  try {
    isReportSaving.value = true;
    const payload = saveReportPayload.value;
    const response = await axios.post(urls.reports.create, payload);
    const newReport = response.data;
    await router.push(editLink(newReport));
  } catch (err) {
    console.log(err);
    captureException(err);
  } finally {
    showDuplicateReportModal.value = false;
    isReportSaving.value = false;
  }
}

async function onModalClose() {
  showDuplicateReportModal.value = false;
  await router.push(closeRoute.value);
}
</script>
