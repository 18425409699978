export const CORE = 'core';
export const SALES = 'sales';

/* Sales edge roles */
export const CO_SELLER = 'co-seller';
export const VIEWER = 'viewer';
export const PARTNER_MANAGER = 'partner-manager';
export const NO_ACCESS = 'no-access'; // Used in place of null in some selectors

export const TOOLTIP_LAST_ADMIN =
  'You cannot modify this user because there needs to be at least 1 admin user';
export const TOOLTIP_SELF = "You can't delete yourself";

export const SALES_EDGE_SEATS = [CO_SELLER, PARTNER_MANAGER, VIEWER];

export const ADMIN_PANEL = 'admin_panel';

export const FREE_TIER_CORE_COPY =
  'Full access to Crossbeam Core & Reply Only access to Sales Edge';

export const NO_ACCESS_ROLE = {
  name: 'No Access',
  id: NO_ACCESS,
  icon: {
    type: ['fak', 'hidden'],
    color: 'text-neutral-accent',
  },
};

export const SALES_EDGE_ROLES = [
  {
    name: 'Manager',
    id: PARTNER_MANAGER,
  },
  {
    name: 'Standard',
    id: CO_SELLER,
  },
  {
    name: 'Limited',
    id: VIEWER,
  },
  NO_ACCESS_ROLE,
];

export const WRITE_ACCESS_ROLES = [PARTNER_MANAGER, CO_SELLER];

export function sortRows(firstValue = null, secondValue = null) {
  if (firstValue === null && secondValue !== null) return 1;
  if (secondValue === null && firstValue !== null) return -1;
  if (firstValue === null && secondValue === null) return 0;
  return firstValue?.localeCompare(secondValue);
}

export const ROLE_TYPE_ORDER = {
  admin: '1',
  standard_user: '2',
  limited_user: '3',
};
