<template>
  <Notification :item="item">
    <template #logo>
      <BittsAvatar :org="partnerOrg" :show-initials="true" size="medium" />
    </template>
    <template #message> Data Sharing Changed </template>
    <template #description>
      <span data-testid="c-report-population-removed-notification__description">
        {{ item.data?.partner_org_name }} has changed sharing settings from
        their {{ item.data?.population_name }} Population with you. This change
        may affect the following reports: {{ reportNames }}</span
      >
    </template>
    <template #action>
      <BittsButton
        text="View Reports"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="viewReport"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'hidden']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script setup lang="ts">
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import { BASE_ROUTES } from '@/constants/routes';
import { useNotificationsStore, usePartnersStore } from '@/stores';
import { Notification as NotificationType } from '@/types/notifications';

const { item } = defineProps<{ item: NotificationType }>();

const emit = defineEmits<(e: 'action-clicked', action: string) => void>();

const partnersStore = usePartnersStore();
const partnerOrg = computed(() =>
  partnersStore.getPartnerOrgById(item.data?.partner_org_id || 0),
);

const reportNames = computed(() => {
  if (!item.data?.report_names) return '';
  const names = [...(item.data.report_names as string[])];
  return names.join(', ');
});

const router = useRouter();
const notificationsStore = useNotificationsStore();
async function viewReport() {
  emit('action-clicked', 'View Reports');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  await router.push({ name: BASE_ROUTES.REPORTS });
}
</script>
