<template>
  <notification :item="item">
    <template #logo>
      <span>
        <BittsAvatar
          v-if="partnerOrg"
          :org="partnerOrg"
          :show-initials="true"
          size="medium"
        />
        <BittsAvatar
          v-else
          :is-icon="true"
          icon-slot-bg-color="bg-beta-background-medium"
          size="medium"
        >
          <template #icon>
            <FontAwesomeIcon
              :icon="['fak', 'company']"
              :style="{ height: '20px', width: '20px', color: 'white' }"
            />
          </template>
        </BittsAvatar>
      </span>
    </template>
    <template #message> Partnership Request </template>
    <template #description>
      {{ userName }}
      <span v-if="userLastName">{{ userLastName }}</span>
      <span v-if="userEmail">({{ userEmail }})</span>
      from {{ organizationName }} wants to partner with you.
    </template>
    <template #action>
      <div v-if="request" class="flex items-center gap-4">
        <BittsButton
          text="Accept"
          type="primary"
          size="x-small"
          @click="acceptInvitation(request)"
        />
        <BittsButton
          text="Reject"
          type="danger"
          variant="ghost"
          size="x-small"
          @click="declineInvitation(request)"
        />
      </div>
      <div v-else-if="partnerOrg.id" class="flex items-center">
        <BittsButton
          text="View Partner"
          type="neutral"
          size="x-small"
          @click="viewPartner"
        />
      </div>
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'team']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </notification>
</template>
<script setup>
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import axios from 'axios';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import {
  useFlashesStore,
  useNotificationsStore,
  usePartnersStore,
} from '@/stores';
import urls from '@/urls';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['action-clicked']);

const userName = computed(() => props.item.data.inviter_user_name);
const userLastName = computed(() => props.item.data.inviter_last_name || '');
const userEmail = computed(() => props.item.data.inviter_email || '');
const organizationName = computed(() => props.item.data.inviter_org_name);

const partnersStore = usePartnersStore();
const request = computed(() =>
  partnersStore.getProposalReceivedById(props.item.object_id),
);
const partnerOrg = computed(() => {
  const org = partnersStore.getPartnerOrgById(props.item.data.inviter_org_id);
  return (
    org || {
      clearbit_domain: request.value?.sending_organization.clearbit_domain,
    }
  );
});

const router = useRouter();
const flashesStore = useFlashesStore();
const notificationsStore = useNotificationsStore();
async function isValidProposal(pid) {
  try {
    const { data } = await axios.get(urls.partners.all);
    const { proposals_received: proposals } = data;
    return proposals.some((invite) => invite.id === pid);
  } catch (err) {
    flashesStore.addErrorFlash({ message: err });
  }
}
async function acceptInvitation(proposal) {
  emit('action-clicked', 'Accept');
  try {
    const hasProposal = await isValidProposal(proposal.id);
    if (hasProposal) {
      await router.push({
        name: 'accept_proposal',
        query: { proposal_id: proposal.id },
      });
      await notificationsStore.markRead({ id: props.item.id, isRead: true });
    } else {
      flashesStore.addErrorFlash({
        message: "Partnership Request Doesn't Exist",
        description:
          'If you want to partner with them, you can send them a request.',
      });
    }
  } catch (err) {
    flashesStore.addErrorFlash({ message: err });
  } finally {
    await partnersStore.refreshPartnersStore();
  }
}
async function declineInvitation(proposal) {
  emit('action-clicked', 'Decline');
  try {
    const hasProposal = await isValidProposal(proposal.id);
    if (hasProposal) {
      await axios.put(urls.proposals.decline(proposal.id));
      flashesStore.addSuccessFlash(
        `Invitation from ${proposal.sending_organization.name} rejected`,
      );
      await notificationsStore.markRead({ id: props.item.id, isRead: true });
    } else {
      flashesStore.addErrorFlash({
        message: "Partnership Request Doesn't Exist",
        description:
          'If you want to partner with them, you can send them a request.',
      });
    }
  } catch (err) {
    flashesStore.addErrorFlash({ message: err });
  } finally {
    await partnersStore.refreshPartnersStore();
  }
}
async function viewPartner() {
  emit('action-clicked', 'View Partner');
  await notificationsStore.markRead({ id: props.item.id, isRead: true });
  await router.push({
    name: 'partner_details',
    params: { partner_org_id: partnerOrg.value.id },
  });
}
</script>
