import { DateTime } from 'luxon';

import { MDM_PROPERTIES } from '@/constants/mdm';

export const MDM_DEFAULT_FIELDS = {
  account: new Set([MDM_PROPERTIES.account.NAME, 'company_website']),
  contact: new Set(['person_email']),
  lead: new Set(['person_email']),
};

export const OPERATORS = [
  {
    label: 'is',
    id: 'in',
    labelOverrides: {
      datetime: 'is on',
    },
  },
  {
    label: 'is not',
    id: 'not in',
    labelOverrides: {
      datetime: 'is not on',
    },
  },
  {
    label: 'greater than',
    id: '>',
    labelOverrides: {
      datetime: 'is after',
    },
  },
  {
    label: 'less than',
    id: '<',
    labelOverrides: {
      datetime: 'is before',
    },
  },
  {
    label: 'greater than or equal to',
    id: '>=',
    labelOverrides: {
      datetime: 'is on or after',
    },
  },
  {
    label: 'less than or equal to',
    id: '<=',
    labelOverrides: {
      datetime: 'is on or before',
    },
  },
  {
    label: 'is empty',
    id: 'is empty',
    filterPartOverrides: {
      operator: 'is empty',
      operand: null,
    },
    labelOverrides: {},
  },
  {
    label: 'is not empty',
    id: 'is not empty',
    filterPartOverrides: {
      operator: 'is not empty',
      operand: null,
    },
    labelOverrides: {},
  },
  {
    label: 'contains',
    id: 'contains',
    labelOverrides: {},
  },
  {
    label: 'does not contain',
    id: 'does not contain',
    labelOverrides: {},
  },
];

export const COMBINATORS = [
  { label: 'and', id: 'AND' },
  { label: 'or', id: 'OR' },
];

export const DATA_TYPES = {
  string: {
    label: 'Type a value to filter',
    placeholder: 'Type a value to filter',
    validate: (value) => {
      // note it would be very strange if something that was not a string was passed here
      return typeof value === 'string';
    },
    transform: (value) => value,
    operators: [
      'in',
      'not in',
      'null',
      'is empty',
      'not null',
      'is not empty',
      'contains',
      'does not contain',
    ],
  },
  number: {
    label: 'number',
    example: '(100, 200.1, 100.24, etc)',
    placeholder: 'e.g. 10, 10.22',
    validate: (value) => {
      return /^\d+(\.\d{1,9})?$/.test(value) || !value;
    },
    transform: (value) => parseFloat(value),
    transformOperator: (operator) => operator,
    operators: [
      'in',
      'not in',
      '>',
      '<',
      '>=',
      '<=',
      'null',
      'is empty',
      'not null',
      'is not empty',
    ],
  },
  datetime: {
    label: 'date in YYYY-MM-DD format',
    example: '(2019-01-03 is January 3, 2019)',
    validate: (value) => {
      const date = DateTime.fromISO(value);
      return date.isValid || !value;
    },
    // Adjusts datetime values so that time is always set to midnight.
    transform: (value) => {
      return DateTime.fromISO(value, { zone: 'utc' }).toString();
    },
    transformOperator: (operator) => {
      let newOperator = operator;
      switch (operator) {
        case '<=':
          newOperator = '<';
          break;
        case '>':
          newOperator = '>=';
          break;
        default:
          break;
      }
      return newOperator;
    },
    operators: [
      'in',
      'not in',
      '>',
      '<',
      '>=',
      '<=',
      'null',
      'is empty',
      'not null',
      'is not empty',
    ],
  },
  boolean: {
    label: 'boolean value',
    example: '(true, false)',
    placeholder: 'e.g. true or false',
    validate: (value) =>
      /^true$/i.test(value) || /^false$/i.test(value) || !value,
    // this transform function requires `value` be a string
    transform: (value) => {
      console.log('boolean - transform: ', value);
      return value ? value === 'true' : null;
    },
    transformOperator: (operator) => operator,
    operators: ['in', 'not in', 'is empty', 'is not empty'],
    values: [
      { id: 'true', label: 'true' },
      { id: 'false', label: 'false' },
    ],
  },
};

export const DELIMITER = '\\';

export function emptyPopulation({
  baseSchema,
  baseTable,
  sourceId,
  standardType,
} = {}) {
  const population = {
    base_schema: baseSchema,
    base_table: baseTable,
    source_id: sourceId,
    sources: [],
    standard_type: standardType,
  };
  population.source_filter_expressions = {};
  return population;
}

export function validateFilterParts(filterParts, population) {
  const validatedFilters = filterParts.map((filterPart) => {
    const { schema, table, column, operator, operand, sourceFieldId } =
      filterPart;
    let hasErrors = false;
    let errorValues = [];
    let label;
    let example;
    if (!column) {
      return {
        hasErrors,
        filterPart,
      };
    }
    const tableDefinition = population.allFields.find(
      (item) => item.schema === schema && item.table === table,
    );
    const columnDefinition = tableDefinition.children.find(
      (child) => child.column === column,
    );
    const dataType = columnDefinition.dataType;
    const trimOperand = (datatype, item) => {
      // For dates we need to clear out the time portion
      if (item && datatype === 'datetime' && typeof item !== 'string') {
        return DateTime.fromJSDate(item).toFormat('yyyy-MM-dd');
      }
      if (item || typeof item === 'boolean') {
        return item.toString().trim();
      }
      return '';
    };
    let updatedOperator = operator;
    let updatedOperand = !Array.isArray(operand)
      ? trimOperand(dataType, operand)
      : operand.map((item) => trimOperand(dataType, item));

    if (dataType !== 'string') {
      label = DATA_TYPES[dataType].label;
      example = DATA_TYPES[dataType].example;

      if (!Array.isArray(updatedOperand)) {
        hasErrors = !DATA_TYPES[dataType].validate(updatedOperand);
        if (hasErrors) {
          errorValues.push(updatedOperand);
        }
      } else {
        const operandValidationErrors = updatedOperand
          .map((item) => {
            const operandHasError = !DATA_TYPES[dataType].validate(item);
            return { operandHasError, operand: item };
          })
          .filter((item) => item.operandHasError);
        hasErrors = operandValidationErrors.length > 0;
        errorValues = errorValues.concat(
          operandValidationErrors.map((item) => item.operand),
        );
      }

      hasErrors = !Array.isArray(updatedOperand)
        ? !DATA_TYPES[dataType].validate(updatedOperand)
        : updatedOperand.some((item) => !DATA_TYPES[dataType].validate(item));

      updatedOperand = !Array.isArray(updatedOperand)
        ? DATA_TYPES[dataType].transform(updatedOperand, operator)
        : updatedOperand.map((item) => DATA_TYPES[dataType].transform(item));

      updatedOperator = !Array.isArray(updatedOperand)
        ? DATA_TYPES[dataType].transformOperator(operator)
        : operator;
    }

    return {
      hasErrors,
      errorValues,
      operand: updatedOperand,
      operator: updatedOperator,
      column,
      label,
      example,
      filterPart,
      sourceFieldId,
    };
  });

  const transformedFilterParts = validatedFilters
    .filter((validatedFilter) => !validatedFilter.hasErrors)
    .map((validatedFilter) => ({
      ...validatedFilter.filterPart,
      operand: validatedFilter.operand,
      operator: validatedFilter.operator,
    }));

  return {
    errors: validatedFilters.filter(
      (validatedFilter) => validatedFilter.hasErrors,
    ),
    filterParts: transformedFilterParts,
  };
}

export function validateAndConvertOperandDataTypes(
  dataType,
  operands,
  operator,
) {
  if (operands == null)
    return { operandValidationErrors: [], convertedOperands: null };
  const trimOperand = (dataType, item) => {
    // For dates we need to clear out the time portion
    if (item && dataType === 'datetime' && typeof item !== 'string') {
      return DateTime.fromJSDate(item).toFormat('yyyy-MM-dd');
    }
    if (item || typeof item === 'boolean' || item === 0) {
      return item.toString().trim();
    }
    return '';
  };
  if (!Array.isArray(operands)) {
    const trimmedOperand = trimOperand(dataType, operands);
    const hasErrors = !DATA_TYPES[dataType].validate(trimmedOperand);
    const operandValidationErrors = [];
    if (hasErrors) {
      operandValidationErrors.push(trimmedOperand);
    }
    const convertedOperands = DATA_TYPES[dataType].transform(
      trimmedOperand,
      operator,
    );

    return { operandValidationErrors, convertedOperands };
  }
  const trimmedOperands = operands.map((operand) =>
    trimOperand(dataType, operand),
  );
  const operandValidationErrors = trimmedOperands
    .map((item) => {
      const operandHasError = !DATA_TYPES[dataType].validate(item);
      return { operandHasError, operand: item };
    })
    .filter((item) => item.operandHasError);

  const convertedOperands = trimmedOperands.map((item) => {
    return DATA_TYPES[dataType].transform(item, operator);
  });
  return { operandValidationErrors, convertedOperands };
}
