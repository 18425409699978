<template>
  <notification :item="item">
    <template #logo>
      <BittsSvg v-if="integrationType" :svg="integrationType + 'Icon'" />
      <BittsAvatar
        v-else
        :is-icon="true"
        :org="null"
        :user="null"
        icon-slot-bg-color="bg-beta-background-medium"
        size="medium"
      >
        <template #icon>
          <FontAwesomeIcon
            :icon="['fak', 'company']"
            :style="{ height: '20px', width: '20px', color: 'white' }"
          />
        </template>
      </BittsAvatar>
    </template>
    <template #message> Connection Error </template>
    <template #description>
      <span> Your {{ friendlyName }} integration encountered an error </span>
    </template>
    <template #action>
      <BittsButton
        text="View Error"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="viewError"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'data-source']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-danger-accent ml-4"
      />
    </template>
  </notification>
</template>
<script setup lang="ts">
import { BittsAvatar, BittsButton, BittsSvg } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import {
  SALESFORCE_DATA_SOURCE_TYPE,
  SALESFORCE_INTEGRATION_FRIENDLY_NAME,
  SNOWFLAKE_DATA_SOURCE_TYPE,
  SNOWFLAKE_INTEGRATION_FRIENDLY_NAME,
} from '@/constants/data_sources';
import { useNotificationsStore } from '@/stores';
import { Notification as NotificationType } from '@/types/notifications';

const { item } = defineProps<{ item: NotificationType }>();

const emit = defineEmits<(e: 'action-clicked', action: string) => void>();

const friendlyName = computed(() => item.data?.integration_friendly_name);

const integrationType = computed(() => {
  if (!item.data?.integration_friendly_name) return null;
  switch (item.data?.integration_friendly_name) {
    case SALESFORCE_INTEGRATION_FRIENDLY_NAME:
      return SALESFORCE_DATA_SOURCE_TYPE;
    case SNOWFLAKE_INTEGRATION_FRIENDLY_NAME:
      return SNOWFLAKE_DATA_SOURCE_TYPE;
    default:
      return null;
  }
});

const router = useRouter();
const notificationsStore = useNotificationsStore();
async function viewError() {
  emit('action-clicked', 'View Error');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  await router.push({ name: 'integrations' });
}
</script>
