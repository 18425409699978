import {
  CUSTOMERS_STANDARD_TYPE,
  OPEN_OPPS_STANDARD_TYPE,
  PROSPECTS_STANDARD_TYPE,
  Population,
  StandardPopulationType,
} from '@/types/populations';

export const STANDARD_POPULATION_NAMES = [
  'Customers',
  'Open Opportunities',
  'Prospects',
];
export const STANDARD_POPULATION_TYPES = [
  CUSTOMERS_STANDARD_TYPE,
  OPEN_OPPS_STANDARD_TYPE,
  PROSPECTS_STANDARD_TYPE,
];

export const ALL_STANDARD_POPULATIONS = [
  {
    name: 'Customers',
    standard_type: CUSTOMERS_STANDARD_TYPE,
  },
  {
    name: 'Open Opportunities',
    standard_type: OPEN_OPPS_STANDARD_TYPE,
  },
  {
    name: 'Prospects',
    standard_type: PROSPECTS_STANDARD_TYPE,
  },
];

export const MAP_LINK_TYPE_TO_ROUTE_NAME = {
  edit: 'edit_population',
  sharing: 'populations.sharing',
  view: 'population_details',
};

export const MAP_POP_NAME_TO_STANDARD_TYPE = {
  Prospects: PROSPECTS_STANDARD_TYPE,
  'Open Opportunities': OPEN_OPPS_STANDARD_TYPE,
  Customers: CUSTOMERS_STANDARD_TYPE,
};

export const MAP_STANDARD_TYPE_TO_POP_NAME = {
  prospects: 'Prospects',
  open_opportunities: 'Open Opportunities',
  customers: 'Customers',
};

// get a population from a list of populations by standard type
export const getPopulationByStandardType = (
  type: StandardPopulationType,
  populations: Population[],
) => {
  return populations.find((pop) => pop.standard_type === type);
};

export const emptyPopulation = (standardType: StandardPopulationType) => {
  return {
    name: MAP_STANDARD_TYPE_TO_POP_NAME[standardType],
    standard_type: standardType,
    standardType,
    isEmpty: true,
  };
};

export const getPopulationTypeName = (
  populationType: StandardPopulationType,
) => {
  switch (populationType) {
    case CUSTOMERS_STANDARD_TYPE:
      return 'Customers';
    case PROSPECTS_STANDARD_TYPE:
      return 'Prospects';
    case OPEN_OPPS_STANDARD_TYPE:
      return 'Open Opportunities';
    default:
      return '';
  }
};
