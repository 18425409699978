<template>
  <Notification v-if="partnerOrg?.name && shareRequest" :item="item">
    <template #logo>
      <BittsAvatar :org="partnerOrg" :show-initials="true" size="medium" />
    </template>
    <template #message> Data Share Request </template>
    <template #description>
      {{ partnerOrg.name }} requested data from your {{ populationNames }}
    </template>
    <template #action>
      <div v-if="requestStatus === 'pending'" class="flex items-center gap-4">
        <BittsButton
          text="Configure Sharing"
          type="primary"
          size="x-small"
          @click="viewRequest"
        />
        <BittsButton
          v-if="!hasMultiplePops"
          text="Ignore"
          type="danger"
          variant="ghost"
          size="x-small"
          @click="ignoreRequest"
        />
      </div>
      <BittsButton
        v-else-if="requestStatus === 'fulfilled'"
        text="View Overlaps"
        type="neutral"
        size="x-small"
        @click="viewOverlaps"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'population']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script setup lang="ts">
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import { SINGLE_OVERLAPS_TYPE } from '@/constants/reports';
import {
  useDataRequestsStore,
  useNotificationsStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';
import { Notification as NotificationType } from '@/types/notifications';

const { item } = defineProps<{ item: NotificationType }>();

const emit = defineEmits<(e: 'action-clicked', action: string) => void>();

const partnersStore = usePartnersStore();
const partnerOrg = computed(() => {
  if (!shareRequest.value) return null;
  return partnersStore.getPartnerOrgById(shareRequest.value?.partner_org_id);
});

const populationsStore = usePopulationsStore();
const ourPop = computed(() =>
  populationsStore.getPopulationById(shareRequest.value?.population_id || 0),
);

const hasMultiplePops = computed(() => {
  if (!item.data?.target_population_names) return false;
  const popNames = [...(item.data.target_population_names as string[])];
  return popNames.length > 1;
});
const populationNames = computed(() => {
  if (!item.data?.target_population_names) return 'Population';
  const popNames = [...(item.data.target_population_names as string[])];
  return hasMultiplePops.value
    ? `${popNames.slice(0, -1).join(', ')} and ${popNames.at(-1)} Populations`
    : `${ourPop.value?.name} Population`;
});

const dataRequestsStore = useDataRequestsStore();
const shareRequest = computed(() =>
  dataRequestsStore.getInboundShareRequestById(item.object_id as string),
);
const requestStatus = computed(() => shareRequest.value?.status);

const router = useRouter();
const notificationsStore = useNotificationsStore();
async function ignoreRequest() {
  emit('action-clicked', 'Decline');
  await dataRequestsStore.ignoreShareRequest(item.object_id as string);
  await notificationsStore.markRead({ id: item.id, isRead: true });
}
async function viewRequest() {
  emit('action-clicked', 'Share');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  const query: {
    tab: string;
    population_id?: number;
  } = { tab: 'sharing' };
  if (!hasMultiplePops.value)
    query.population_id = shareRequest.value?.population_id;
  await router.push({
    name: 'partner_details',
    params: { partner_org_id: shareRequest.value?.partner_org_id },
    query,
  });
}
async function viewOverlaps() {
  emit('action-clicked', 'View Overlaps');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  await router.push({
    name: 'account_mapping.create',
    query: {
      type: SINGLE_OVERLAPS_TYPE,
      partner_id: shareRequest.value?.partner_org_id,
    },
  });
}
</script>
