<template>
  <BittsTable
    v-if="columns.length && rows.length"
    class="bitts-reports-table"
    :pagination-page-size="MAX_TABLE_SIZE"
    :columns="columns"
    :compress-columns="true"
    :rows="rows"
    :search-query="reportSearch"
    :hide-pagination="!showPagination"
    :pagination="true"
    :suppress-row-click-selection="true"
    :row-height="70"
    :row-multi-select-with-click="true"
    :on-row-selected="(e: RowNode) => onReportChecked(e)"
    :use-search="true"
    results-text=""
    row-selection="multiple"
  />
</template>

<script setup lang="ts">
/* eslint-disable vue/no-unused-components */
import { BittsTable } from '@crossbeam/bitts';

import { ColDef, ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { RouteLocationRaw } from 'vue-router';

import PartnersInReportCell from '@/components/reports/PartnersInReportCell.vue';
import ReportNameAndFolderCell from '@/components/reports/ReportNameAndFolderCell.vue';
import ReportNotificationsCell from '@/components/reports/ReportNotificationsCell.vue';
import ReportOverflowMenuCell from '@/components/reports/ReportOverflowMenuCell.vue';
import ReportTypeCell from '@/components/reports/ReportTypeCell.vue';
import ReportUserCell from '@/components/reports/ReportUserCell.vue';

import useAuth from '@/composables/useAuth';
import {
  ALL_PARTNERS_TYPE,
  CUSTOM_TYPE,
  ECOSYSTEM,
  GREENFIELD_CONSOLIDATED_TYPES,
  GREENFIELD_TYPE,
  OVERLAPS,
  PARTNER_TAGS_TYPE,
  POTENTIAL_REVENUE_TYPE,
  SINGLE_OVERLAPS_TYPE,
} from '@/constants/reports';
import { useBillingStore, usePartnersStore, useTeamStore } from '@/stores';
import { Partner } from '@/types/partners';
import { Report } from '@/types/reports';
import { User } from '@/types/root';

type ReportCell = {
  report: Report;
  hideExport: boolean;
  isOnReportsPage: boolean;
  partnersInReport: Partner[];
  hasWritePermissions: boolean;
  trialOver: boolean;
  reportLink: RouteLocationRaw;
  updatedByUser: User;
  reportType: string;
};
const {
  reports = [],
  reportSearch = '',
  hideExport = false,
  isOnReportsPage = true,
  trialOver = false,
} = defineProps<{
  reports?: Report[];
  reportSearch?: string;
  hideExport?: boolean;
  isOnReportsPage?: boolean;
  trialOver?: boolean;
}>();

const emit = defineEmits<(e: 'report-checked', reports: string[]) => void>();

const nameComparator = (cellA: ReportCell, cellB: ReportCell) => {
  if (cellA.report.name === cellB.report.name) return 0;
  return cellA.report.name > cellB.report.name ? 1 : -1;
};

const partnerComparator = (cellA: ReportCell, cellB: ReportCell) => {
  if (cellA.partnersInReport.length === cellB.partnersInReport.length) return 0;
  return cellA.partnersInReport.length > cellB.partnersInReport.length ? 1 : -1;
};

const timeComparator = (cellA: ReportCell, cellB: ReportCell) => {
  if (cellA.report.updated_at === cellB.report.updated_at) return 0;
  return cellA.report.updated_at > cellB.report.updated_at ? 1 : -1;
};

const MAX_TABLE_SIZE = 50;

const DEFAULT_COL_FIELDS = {
  autoHeight: true,
  suppressMovable: true,
};

const { hasScope } = useAuth();

const currentSelectedReports = ref<string[]>([]);

const columnHeadings = computed<ColDef[]>(() => {
  return [
    {
      ...DEFAULT_COL_FIELDS,
      cellRenderer: ReportNameAndFolderCell,
      field: 'Report Name',
      getQuickFilterText: (params: ICellRendererParams) => {
        if (!params?.value?.report) return '';
        return params.value.report.name;
      },
      sortable: true,
      initialSort: 'asc',
      comparator: (cellA: ReportCell, cellB: ReportCell) =>
        nameComparator(cellA, cellB),
      checkboxSelection: !trialOver,
      headerCheckboxSelection: !trialOver,
      minWidth: 300,
    },
    {
      ...DEFAULT_COL_FIELDS,
      cellRenderer: PartnersInReportCell,
      field: 'Partners in Report',
      sortable: true,
      comparator: (cellA: ReportCell, cellB: ReportCell) =>
        partnerComparator(cellA, cellB),
      maxWidth: 200,
    },
    {
      cellRenderer: ReportTypeCell,
      field: 'Report Type',
      maxWidth: 150,
    },
    {
      ...DEFAULT_COL_FIELDS,
      cellRenderer: ReportUserCell,
      field: 'Last Edited',
      sortable: true,
      comparator: (cellA: ReportCell, cellB: ReportCell) =>
        timeComparator(cellA, cellB),
      maxWidth: 200,
    },
    {
      ...DEFAULT_COL_FIELDS,
      cellRenderer: ReportNotificationsCell,
      field: 'Notifications',
      maxWidth: 120,
    },
    {
      ...DEFAULT_COL_FIELDS,
      cellRenderer: ReportOverflowMenuCell,
      field: ' ',
      maxWidth: 62,
    },
  ] as ColDef[];
});
const hasWriteReportPermissions = computed(() => hasScope('write:reports'));
const columns = computed(() => {
  return columnHeadings.value.map((heading) => {
    const newHeading = { ...heading };
    // need write permissions to check/uncheck
    if (newHeading.checkboxSelection) {
      newHeading.checkboxSelection = hasWriteReportPermissions.value;
      newHeading.headerCheckboxSelection = hasWriteReportPermissions.value;
    }
    return newHeading;
  });
});

const { authorizations, revokedUsers } = storeToRefs(useTeamStore());
const { isOnFreeTrial } = storeToRefs(useBillingStore());
const { partnerOrgsLookup } = storeToRefs(usePartnersStore());
function isOneToOne({
  our_population_ids: ourPopulationIds,
  partner_population_ids: partnerPopulationIds,
}: {
  our_population_ids: number[];
  partner_population_ids: number[];
}) {
  return ourPopulationIds.length === 1 && partnerPopulationIds.length === 1;
}
function getReportUser(report: Report) {
  const updatedByAuthorization = authorizations.value.find(
    (authorization) => authorization.user.id === report.updated_by_user_id,
  );
  const editedByRevokedUser = revokedUsers.value.find(
    (revokedUser) => revokedUser.id === report.updated_by_user_id,
  );
  const updatedByUser = updatedByAuthorization
    ? updatedByAuthorization.user
    : editedByRevokedUser
      ? {
          ...editedByRevokedUser,
          last_name: `${editedByRevokedUser.last_name} (Deactivated)`,
        }
      : { first_name: 'Admin', last_name: '' };
  return updatedByUser;
}
function getReportType(report: Report) {
  const flow = report.consolidated_report_type;
  if (GREENFIELD_CONSOLIDATED_TYPES.includes(flow)) return GREENFIELD_TYPE;
  if (flow === ECOSYSTEM)
    return report.tag_id ? PARTNER_TAGS_TYPE : ALL_PARTNERS_TYPE;
  if (flow === OVERLAPS)
    return isOneToOne(report) ? SINGLE_OVERLAPS_TYPE : CUSTOM_TYPE;
  return POTENTIAL_REVENUE_TYPE;
}
function partnerOrgs(report: Report) {
  const partnerOrgs = report.partner_organization_ids.map(
    (orgId: number) => partnerOrgsLookup.value[orgId],
  );
  return partnerOrgs.filter((org) => !!org); // filter out potential undefined values
}
const rows = computed(() => {
  if (!reports) return [];
  return reports.map((report: Report) => {
    const row: Record<string, ReportCell> = {};
    const updatedByUser = getReportUser(report) as User;
    columns.value.forEach((col) => {
      row[col.field as string] = {
        report,
        hideExport: Boolean(hideExport || isOnFreeTrial.value),
        isOnReportsPage,
        partnersInReport: partnerOrgs(report),
        hasWritePermissions: hasWriteReportPermissions.value,
        trialOver,
        reportLink: {
          name: 'edit_report',
          params: { report_id: report.id },
        },
        updatedByUser,
        reportType: getReportType(report),
      };
    });
    return row;
  });
});

const showPagination = computed(() => rows.value.length > MAX_TABLE_SIZE);

function onReportChecked(node: RowNode) {
  const reportId = node.data['Report Name'].report.id;
  const reportIndex = currentSelectedReports.value.indexOf(reportId);
  if (reportIndex >= 0) {
    currentSelectedReports.value.splice(reportIndex, 1);
  } else {
    currentSelectedReports.value.push(reportId);
  }
  emit('report-checked', currentSelectedReports.value);
}
</script>
<style lang="pcss">
.bitts-reports-table {
  .ag-body-horizontal-scroll {
    @apply invisible;
  }

  .ag-center-cols-container {
    @apply px-8;
  }

  .ag-header {
    @apply border-b-0;
  }

  .ag-header-cell:first-child {
    @apply pl-[33px];
  }

  .ag-header-select-all {
    @apply mr-24;
  }

  .ag-row {
    @apply rounded-bts-md w-[98.8%];
  }

  &.bitts-table .ag-cell {
    @apply px-0;
  }

  .reports-tab-list__header {
    @apply flex items-center w-full justify-between mb-16;
  }

  .reports-table-cell__wrapper {
    @apply flex flex-col justify-center h-full w-full py-16 pr-0 pl-8;
  }

  .ag-cell-value {
    @apply w-full;
  }

  .ag-cell-wrapper {
    @apply items-stretch h-full;
    width: inherit;
  }

  .ag-selection-checkbox {
    @apply pl-24 h-full;
  }

  .ag-theme-alpine .ag-checkbox-input-wrapper,
  .ag-theme-alpine .ag-checkbox-input {
    @apply cursor-pointer;
  }

  .ag-theme-alpine
    .ag-cell-wrapper
    > :not(.ag-cell-value):not(.ag-group-value) {
    height: auto;
  }

  .ag-theme-alpine .ag-ltr .ag-row-drag,
  .ag-theme-alpine .ag-ltr .ag-selection-checkbox,
  .ag-theme-alpine .ag-ltr .ag-group-expanded,
  .ag-theme-alpine .ag-ltr .ag-group-contracted {
    @apply m-0;
  }
}
</style>
