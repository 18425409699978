<template>
  <BittsModalTwo
    :width="600"
    name="map-duns-modal"
    primary-button-text="Apply"
    @closed="close"
    @action="mapColumns"
    :loading="loading"
    :action-loading="isMappingData"
  >
    <template #header>
      <h2 class="title mt-12">Map Duns Number</h2>
    </template>
    <FieldMapping label="DUNS Number">
      <BittsSelect
        v-model="dunsField"
        :options="unmappedColumns"
        @update="updateMappings"
      />
    </FieldMapping>
  </BittsModalTwo>
</template>
<script setup>
import { BittsModalTwo, BittsSelect } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import FieldMapping from '@/components/data-sources/FieldMapping.vue';

import { crossbeamApi } from '@/api';
import useColumnMapping from '@/composables/useColumnMapping';
import { useSourcesStore } from '@/stores';

const props = defineProps({
  accountSourceId: {
    type: Number,
    required: true,
  },
});

const sourcesStore = useSourcesStore();

useHead({ title: 'Map DUNS Field - Crossbeam' });

const router = useRouter();
const { loading, unmappedColumns } = useColumnMapping(props.accountSourceId);

const dunsField = ref(undefined);
function updateMappings(v) {
  unmappedColumns.value = v.unmappedColumns;
}

async function close() {
  await router.push({ name: 'data-sources' });
}

const isMappingData = ref(false);
async function mapColumns() {
  isMappingData.value = true;

  const body = [
    {
      mdm_property: 'company_duns_number',
      source_field_id: unmappedColumns.value.find(
        (col) => col.value === dunsField.value,
      ).id,
    },
  ];
  await crossbeamApi.PATCH(`/v0.3/sources/${props.accountSourceId}/fields`, {
    body,
  });

  isMappingData.value = false;
  sourcesStore.refreshSourcesStore();
  await close();
}
</script>
<style lang="pcss" scoped>
.c-google-sheets-two__icon {
  @apply w-60 h-60 mb-6 mt-12;
}
.title {
  @apply text-neutral-text-strong font-bold text-xl mb-4 px-16 text-center w-full;
}
.header {
  @apply font-bold text-neutral-text-strong;
}
</style>
