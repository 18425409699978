<template>
  <div class="report-filter-group-selector">
    <div
      v-if="feedList.length > 1"
      class="report-filter-group-selector__feed-list"
    >
      <button
        v-for="id in feedList"
        :key="`feed__${id}`"
        :data-testid="`${id}`"
        class="report-filter-group-selector__feed"
        :class="{
          'report-filter-group-selector__feed-selected': currentFeed === id,
        }"
        @click="currentFeed = id"
        type="button"
      >
        <div class="flex items-center">
          <BittsSvg
            :svg="`${feedLookup[id].feed?.integration ? feedLookup[id].feed.integration.type : 'file-upload'}Icon`"
            class="w-16 h-16 mr-8"
          />
          {{ feedLookup[id].label }}
        </div>
        <FontAwesomeIcon
          :icon="['fak', 'chevron-right']"
          class="text-neutral-accent ml-8"
        />
      </button>
    </div>
    <div class="report-filter-group-selector__source-list">
      <div class="pb-12 px-8">
        <BittsInput
          v-model="searchQuery"
          size="small"
          type="search"
          data-testid="report-filter-group-selector__field-search"
          name="filter-search"
          placeholder="Search anything"
        />
      </div>
      <div
        v-for="(source, idx) in filteredCurrentSources"
        :key="`source__${source.id}`"
        class="flex flex-col items-stretch justify-center"
      >
        <div class="report-filter-group-selector__source-name">
          {{ source.table }}
        </div>
        <button
          v-for="field in source.fields"
          :key="`field__${field.id}`"
          class="report-filter-group-selector__field-name"
          @click="emit('field-chosen', { source, field })"
          type="button"
        >
          {{ field.display_name }}
        </button>
        <BittsDivider
          v-if="idx !== filteredCurrentSources.length - 1"
          class="m-0 mb-6"
        />
      </div>
      <div
        v-if="!filteredCurrentSources.length"
        class="report-filter-group-selector__empty"
      >
        <div class="text-neutral-text-strong mb-4 font-bold">
          No matches found
        </div>
        <div class="text-neutral-text"> Try searching for something else </div>
      </div>
      <div class="mx-8">
        <BittsButton
          v-if="isOwn"
          size="x-small"
          type="neutral"
          text="Sync Additional Fields"
          :left-icon="['fak', 'cycle']"
          class="my-8 w-full"
          @click="onSyncAdditionalFields"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  BittsButton,
  BittsDivider,
  BittsInput,
  BittsSvg,
} from '@crossbeam/bitts';

import { sortBy } from 'lodash';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import useFeeds from '@/composables/useFeeds';
import {
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { useFeedsStore } from '@/stores';

const props = defineProps({
  isOwn: {
    type: Boolean,
    default: true,
  },
  sourceList: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['field-chosen']);

const router = useRouter();

const feedsStore = useFeedsStore();
const { normalizeSchemaName } = useFeeds();

const searchQuery = ref('');

function getSourcesWithAlphaFields(source) {
  const newSource = { ...source };
  newSource.fields = sortBy(newSource.fields, ['display_name']);
  return newSource;
}

const feedLookup = computed(() => {
  const lookup = {};
  props.sourceList.forEach((source) => {
    const { schema, id, feed_id: feedId, table } = source.value;
    const sources = getSourcesWithAlphaFields(source.value);
    const feed = feedsStore.getFeedById(feedId);
    if (
      schema.includes(FILE_UPLOAD_DATA_SOURCE_TYPE) ||
      schema.includes(GOOGLE_SHEETS_DATA_SOURCE_TYPE)
    ) {
      // there is only one "source" per upload
      lookup[`upload__${id}`] = {
        sources: [sources],
        feed,
        label: table,
        schema_name: schema,
        id: feedId,
      };
    } else {
      const id = `feed__${feedId}`;
      if (!lookup[id]) {
        const label = normalizeSchemaName(schema);
        lookup[id] = {
          sources: [],
          label,
          feed,
          schema_name: schema,
          id: feedId,
        };
      }
      lookup[id].sources.push(sources);
    }
  });
  return lookup;
});
const feedList = computed(() => Object.keys(feedLookup.value));

const currentFeed = ref(feedList.value[0]);
const currentSources = computed(
  () => feedLookup.value[currentFeed.value]?.sources,
);
const filteredCurrentSources = computed(() => {
  if (!searchQuery.value) return currentSources.value || [];
  return (
    currentSources.value
      .map((source) => {
        const normalizedQuery = searchQuery.value.toLowerCase();
        const fields = source.fields.filter((field) =>
          field.display_name.toLowerCase().includes(normalizedQuery),
        );
        return { ...source, fields };
      })
      .filter((source) => !!source.fields.length) || []
  );
});

async function onSyncAdditionalFields() {
  const { schema_name: schemaName, id } = feedLookup.value[currentFeed.value];
  if (
    schemaName.includes(FILE_UPLOAD_DATA_SOURCE_TYPE) ||
    schemaName.includes(GOOGLE_SHEETS_DATA_SOURCE_TYPE)
  ) {
    await router.push({ name: 'data-sources' });
  } else if (schemaName.includes(SNOWFLAKE_DATA_SOURCE_TYPE)) {
    await router.push({ name: 'data-sources-settings', params: { id } });
  } else {
    await router.push({
      name: 'data-templates',
      params: { id },
      query: { customizing: true },
    });
  }
}
</script>

<style scoped lang="pcss">
.report-filter-group-selector {
  @apply flex items-stretch;
}
.report-filter-group-selector__empty {
  @apply text-sm text-center;
}
.report-filter-group-selector__feed {
  @apply p-8 rounded-bts-sm text-left mr-4 flex justify-between items-center;
  &:hover {
    @apply bg-neutral-background;
  }
}
.report-filter-group-selector__feed-selected {
  @apply bg-neutral-background;
}
.report-filter-group-selector__feed-list {
  @apply flex flex-col items-stretch p-4 pr-0 border-r border-solid border-neutral-border;
}
.report-filter-group-selector__field-name {
  @apply text-base text-neutral-text-strong mb-2 px-4 mx-4 rounded-bts-sm py-4 text-left;
  &:hover {
    @apply bg-neutral-background;
  }
}
.report-filter-group-selector__source-list {
  @apply pt-12 max-h-[350px] overflow-y-scroll flex flex-col justify-between min-w-[300px];
}
.report-filter-group-selector__source-name {
  @apply text-neutral-text text-sm mb-6 px-4 mx-4 text-left;
}
</style>
