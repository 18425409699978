<template>
  <Notification :item="item">
    <template #logo>
      <BittsAvatar
        :org="partnerOrg || {}"
        :show-initials="true"
        size="medium"
      />
    </template>
    <template #message> Data Sharing Changed </template>
    <template #description>
      <span>{{ description }}</span>
    </template>
    <template #action>
      <BittsButton
        :text="buttonText"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="onButtonClick"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'population']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script setup lang="ts">
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { isEmpty } from 'lodash';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import { BASE_ROUTES } from '@/constants/routes';
import { useNotificationsStore, usePartnersStore } from '@/stores';
import { Notification as NotificationType } from '@/types/notifications';

const { item } = defineProps<{ item: NotificationType }>();

const emit = defineEmits<(e: 'action-clicked', action: string) => void>();

const partnersStore = usePartnersStore();
const partnerOrg = computed(() =>
  partnersStore.getPartnerOrgById(item.data?.partner_org_id || 0),
);

const hasReports = computed(() => item.data && !isEmpty(item.data.reports));

const buttonText = computed(() =>
  hasReports.value ? 'View Reports' : 'View Sharing',
);
const description = computed(() => {
  const partnerName = item.data?.partner_name;
  const partnerPopulationName = item.data?.partner_population_name;
  const sharingStoppedMsg = `${partnerName} has changed sharing settings from their ${partnerPopulationName} Population with you`;

  if (hasReports.value && item.data?.reports) {
    const reports = [...(item.data.reports as Record<string, string>[])];
    const reportNamesList = reports.map(({ name }) => name).join(', ');

    return `${sharingStoppedMsg}. This change may affect the following reports: ${reportNamesList}.`;
  }
  return `${sharingStoppedMsg}.`;
});

const router = useRouter();
const notificationsStore = useNotificationsStore();
async function viewSharing() {
  emit('action-clicked', 'View Sharing');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  await router.push({
    name: 'partner_details',
    params: { partner_org_id: item.data?.partner_org_id },
    query: { tab: 'shared_with_you', from: 'partners' },
  });
}
async function viewReports() {
  emit('action-clicked', 'View Reports');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  await router.push({ name: BASE_ROUTES.REPORTS });
}
function onButtonClick() {
  return hasReports.value ? viewReports() : viewSharing();
}
</script>
