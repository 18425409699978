import {
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';

export default function () {
  function uppercaseFirstChar(name) {
    return name.charAt(0).toUpperCase() + name.substring(1);
  }

  function uppercaseHubspot(name) {
    return (
      name.charAt(0).toUpperCase() + // H
      name.substring(1, 3) + // ub
      name.charAt(3).toUpperCase() + // S
      name.substring(4)
    ); // pot
    /* HubSpot! */
  }

  function dataSourceTypeFromSchema(schema) {
    const schemaParts = schema.split('_');
    schemaParts.pop();
    return schemaParts.join('_');
  }

  function normalizeSchemaName(schema) {
    // schemas for CRMs look like this: 'salesforce_1234'
    // we want to turn that into 'Salesforce'
    if (
      !schema.includes(MD_DATA_SOURCE_TYPE) &&
      !schema.includes(GOOGLE_SHEETS_DATA_SOURCE_TYPE)
    ) {
      const name = schema.split('_')[0];
      // Now our Hubspot sources have `hubspot_v3...` schema
      // but sources from feeds converted int Hubspot v3 have `hubspot_...` schema
      if (name === HUBSPOT_DATA_SOURCE_TYPE) return uppercaseHubspot(name);
      return uppercaseFirstChar(name);
    }
    // for dynamics and google sheets it is two words, so we want to get both
    const names = schema.split('_');
    return `${uppercaseFirstChar(names[0])} ${uppercaseFirstChar(names[1])}`;
  }

  return { normalizeSchemaName, dataSourceTypeFromSchema };
}
