<template>
  <div class="filter-segment">
    <div class="filter-segment__filter flex gap-8">
      <BittsMultiselect
        v-if="!isFirstFilter && !isCombinatorsLocked"
        :key="filter.combinator"
        :dropdown-match-select-width="false"
        :model-value="filter.combinator"
        :options="COMBINATOR_OPTIONS"
        placeholder="Select a source field"
        class="filter-segment__combinator"
        @update:model-value="(comb) => emit('change-combinator', comb)"
      />
      <div
        class="flex-1 flex justify-center items-center"
        v-else-if="!isFirstFilter && isCombinatorsLocked"
      >
        <div class="text-neutral-text-weak">{{ filter.combinator }}</div>
      </div>
      <div class="flex">
        <BittsMultiselect
          :key="filter.source_field_id"
          custom-filter="label"
          :dropdown-match-select-width="false"
          :model-value="filter.source_field_id"
          :options="sourceFieldOptions"
          :class="{
            'filter-segment__source-field-with-source': fullSourceField,
          }"
          class="filter-segment__source-field"
          @update:model-value="onChangeSourceField"
        />
        <BittsMultiselect
          v-if="fullSourceField"
          :key="filter.operator"
          :dropdown-match-select-width="false"
          :model-value="filter.operator"
          :options="operatorOptions"
          :class="{
            'filter-segment__operator-not-empty': !isEmptySelected,
          }"
          class="filter-segment__operator"
          @update:model-value="onChangeOperator"
        />
        <div
          v-if="!isEmptySelected && fullSourceField"
          class="filter-segment__operands"
        >
          <BittsRangePicker
            v-if="isDatetimeSelected"
            v-click-away="onClickAway"
            format="YYYY-MM-DD"
            placeholder="Select a date"
            :is-open="rangePickerOpen"
            :use-open="cameFromQuickFilter"
            :initial-date="filter.operand"
            :use-single-date-picker="true"
            class="filter-segment__datepicker"
            @change="(ops) => emit('change-operands', ops)"
            @click="() => (rangePickerOpen = true)"
          />
          <BittsInput
            v-else-if="!isFilterSearchable"
            :model-value="filter.operand"
            :placeholder="
              isOwn ? 'Start typing here' : 'Type exact field to filter'
            "
            class="filter-segment__operand-input fullstory-hide dd-privacy-mask"
            name="filter-segment"
            @update:model-value="(ops) => emit('change-operands', ops)"
          />
          <BittsMultiselect
            v-else
            :auto-focus="isQuickFilter && !filter.operand"
            :default-open="isQuickFilter && !filter.operand"
            :dropdown-match-select-width="false"
            :hide-from-third-parties="true"
            :model-value="filter.operand"
            :options="operandOptions"
            :loading="isLoadingFieldOptions"
            :mode="selectMode"
            option-type="default"
            class="filter-segment__operand-select fullstory-hide dd-privacy-mask"
            @update:model-value="onOperandUpdate"
            @search-changed="onFieldSearchDebounced"
          />
        </div>
      </div>
      <slot name="actions" />
    </div>
  </div>
</template>

<script setup>
import {
  BittsInput,
  BittsMultiselect,
  BittsRangePicker,
} from '@crossbeam/bitts';

import { debounce, sortBy } from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { directive as vClickAway } from 'vue3-click-away';

import useGetPicklistFieldOptions from '@/composables/useGetPicklistFieldOptions';
import { TEMP_REPORT_FILTER_WITH_PICKLIST_OPTIONS } from '@/constants/feature_flags';
import { DATA_TYPES, OPERATORS } from '@/constants/populations';
import { BIGINT, BOOLEAN, DATETIME, DOUBLE, NUMBER } from '@/constants/reports';
import { captureException } from '@/errors';
import {
  useDataSharesStore,
  useFeatureFlagStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';

const props = defineProps({
  fields: {
    type: Array,
    default: () => [],
  },
  filter: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  position: {
    type: Number,
    required: true,
  },
  isOwn: {
    type: Boolean,
    default: true,
  },
  dataSourceType: {
    type: String,
    required: true,
  },
  isCombinatorsLocked: {
    type: Boolean,
    default: false,
  },
  isQuickFilter: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'change-combinator',
  'change-operands',
  'change-operator',
  'change-source-field',
  'delete-filter',
]);

const cameFromQuickFilter = ref(false);
onMounted(() => {
  if (props.isQuickFilter.value && !props.filter.operand) {
    cameFromQuickFilter.value = true;
    rangePickerOpen.value = true;
  }
});

const rangePickerOpen = ref(false);
function onClickAway() {
  rangePickerOpen.value = false;
}
const sourcesStore = useSourcesStore();

const isFirstFilter = computed(() => !props.position);

const FILTER_SEARCH_URL = urls.filterSearch;
const COMBINATOR_OPTIONS = [
  { label: 'and', value: 'AND' },
  { label: 'or', value: 'OR' },
];
const ARRAY_OPERATORS = ['in', 'not in'];
const USER_INPUT_OPERATORS = [
  'contains',
  'does not contain',
  '>',
  '<',
  '>=',
  '<=',
];
const EMPTY_OPERATORS = ['is empty', 'is not empty'];

const sourceFieldOptions = computed(() => sortBy(props.fields, ['label']));

const isLoadingFieldOptions = ref(false);

const featureFlagStore = useFeatureFlagStore();
const shouldUsePicklistOptions = computed(() =>
  featureFlagStore.hasFeatureFlag(TEMP_REPORT_FILTER_WITH_PICKLIST_OPTIONS),
);

async function onFieldSearch(query, id = null) {
  try {
    let field;
    if (id) field = id;
    else field = fullSourceField.value.id;
    let convertedQuery = query;
    if (dataType.value === NUMBER) convertedQuery = parseInt(query);
    isLoadingFieldOptions.value = true;
    if (dataType.value === BOOLEAN) {
      operandOptions.value = [
        { value: 'true', label: 'true' },
        { value: 'false', label: 'false' },
      ];
    } else {
      operandOptions.value = await useGetPicklistFieldOptions({
        searchUrl: FILTER_SEARCH_URL,
        query: convertedQuery,
        fieldId: field,
        fieldOptions: fullSourceField.value.options,
        dataSourceType: props.dataSourceType,
        usePicklistOptions: shouldUsePicklistOptions.value,
      }).then((res) =>
        res.map((value) => {
          return { value, label: value };
        }),
      );
    }
    isLoadingFieldOptions.value = false;
  } catch (err) {
    captureException(err);
  }
}
async function getInitialOperandOptions(id) {
  if (!fullSourceField.value) return;
  await onFieldSearch('', id);
}
const operandOptions = ref([]);
const onFieldSearchDebounced = ref(null);

onMounted(async () => {
  onFieldSearchDebounced.value = debounce(onFieldSearch, 300);
  if (props.isOwn && props.filter.source_field_id)
    await getInitialOperandOptions(props.filter.source_field_id);
});

async function onChangeSourceField(id) {
  emit('change-source-field', id);
  emit('change-operator', 'in');
  emit('change-operands', undefined);
  if (props.isOwn) await getInitialOperandOptions(id);
}
function onChangeOperator(op) {
  emit('change-operator', op);
  emit('change-operands', undefined);
}
function onOperandUpdate(operands) {
  let ops = operands;
  const areOperandsArray = Array.isArray(ops);
  if (areOperandsArray && !ops.length) ops = undefined;
  else if (
    selectMode.value === 'multiple' &&
    !areOperandsArray &&
    !isDatetimeSelected.value &&
    isFilterSearchable.value
  )
    ops = [ops];
  emit('change-operands', ops);
}

const fullSourceField = computed(() => {
  const { source_field_id: sourceFieldId } = props.filter;
  const dataSharesStore = useDataSharesStore();
  if (!sourceFieldId) return '';
  let field;
  if (props.isOwn) field = sourcesStore.getSourceFieldById(sourceFieldId);
  else field = dataSharesStore.getSharedFieldBySourceFieldId(sourceFieldId);
  return field || '';
});

const operatorOptions = computed(() => {
  if (!fullSourceField.value) return [];
  const dataType = fullSourceField.value.data_type;
  const relevantOperatorIds = DATA_TYPES[dataType].operators;
  return OPERATORS.filter((op) => relevantOperatorIds.includes(op.id)).map(
    (op) => {
      let label = op.label;
      if (op.labelOverrides[dataType]) {
        label = op.labelOverrides[dataType];
      }
      return { label, value: op.id };
    },
  );
});

const dataType = computed(() => {
  if (!fullSourceField.value) return '';
  return fullSourceField.value.data_type;
});
const selectMode = computed(() => {
  const { operator } = props.filter;
  if (!operator) return 'default';
  if (ARRAY_OPERATORS.includes(operator)) return 'multiple';
  return 'default';
});
const isFilterSearchable = computed(() => {
  if (!props.isOwn) return false;

  if (
    fullSourceField.value.pg_data_type === BIGINT ||
    fullSourceField.value.pg_data_type === DOUBLE
  )
    return false;

  return !USER_INPUT_OPERATORS.includes(props.filter.operator);
});
const isEmptySelected = computed(() =>
  EMPTY_OPERATORS.includes(props.filter.operator),
);
const isDatetimeSelected = computed(() => dataType.value === DATETIME);
</script>

<style scoped lang="pcss">
.filter-segment {
  @apply flex items-center justify-between;
}
.filter-segment__combinator {
  @apply w-[76px];
}
.filter-segment__filter {
  @apply flex;
}
.filter-segment__operands {
  @apply self-stretch;
}
.filter-segment__operand-input {
  @apply min-w-[250px] border-none outline-none rounded-none;
}
.filter-segment__operand-select,
.filter-segment__source-field {
  @apply min-w-[150px];
}
.filter-segment__operand-select {
  @apply h-full;
}
.filter-segment__where {
  @apply w-[72px] text-neutral-text-weak py-4 text-center bg-neutral-background-weak rounded-bts-md;
}
</style>

<style lang="pcss">
.filter-segment {
  .bitts-range-picker {
    @apply h-full;
  }
  .ant-picker {
    @apply h-full border-neutral-border py-0 rounded-r-lg rounded-l-none;
  }
  .ant-select-selection-search-input {
    @apply h-full !important;
  }
  .ant-picker-focused {
    @apply shadow-none border-neutral-border;
  }
  .ant-picker-input > input::placeholder {
    @apply text-base leading-[38px] text-neutral-text-placeholder;
  }
  .ant-select,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    @apply h-full;
  }
  .c-bitts-input {
    @apply border border-neutral-border rounded-lg rounded-l-none;
    .c-bitts-input__input {
      @apply rounded-l-none;
    }
    span.c-bitts-input input.ant-input-affix-wrapper {
      @apply outline-none border-none;
    }
  }
  .ant-input:focus:not(.c-bitts-input__borderless),
  .c-bitts-input:focus:not(.c-bitts-input__borderless) {
    @apply border border-neutral-border;
    box-shadow: none !important;
    border-right-width: 1px !important;
  }
  ant-select-single .ant-select-selector::after,
  .ant-select-single .ant-select-selector .ant-select-selection-item::after,
  .ant-select-single
    .ant-select-selector
    .ant-select-selection-placeholder::after {
    @apply hidden;
  }
}
.filter-segment__source-field-with-source,
.filter-segment__operator-not-empty {
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    @apply shadow-none border-neutral-border;
    border-right-width: 0 !important;
  }
}
.filter-segment__combinator,
.filter-segment__source-field:not(.filter-segment__operator-not-empty),
.filter-segment__operands,
.filter-segment__operator:not(.filter-segment__source-field-with-source) {
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    @apply shadow-none border-neutral-border;
  }
}
.filter-segment__operator-not-empty,
.filter-segment__source-field-with-source {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @apply border-r-0 rounded-r-none;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-right-width: 0 !important;
  }
}
.filter-segment__operator,
.filter-segment__operands {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @apply rounded-l-none;
  }
}
</style>
