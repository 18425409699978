import appConfig from '@/config';

const buildSalesEdgeUrl = (url) => `${appConfig.salesEdgeBaseUrl}${url}`;

export default {
  academy: {
    login: '/v0.1/academy/login',
  },
  api: {
    feeds: '/api/feeds',
  },
  timeline: {
    v0_1: '/v0.1/timeline',
  },
  session: {
    callback: '/v0.1/session/callback',
    logout: '/v0.1/logout',
  },
  forgotPassword: {
    post: '/v0.1/forgot-password',
  },
  external: {
    activeStatusPageEvents: '/api/v2/scheduled-maintenances/active.json',
  },
  notifications: {
    all: '/v0.1/notifications',
    markAllRead: '/v0.1/notifications/mark-all-read',
    one(id) {
      return `/v0.1/notifications/${id}`;
    },
    settings: {
      default: '/v0.1/notification-settings',
      bulk: '/v0.1/notification-settings/bulk',
    },
    threads: {
      view: '/v0.1/notification-views/threads',
    },
  },
  accountMappingMetrics: 'v0.1/partners/global/metrics',
  attribution: {
    all: '/v0.1/attribution/opportunities',
    closedWon: '/v0.1/attribution/won-pipeline/opportunities',
    opportunity: (masterUUID, opportunityID) =>
      `/v0.1/attribution/opportunities/${masterUUID}/${opportunityID}`,
    put: (masterUUID, opportunityID) =>
      `/v0.1/attributions/${masterUUID}/${opportunityID}`,
    delete: (attributionID) => `/v0.1/attributions/${attributionID}`,
    dismiss: (masterUUID, opportunityID) =>
      `/v0.1/attribution/opportunities/${masterUUID}/${opportunityID}/metadata`,
    overlaps: '/v1/overlaps/accounts/search',
    metrics: '/v0.1/attribution/opportunities/metrics',
  },
  billing: {
    get: '/v0.2/billing',
    sync: '/v0.2/billing/sync',
    purchaseConnectorSeats: '/v0.2/billing/seats',
    editCard: (productHandle) => `/v0.2/billing/subscriptions/${productHandle}`,
    getBillingPortal: '/v0.2/billing/portal',
    syncBillingPortal: '/v0.2/billing/portal/sync',
    paymentInfo: '/v0.2/billing/payment',
    roleUpdate: '/v0.2/billing/roles-update/acknowledgement',
    selfServe: {
      subscribe: (productHandle) =>
        `/v0.2/billing/subscriptions/${productHandle}`,
      preview: (productHandle) =>
        `/v0.2/billing/subscriptions/${productHandle}/preview`,
      componentPreview: '/v0.2/billing/subscription/components/preview',
      componentPurchase: '/v0.2/billing/subscription/components',
      cancel: (productHandle) =>
        `/v0.2/billing/subscriptions/${productHandle}/cancellation`,
      reclaimSeats: '/v0.2/billing/subscriptions/components/reclaim',
      restore: (productHandle) =>
        `/v0.2/billing/subscriptions/${productHandle}/cancellation`,
      previewReduce: '/v0.2/billing/subscriptions/components/reduce/preview',
      reduce: '/v0.2/billing/subscriptions/components/reduce',
    },
  },
  ecosystemMetrics: '/v0.1/ecosystem-metrics',
  eaDowngrade: 'v0.1/ea/downgrade',
  oAuthApplications: {
    get: '/v0.1/oauth-applications',
    post: '/v0.1/oauth-applications',
    delete(oAuthApplicationId) {
      return `/v0.1/oauth-applications/${oAuthApplicationId}`;
    },
    patch(oAuthApplicationId) {
      return `/v0.1/oauth-applications/${oAuthApplicationId}`;
    },
  },
  publicInviteCode: {
    getOrgId(publicInviteCode) {
      return `/v0.1/public-invite-codes/${publicInviteCode}`;
    },
  },
  sources: {
    all: '/v0.1/sources',
    allV3: '/v0.3/sources',
    filterableSources(sourceId) {
      return `/v0.2/sources/${sourceId}/filterable-sources`;
    },
    allFilterableSources: '/v0.2/sources/filterable-sources',
    mapNewColumns(sourceId) {
      return `/v0.2/sources/${sourceId}/map-new-columns`;
    },
    pair(populationId, partnerPopulationId) {
      return '/v0.1/sources/pair/population'.concat(
        `/${populationId}/partner/${partnerPopulationId}`,
      );
    },
    delete(sourceId) {
      return `/v0.1/sources/${sourceId}`;
    },
    googleSheetsColumnsV2(feedId) {
      return `/v0.2/feeds/${feedId}/google-sheets/details`;
    },
    googleSheetsNames(feedId) {
      return `/v0.1/feeds/${feedId}/google-sheets/sheets`;
    },
  },
  img(imgName) {
    return `/static/img/${imgName}`;
  },
  slack: {
    installUrl: '/v0.1/slack/install-url',
    callback: '/public/v0.1/slack/auth/success',
    sharedChannel(slackId) {
      return `/v0.1/slack-app/channels/${slackId}`;
    },
  },
  partnerDirectory: '/v0.1/partnerbase-ecosystem',
  partnerStack: {
    callback: '/v0.1/partnerstack/auth/success',
    formFieldsByGroup(groupKey) {
      return `/v0.1/partnerstack/form-template/${groupKey}`;
    },
    installUrl: '/v0.1/partnerstack/install-url',
    integration: '/v0.1/partnerstack/integration',
    partnerships: '/v0.2/partnerstack/partnerships',
    partnershipSearch(partner) {
      return `v0.1/partnerstack/partnerships/${partner}`;
    },
    submitReferral: '/v0.2/partnerstack/submit',
    delete: '/v0.1/partnerstack/integration',
  },
  connections: {
    all: '/v0.1/connections',
    snowflake: '/v0.1/connections/snowflake',
    patch(connectionId) {
      return `/v0.1/connections/${connectionId}`;
    },
    populationSettings(connectionId) {
      return `/v0.1/connections/${connectionId}/salesforce/population-settings`;
    },
  },
  feeds: {
    all: '/v0.1/feeds',
    deleteHard(feedId) {
      return `/v0.1/feeds/${feedId}`;
    },
    details(feedId) {
      return `/v0.1/feeds/${feedId}`;
    },
    patch(feedId) {
      return `/v0.1/feeds/${feedId}`;
    },
    sourceFields(feedId) {
      return `/v0.2/feeds/${feedId}/source-fields`;
    },
    sync(feedId) {
      return `/v0.1/feeds/${feedId}/sync`;
    },
    limit: '/v0.1/feeds/limit',
  },
  /* The fact that these are /integrations is a legacy
   * from when we used to call data sources integrations */
  dataSources: {
    all: '/v0.1/integrations',
    requiredColumns(dataSourceType) {
      return `/v0.2/integrations/${dataSourceType}/required-columns`;
    },
  },
  tray: '/v0.1/tray-integrations',
  fileUploads: {
    jsonUploadV3: '/v0.3/file-uploads',
    tables: '/v0.3/file-uploads/tables',
    all: '/v0.2/file-uploads',
    fileOptions: '/v0.1/file-uploads/file-options',
    processFileUpload(uploadId) {
      return `/v0.1/file-uploads/${uploadId}/process-upload`;
    },
  },
  lists: {
    get_v2: '/v0.2/lists',
    post: '/v0.1/lists',
    addRecords(listId) {
      return `/v0.1/lists/${listId}/members`;
    },
    columns(listId, columnId) {
      return `/v0.1/lists/${listId}/columns/${columnId}`;
    },
    createColumn(listId) {
      return `/v0.1/lists/${listId}/columns`;
    },
    deleteRows(listId) {
      return `/v0.1/lists/${listId}/rows/delete`;
    },
    getOpportunity(sourceId, recordId) {
      return `/v0.1/records/${sourceId}/${recordId}/opportunities`;
    },
    getRowData(listId) {
      return `/v0.1/lists/${listId}/data`;
    },
    list(listId) {
      return `/v0.1/lists/${listId}`;
    },
    addPermissions(listId) {
      return `/v0.1/lists/${listId}/permissions`;
    },
    revokePermissions(listId, users) {
      const userParams = users.map((user) => `user_id=${user.id}`).join('&');
      return `/v0.1/lists/${listId}/permissions?${userParams}`;
    },
    postToSales(listId) {
      return `/v0.1/lists/${listId}/sales`;
    },
    putNote(listId, rowId) {
      return `/v0.1/lists/${listId}/rows/${rowId}/note`;
    },
    putCustomColumnNote(listId, rowId, columnId) {
      return `/v0.1/lists/${listId}/rows/${rowId}/columns/${columnId}`;
    },
    setOpportunity(listId, rowId) {
      return `/v0.1/lists/${listId}/rows/${rowId}/opportunity`;
    },
  },
  populations: {
    all_v3({ onlyInactive }) {
      return `/v0.3/populations?only_inactive=${onlyInactive}`;
    },
    allPartnerPops: '/v0.1/partner-populations',
    create_v3: '/v0.3/populations',
    delete(populationId) {
      return `/v0.2/populations/${populationId}`;
    },
    preview_v3: '/v0.3/population-preview',
    previewCount_v3: '/v0.3/population-preview-count',
    patch_v3(populationId) {
      return `/v0.3/populations/${populationId}`;
    },
  },
  population_stats: {
    all: '/alpha/population-stats',
  },
  records: {
    v0_2(sourceId, sourcePrimaryKey) {
      return `/v0.2/records/${sourceId}/${sourcePrimaryKey}`;
    },
  },
  auditLogs: {
    export: 'v0.1/audit-log/export',
  },
  matches: {
    export: '/v0.3/matches/export',
  },
  matchingIssues: {
    types: '/v0.1/matching-issue-types',
    get: (sourceId, recordId) =>
      `/v0.1/matching-issues/${sourceId}/${recordId}`,
    post: '/v0.1/matching-issues',
  },
  oauth: {
    authorize: '/v0.1/oauth/authorize',
    callback: '/v0.1/oauth/callback',
  },
  overlaps: {
    v0_3: '/v0.3/overlaps',
    byPartner(partnerId) {
      return `/v0.4/overlaps/${partnerId}`;
    },
  },
  proposals: {
    create_v0_4: '/v0.4/proposals',
    accept_v0_3(proposalId) {
      return `/v0.3/proposals/${proposalId}/accept`;
    },
    decline(proposalId) {
      return `/v0.1/proposals/${proposalId}/decline`;
    },
    remove(proposalId) {
      return `/v0.1/proposals/${proposalId}/revoke`;
    },
    byAcceptanceCode(acceptanceCode) {
      return `/v0.1/proposal-codes/${acceptanceCode}/proposal`;
    },
  },
  recordExportUsage: '/v0.1/record-export-usage',
  recordStats: '/v0.1/population-record-stats',
  reports: {
    all: '/v0.3/reports',
    create: '/v0.4/reports',
    partnerSources: '/v0.2/reports/partner-sources',
    folders: {
      all: '/v0.1/reports/folders',
      delete(id) {
        return `/v0.1/reports/folders/${id}`;
      },
    },
    delete(id) {
      return `/v0.2/reports/${id}`;
    },
    details(id) {
      return `/v0.4/reports/${id}`;
    },
    notificationConfigs(id) {
      return `/v0.2/reports/${id}/notification-configs`;
    },
  },
  reportData: {
    reportData: '/v0.4/report-data',
    export: '/v0.3/report-data/export',
    exportDownload(exportLink) {
      return `/v0.1/report-data/download?export_link_encoded=${exportLink}`;
    },
  },
  universalSearch: {
    v0_1: '/v0.1/search',
  },
  filterSearch: '/v0.1/search/source-data',
  partners: {
    all: '/v0.1/partners',
    users(partnerId) {
      return `/v0.1/partners/${partnerId}/users`;
    },
    usersWithListAccess(partnerUUID) {
      return `/v0.1/partners/${partnerUUID}/users?list_access_required=true`;
    },
    overlaps: '/v0.1/partners/global/overlap-counts',
    proposalsReceived: '/v0.1/partners/global/proposals-received',
    partnersWithFFAccess(ff) {
      return `/v0.1/feature-flags/partners/${ff}`;
    },
    deletePartnership(partnerUUID) {
      return `/v0.1/partners/${partnerUUID}`;
    },
    getVisibility: (partnerOrgUuid) =>
      `/v0.1/partners/${partnerOrgUuid}/team-access`,
  },
  offlinePartners: {
    create: '/v0.1/offline-partners',
    dataSources: {
      tables(uuid) {
        return `/v0.3/file-uploads/tables?offline_partner_org_uuid=${uuid}`;
      },
      sources(uuid) {
        return `/v0.2/sources?offline_partner_org_uuid=${uuid}`;
      },
      allFilterableSources(uuid) {
        return `/v0.2/sources/filterable-sources?offline_partner_org_uuid=${uuid}`;
      },
      filterableSources(sourceId, uuid) {
        return `/v0.2/sources/${sourceId}/filterable-sources?offline_partner_org_uuid=${uuid}`;
      },
      delete(sourceId, uuid) {
        return `/v0.1/sources/${sourceId}?offline_partner_org_uuid=${uuid}`;
      },
      feeds(uuid) {
        return `/v0.2/feeds?offline_partner_org_uuid=${uuid}`;
      },
    },
    populations: {
      list(uuid) {
        return `/v0.3/populations?offline_partner_org_uuid=${uuid}`;
      },
      create(uuid) {
        return `/v0.3/populations?offline_partner_org_uuid=${uuid}`;
      },
      delete(popId, uuid) {
        return `/v0.2/populations/${popId}?offline_partner_org_uuid=${uuid}`;
      },
      patch(popId, uuid) {
        return `/v0.3/populations/${popId}?offline_partner_org_uuid=${uuid}`;
      },
      filterSearch(uuid) {
        return `/v0.1/search/source-data?offline_partner_org_uuid=${uuid}`;
      },
      stats(uuid) {
        return `/alpha/population-stats?offline_partner_org_uuid=${uuid}`;
      },
    },
  },
  partnerTags: {
    all: '/v0.1/partner-tags',
    put(partnerUuid) {
      return `/v0.1/partners/${partnerUuid}/tags`;
    },
    putPartners(tagId) {
      return `/v0.1/partner-tags/${tagId}`;
    },
    delete(tagId) {
      return `/v0.1/partner-tags/${tagId}`;
    },
    patch(tagId) {
      return `/v0.1/partner-tags/${tagId}`;
    },
  },
  dismissPartnerSuggestions: {
    post: '/v0.1/dismissed-partner-suggestion',
  },
  partnerSuggestions: '/v0.1/partner-suggestions',
  prospectivePartnerTeam: {
    byInviteCode(publicInviteCode) {
      return `/v0.1/prospective-partner-team/${publicInviteCode}`;
    },
  },
  partnerSuggestionsV2: '/v0.2/partner-suggestions',
  dataShares: {
    incomingShares: '/v0.1/incoming-data-shares',
    outgoingShares: '/v0.1/outgoing-data-shares',
    outgoingRules: {
      default: '/v0.1/outgoing-sharing-rules',
      byId(dataShareId) {
        return `/v0.1/outgoing-sharing-rules/${dataShareId}`;
      },
    },
    incomingRules: '/v0.1/incoming-sharing-rules',
    delete(dataShareId) {
      return `/v0.1/outgoing-sharing-rules/${dataShareId}`;
    },
    incomingVisibility: {
      // not allowing viz to be passed in here because this is the only one that works for now
      greenfield:
        '/v0.1/incoming-population-visibility?visibility=greenfield_full',
    },
    incomingExclusions: {
      default: '/v0.1/incoming-population-visibility/exclusions',
      byTagId(tagId) {
        return `/v0.1/incoming-population-visibility/exclusions?tag-id=${tagId}`;
      },
    },
    outgoingExclusions: {
      default: '/v0.1/outgoing-population-visibility/exclusions',
      byTagId(tagId) {
        return `/v0.1/outgoing-population-visibility/exclusions?tag-id=${tagId}`;
      },
    },
  },
  shareRequests: {
    default: '/v0.2/share-requests',
    outbound: {
      default: '/v0.1/outbound-share-requests',
      byId: (id) => `/v0.1/outbound-share-requests/${id}`,
    },
    inbound: {
      default: '/v0.1/inbound-share-requests',
      byId: (id) => `/v0.1/inbound-share-requests/${id}`,
    },
    remind: (id) => `/v0.1/share-requests/${id}/remind`,
  },
  users: {
    me: '/v0.1/users/me',
    meV2: '/v0.2/users/me',
    suggestedOrgsByEmail: '/v0.1/users/me/suggested-orgs-by-email',
    featureFlags: '/v0.1/feature-flags',
    favorites: {
      get: '/v0.1/users/favorites',
      post: '/v0.1/users/favorites',
      delete: (uuid) => `/v0.1/users/favorites/${uuid}`,
    },
  },
  registration: {
    register: '/v0.1/register',
    domain(domain) {
      const encoded = encodeURIComponent(domain);
      return `/v0.1/registration-domains/${encoded}`;
    },
    cookieEmailDomain: '/v0.1/registration-domains',
  },
  auth0: {
    profiles: '/v0.1/auth0/profiles',
    linkAccounts: '/v0.1/auth0/link',
    signUp: '/v0.1/auth0/sign-up',
  },
  admin: {
    orgs: '/admin/v0.1/organizations',
    org(orgId) {
      return `/admin/v0.1/organizations/${orgId}`;
    },
    users(orgId) {
      return `/admin/v0.1/organizations/${orgId}/users`;
    },
    verify: '/admin/v0.1/verification-changes',
    tempLogin: '/admin/v0.1/temp-login',
  },
  team: {
    deauthorize(userId) {
      return `/v0.1/team/${userId}/deauthorize`;
    },
    list: '/v0.1/team',
    invite: '/v0.2/user-invites',
    change_roles: '/v0.1/team/roles',
  },
  roles: {
    all: '/v0.1/roles',
    create: '/v0.1/roles',
    update(id) {
      return `/v0.1/roles/${id}`;
    },
    delete(roleId) {
      return `/v0.1/roles/${roleId}`;
    },
  },
  all_permissions: '/v0.1/permissions',
  org: {
    patch: '/v0.1/organization',
    login_method: '/v0.1/organization/login-method',
    potentialRevenueSettings: '/v0.1/organization/settings/potential-influence',
  },
  invites: {
    details(code) {
      return `/v0.1/user-invites/${code}`;
    },
    redeem(code) {
      return `/v0.1/user-invites/${code}/redeem`;
    },
    revoke(id) {
      return `/v0.1/delete-user-invites/${id}`;
    },
  },
  slackApp: {
    channels: '/v0.1/slack-app',
    integration: '/v0.1/slack-app/slack-integration',
  },
  sso: {
    config: '/v0.1/sso/config',
    config_v2: '/v0.2/sso/config',
    auth_connection(id) {
      return `/v0.1/sso/config/${id}/auth-connection`;
    },
  },
  activityTimeline: {
    all(sourceId, recordId) {
      return `/v0.1/activity-timeline/${sourceId}/${recordId}`;
    },
  },
  clearbitAutoComplete: (query) => `/v0.1/clearbit-autocomplete?query=${query}`,
  seatRequests: {
    create: '/v0.1/seat-requests',
    list: '/v0.1/seat-requests',
    update: (uuid) => `/v0.1/seat-requests/${uuid}`,
  },
  inviteRequests: {
    notify(inviteRequestId) {
      return `v0.1/invite-requests/${inviteRequestId}/notify`;
    },
    byId(inviteRequestId) {
      return `v0.1/invite-requests/${inviteRequestId}`;
    },
  },
  partnerIdMappings: {
    get: '/v0.1/partner-id-mapping',
    put: '/v0.1/partner-id-mapping',
  },
  integrationProfiles: {
    all: '/v0.1/integration-profiles',
    get: (id) => `/v0.1/integration-profiles/${id}`,
    post: '/v0.1/integration-profiles',
    put: (id) => `/v0.1/integration-profiles/${id}`,
  },
  salesEdge: {
    post: {
      messageReadyV2: buildSalesEdgeUrl(
        '/extensions/requests/message-ready/v2',
      ),
    },
    get: {
      partners: (ownOrgUuid) =>
        buildSalesEdgeUrl(`/cb_orgs/${ownOrgUuid}/partners`),
    },
  },
};
