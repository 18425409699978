<template>
  <Notification :item="item">
    <template #logo>
      <BittsAvatar :org="partnerOrg" :show-initials="true" size="medium" />
    </template>
    <template #message> Partnership Accepted </template>
    <template #description>
      {{ userName }} at {{ organizationName }} accepted your partnership
      request.
    </template>
    <template #action>
      <BittsButton
        text="View Partner"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="viewPartner"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'team']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script setup lang="ts">
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import { useNotificationsStore, usePartnersStore } from '@/stores';
import { Notification as NotificationType } from '@/types/notifications';

const { item } = defineProps<{ item: NotificationType }>();

const emit = defineEmits<(e: 'action-clicked', action: string) => void>();

const userName = computed(() => item.data?.receiving_user_name);
const organizationName = computed(() => item.data?.receiving_org_name);

const partnersStore = usePartnersStore();
const partnerOrg = computed(() =>
  partnersStore.getPartnerOrgById(item.data?.receiving_org_id || 0),
);

const router = useRouter();
const notificationsStore = useNotificationsStore();
async function viewPartner() {
  emit('action-clicked', 'View Partner');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  await router.push({
    name: 'partner_details',
    params: { partner_org_id: partnerOrg.value?.id },
  });
}
</script>
