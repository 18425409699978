<template>
  <component
    :is="rootComponent"
    class="reports-table-cell__wrapper"
    v-bind="rootProps"
  >
    <div
      :class="{ 'opacity-50 cursor-not-allowed': cannotEditNotifications }"
      class="flex items-center pr-40 justify-center"
    >
      <RouterLink
        :to="notificationsModalRoute"
        :class="{ 'pointer-events-none': cannotEditNotifications }"
        class="leading-none"
      >
        <BittsTooltip
          v-if="reportNotifications.length || isOwnGreenfieldReport"
          :mount-to-body="true"
          overlay-class="report-notification-cell__tooltip"
          placement="bottom"
          :class="{ 'cursor-not-allowed ml-12': isOwnGreenfieldReport }"
        >
          <div
            :class="
              isOwnGreenfieldReport
                ? 'report-notification-cell__greenfield'
                : 'report-notification-cell__icon bg-accent-background-weak mb-4'
            "
          >
            <FontAwesomeIcon
              :icon="[
                'fak',
                isOwnGreenfieldReport
                  ? 'notifications-off'
                  : 'notifications-on',
              ]"
              :style="{ height: '12px', width: '12px', color: 'currentColor' }"
              :class="
                isOwnGreenfieldReport
                  ? 'text-neutral-accent'
                  : 'mr-4 text-accent-accent'
              "
            />
            <span v-if="!isOwnGreenfieldReport"> On </span>
          </div>
          <template #title> {{ tooltipText }} </template>
        </BittsTooltip>
        <div
          v-else
          class="report-notification-cell__icon text-neutral-500 bg-neutral-50"
        >
          <FontAwesomeIcon
            :icon="['fak', 'notifications-off']"
            :style="{ height: '12px', width: '12px', color: 'currentColor' }"
            class="text-neutral-accent mr-4"
          />
          <span>Off</span>
        </div>
      </RouterLink>
    </div>
    <template #title>
      {{ cannotEditText }}
    </template>
  </component>
</template>

<script setup lang="ts">
import { BittsTooltip } from '@crossbeam/bitts';

import { ICellRendererParams } from '@ag-grid-community/core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

import { OWN_GREENFIELD } from '@/constants/reports';
import { CHILD_ROUTES } from '@/constants/routes';
import { ReportNotification } from '@/types/reports';

const { params } = defineProps<{ params: ICellRendererParams }>();

const NOTIFS_TO_TOOLTIP_TEXT_MAP = {
  'slack-and-email': '🔔️ Email & Slack',
  slack: '🔔️ Slack',
  email: '🔔️ Email',
};

const reportNotifications = computed(
  () => params.value.report.notification_configs,
);

const hasEmailNotif = computed(
  () =>
    !!reportNotifications.value.find(
      (notif: ReportNotification) => !!notif.emails,
    ),
);
const hasSlackNotif = computed(
  () =>
    !!reportNotifications.value.find(
      (notif: ReportNotification) => !!notif.slack_channel,
    ),
);

const isOwnGreenfieldReport = computed(
  () => params.value.report.consolidated_report_type === OWN_GREENFIELD,
);
const isInErrorState = computed(
  () => params.value.report.status?.errors.length,
);
const hasWritePermissions = computed(() => params.value.hasWritePermissions);
const cannotEditNotifications = computed(() => {
  return (
    isInErrorState.value ||
    !hasWritePermissions.value ||
    isOwnGreenfieldReport.value
  );
});

const notificationsModalRoute = computed(() => {
  if (params.value.isOnReportsPage) {
    return {
      name: CHILD_ROUTES.reports.REPORTS_NOTIFICATIONS,
      params: { id: params.value.report.id },
      query: { id: params.value.report.id },
    };
  }
  return {
    name: CHILD_ROUTES.folder.REPORTS_NOTIFICATIONS,
    params: { folder_id: params.value.report.folder_uuid },
    query: { id: params.value.report.id },
  };
});

const rootComponent = computed(() => {
  if (params.value.trialOver) return 'div';
  return cannotEditNotifications.value ? BittsTooltip : 'RouterLink';
});
const rootProps = computed(() => {
  return cannotEditNotifications.value
    ? { placement: 'bottom', mountToBody: true }
    : { to: params.value.reportLink };
});

const tooltipText = computed(() => {
  if (hasEmailNotif.value && hasSlackNotif.value) {
    return NOTIFS_TO_TOOLTIP_TEXT_MAP['slack-and-email'];
  } else if (hasEmailNotif.value) {
    return NOTIFS_TO_TOOLTIP_TEXT_MAP.email;
  } else if (hasSlackNotif.value) {
    return NOTIFS_TO_TOOLTIP_TEXT_MAP.slack;
  }
  return 'Notifications are not available for this report type';
});

const cannotEditText = computed(() => {
  if (!hasWritePermissions.value) {
    return 'You have "view only" access based on your user role. Contact your admin to change reports notification';
  }
  if (isInErrorState.value) {
    return 'Notifications are paused until report errors have been addressed';
  }
  return 'Notifications are not available for this report type';
});
</script>

<style lang="pcss">
.report-notification-cell__greenfield {
  @apply flex justify-center p-4 text-neutral-500 rounded-bts-sm bg-neutral-50;
}

.report-notification-cell__icon {
  @apply text-sm inline-flex items-center rounded-bts-sm px-8 py-4;
  max-height: 20px !important;
}
</style>
