<template>
  <div class="c-population-rule">
    <div class="c-population-rule_item">
      <div class="font-bold text-m">{{ title }}</div>
      <BittsTag v-if="isRequired" color="info" size="small" variant="rounded">
        Required
      </BittsTag>
    </div>
    <BittsButton
      v-if="isEmptyPopulation"
      :left-icon="['far', 'plus']"
      text="Create"
      size="small"
      @click="handleCreatePopulationRow"
    />
    <div v-else class="c-population-rule_item">
      <BittsTag color="secondary" size="small" variant="rounded">
        {{ `${recordCount} ${pluralize(recordCount || 0, 'record')}` }}
      </BittsTag>
      <BittsButton
        type="neutral"
        variant="outline"
        size="small"
        :center-icon="['fak', 'edit']"
        @click="handleEditPopulationRow"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsTag } from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { getPopulationTypeName } from '@/constants/standard_populations';
import { Population } from '@/types/populations';

const { population, recordCount = null } = defineProps<{
  population: Population;
  recordCount?: number;
}>();
const router = useRouter();
const route = useRoute();

// TODO update when adding Custom pops
const isRequired = true;
const isEmptyPopulation = computed(() => !population.id);

const title = computed(() =>
  population.standard_type
    ? getPopulationTypeName(population.standard_type)
    : '',
);

async function handleCreatePopulationRow() {
  await router.push({
    name: 'create-population',
    params: { ...route.params, type: population.standard_type },
  });
}

async function handleEditPopulationRow() {
  await router.push({
    name: 'edit-population',
    params: { ...route.params, population_id: population.id },
  });
}
</script>

<style scoped lang="pcss">
.c-population-rule {
  @apply flex items-center justify-between p-16;
}
.c-population-rule_item {
  @apply flex gap-10 items-center;
}
</style>
