<template>
  <BittsCard
    class="c-billing-plan-card-updated"
    :class="{
      'w-1/2': !isBillingPage,
      'h-[1000px]': isBillingPage,
      'c-billing-plan-card-updated__connector': isConnectorTier,
      'c-billing-plan-card-updated__enterprise': isEnterpriseTier,
    }"
  >
    <div
      class="c-billing-plan-card-updated__highlight-tag"
      :class="{
        'c-billing-plan-card-updated__highlight-tag__billing': isBillingPage,
      }"
    >
      <BittsTag v-if="isEnterpriseTier" color="upsell-dark" variant="rounded">
        <template #pre>
          <FontAwesomeIcon class="mr-4 h-12 w-12" :icon="['fak', 'star']" />
        </template>
        <span>Recommended</span>
      </BittsTag>
    </div>
    <div class="flex flex-col p-24 gap-24">
      <div>
        <p
          class="pb-8 font-bold text-lg c-billing-plan-card-updated__tier-title"
        >
          {{ capitalize(tier) }}
        </p>
        <p class="text-neutral-text-weak text-base h-[63px]">
          {{ BILLING_IMPROVEMENTS_COPY[tier].description }}
        </p>
      </div>

      <div>
        <p class="text-neutral-text-strong font-bold text-xxl">
          {{ corePrice }}
          <span
            v-if="isConnectorTier"
            class="text-neutral-text-weak text-base font-normal"
          >
            per seat/month
          </span>
        </p>
        <p v-if="isEnterpriseTier" class="h-[21px]" />
        <p v-else-if="isConnectorTier" class="text-success-text">
          or {{ salesPrice }} per seat/month for Sales seats
        </p>
        <p v-else-if="isFreeTier" class="text-neutral-text-weak">
          for 3 Full Access seats
        </p>
      </div>

      <BittsButton
        v-if="!isBillingPage"
        :text="buttonText"
        :type="buttonType"
        size="medium"
        :left-icon="isEnterpriseTier ? ['fak', 'upgrade'] : undefined"
        class="self-start"
        @click="handleButtonClick"
      />
      <div v-else>
        <slot name="action" />
      </div>

      <BillingPlanComparisonFeatureList
        class="min-h-[350px]"
        :tier
        :comparison-modal="true"
        :highlight
      />
      <div v-if="isBillingPage">
        <p class="text-neutral-text-strong font-bold mb-12 text-m">
          Available Integrations
        </p>
        <div class="c-billing-plan-comparison-card__integrations">
          <BittsTag
            v-for="item in filteredIntegrations"
            :key="item.text"
            color="partner"
            size="small"
            variant="rounded"
            class="c-billing-plan-comparison-card__integration-tag"
          >
            <template #pre>
              <BittsSvg
                v-if="item.svg"
                class="w-12 h-12 mr-8"
                :svg="item.svg"
              />
            </template>
            <span>
              {{ item.text }}
            </span>
          </BittsTag>
        </div>
      </div>
    </div>
  </BittsCard>
</template>

<script setup lang="ts">
import { BittsButton, BittsCard, BittsSvg, BittsTag } from '@crossbeam/bitts';

import { capitalize } from 'lodash';
import { computed } from 'vue';

import BillingPlanComparisonFeatureList from '@/components/billing/BillingPlanComparisonFeatureList.vue';

import useAuth from '@/composables/useAuth';
import {
  BILLING_IMPROVEMENTS_COPY,
  FULL_ACCESS,
  SALES_ONLY,
  SEAT_COSTS,
  V4_BILLING_COPY,
} from '@/constants/billing';
import {
  HS3_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { BillingPeriod } from '@/types/billing';
import { centsToDollars } from '@/utils';

interface Props {
  tier: keyof typeof V4_BILLING_COPY.explorePlanCards;
  period?: BillingPeriod;
  highlight?: string;
  isBillingPage?: boolean;
}

const {
  tier,
  highlight = '',
  period = BillingPeriod.Month,
  isBillingPage = false,
} = defineProps<Props>();

const emit = defineEmits<(e: 'action') => void>();

const { currentOrg } = useAuth();

const isConnectorTier = computed(() => tier === 'connector');
const isEnterpriseTier = computed(() => tier === 'supernode');
const isFreeTier = computed(() => tier === 'explorer');

const buttonText = computed(() => {
  if (isEnterpriseTier.value) return 'Talk to Sales';
  return 'Upgrade to Connector';
});

const buttonType = computed(() => {
  if (isEnterpriseTier.value) return 'upsell';
  return 'primary';
});

const corePrice = computed(() => {
  if (isEnterpriseTier.value) return 'Custom';
  if (isFreeTier.value) return 'Free';
  return (
    period &&
    centsToDollars(
      SEAT_COSTS[FULL_ACCESS][period] / (period === 'year' ? 12 : 1),
    )
  );
});

const salesPrice = computed(() => {
  if (isEnterpriseTier.value) return null;
  return (
    period &&
    centsToDollars(
      SEAT_COSTS[SALES_ONLY][period] / (period === 'year' ? 12 : 1),
    )
  );
});

const preferredCrm = computed(() => currentOrg.value.preferred_crm);
const filteredIntegrations = computed(() => {
  return V4_BILLING_COPY.explorePlanCards[tier].features.integrations.filter(
    (integration) => {
      if (integration.svg?.startsWith(SALESFORCE_DATA_SOURCE_TYPE))
        return preferredCrm.value === SALESFORCE_DATA_SOURCE_TYPE;
      if (integration.svg?.startsWith(HUBSPOT_DATA_SOURCE_TYPE))
        return (
          preferredCrm.value === HUBSPOT_DATA_SOURCE_TYPE ||
          preferredCrm.value === HS3_DATA_SOURCE_TYPE
        );
      return true;
    },
  );
});

function handleButtonClick() {
  emit('action');
}
</script>

<style scoped lang="pcss">
.c-billing-plan-card-updated {
  @apply relative;
  &.c-billing-plan-card-updated__connector {
    @apply flex border border-solid border-teal-400 bg-info-background-weak/25;
    .c-billing-plan-card-updated__tier-title {
      background: linear-gradient(
        90deg,
        theme(colors.teal.400) 0.11%,
        theme(colors.teal.400/ 0.75) 27.41%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.c-billing-plan-card-updated__enterprise {
    @apply border border-solid border-denim-400;
    .c-billing-plan-card-updated__highlight-tag {
      @apply w-full left-24 absolute top-[-12px] justify-start;
    }
    .c-billing-plan-card-updated__highlight-tag__billing {
      @apply w-full left-0 absolute top-[-12px] flex justify-center;
    }
    .c-billing-plan-card-updated__tier-title {
      background: linear-gradient(
        90deg,
        theme(colors.denim.400) 0.13%,
        theme(colors.pink.600/ 0.75) 24.57%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .c-billing-plan-comparison-card__integrations {
    @apply flex flex-wrap gap-12;
    div {
      @apply w-auto shrink;
    }
  }
}
</style>
<style lang="pcss">
.c-billing-plan-comparison-card__integration-tag.bitts-tag {
  .ant-tag {
    @apply cursor-pointer flex items-center;
  }
}
</style>
