<template>
  <div class="report-builder">
    <div
      v-if="isGreenfieldType"
      data-testid="greenfield-selector"
      class="mb-40"
    >
      <div class="report-builder__title mb-16">
        <div class="report-builder__number"> 1 </div>
        Select which Greenfield accounts to view in the report
      </div>
      <BittsSegment
        :initial-active-index="
          selectedGreenfieldType === PARTNER_GREENFIELD ? 0 : 1
        "
        :tabs="GREENFIELD_TYPE_TABS"
        variant="segmented_control"
        class="max-w-full"
        @change:segment="toggleGreenfieldType"
      />
    </div>
    <div
      v-if="!showGreenfieldEmptyState"
      class="flex flex-col"
      :class="{ 'flex-col-reverse': isGreenfieldType && isPartnerGreenfield }"
    >
      <div class="report-builder__select-partner">
        <div class="report-builder__title mb-16">
          <div class="report-builder__number">
            {{ firstSelectionNumber }}
          </div>
          Select {{ firstSelectionType }}
        </div>
        <BittsSelect
          v-if="isUsingAMMGrid"
          :model-value="props.selectedPartnerOrg?.uuid"
          :allow-clear="true"
          :mount-to-body="false"
          option-type="company"
          :options="orderedPartnerOrgs"
          placeholder="Select Partner"
          :use-disabled-options="true"
          :disabled-option-content="{
            text: 'This partner is not sharing data with you. Request data from this partner to compare data in a report.',
          }"
          @update:model-value="(uuid) => emit('selected-partner-updated', uuid)"
        >
          <template #suffix="{ option }">
            <PartnerSponsorTag
              v-if="option.is_partner_sponsor && hasPartnerSponsorshipFlag"
              class="ml-4"
            />
          </template>
        </BittsSelect>
        <ReportPopulationTree v-else :populations="populationsInOrder()" />
      </div>
      <div class="report-builder__amm-grid">
        <div class="report-builder-amm-grid-header">
          <div class="report-builder__title">
            <div class="report-builder__number">
              {{ secondSelectionNumber }}
            </div>
            Select {{ secondSelectionType }}
          </div>
          <BittsCheckbox
            v-if="isUsingAMMGrid"
            label="Hide Empty Populations"
            :checked="hideEmptyPopulations"
            @input="onHidePopsCheck"
          />
          <BittsInput
            v-else-if="isCustom"
            v-model="partnerSearch"
            type="search"
            class="w-[250px]"
            size="small"
            placeholder="Search partners"
            name="partner-search"
          />
        </div>
        <div
          v-if="
            [ALL_PARTNERS_TYPE, PARTNER_TAGS_TYPE, GREENFIELD_TYPE].includes(
              props.type.type,
            )
          "
          class="mb-16"
        >
          <BittsSelect
            :model-value="dropdownInfo.modelValue"
            :allow-clear="true"
            :disabled="dropdownInfo.disabled"
            :disabled-option-content="dropdownInfo.disabledOptionContent"
            :mount-to-body="false"
            :options="dropdownInfo.options"
            :option-type="dropdownInfo.optionType"
            :placeholder="dropdownInfo.placeholder"
            :prefix-icon="dropdownInfo.prefixIcon"
            :use-disabled-options="dropdownInfo.useDisabledOptions"
            @update:model-value="dropdownInfo.update"
          />
          <div class="text-neutral-text-weak text-sm mt-8">
            {{ infoMessage }}
          </div>
          <BittsCallout
            v-if="
              (notAllPartnersSharing && !isGreenfieldType) ||
              isNotSharingOwnGreenfield
            "
            class="mt-8"
            size="x-small"
            type="warning"
            :title="calloutProps.title"
            :subtitle="calloutProps.subtitle"
            :action-text="calloutProps.actionText"
            @bitts-callout-action="calloutProps.action"
          />
        </div>
        <AccountMappingMatrix
          v-if="isUsingAMMGrid"
          :alert-state="potentialRevenueAlertState"
          :amm-view="props.type.viewName"
          :can-see-opps-data="
            canSeeOppsData(props.selectedPartnerOrg?.id, false) ||
            isPartnerOverlapsOnly
          "
          :hide-empty-populations="hideEmptyPopulations"
          :overlaps="props.overlaps"
          :partner="props.selectedPartnerOrg"
          :show-title-bar="false"
          :use-all-alerts-for-empty-state="true"
          class="mb-24"
          @empty-state-cta-clicked="pushToSharing"
          @open-request-data-modal="onRequestData"
        />
        <div v-else class="flex flex-col gap-8 mb-24">
          <BittsEmptyState
            v-if="emptyState"
            :button="emptyStateMapped[emptyState].button"
            :description="emptyStateMapped[emptyState].description"
            :link="emptyStateMapped[emptyState].link"
            :title="emptyStateMapped[emptyState].title(fullTagInfo?.label)"
            :png-name="emptyStateMapped[emptyState].pngName"
            @button-clicked="emptyStateButtonClicked"
          />
          <ReportPopulationTree
            v-for="(tree, idx) in treesForPopulationSelector"
            v-else
            :key="`partner-pop-tree__${idx}`"
            data-testid="partner-tree"
            :disabled-tree="isPopTreeDisabled"
            :expanded="!isCustom"
            :is-org="isTreeOrg"
            :parent="tree"
            :populations="treeIdToPopulationsMap[tree.id]"
            :report-type="props.type.type"
            :outgoing-pop-exclusions="outgoingPopExclusions[ALL_PARTNERS_TYPE]"
          />
        </div>
      </div>
    </div>
    <BittsEmptyState
      v-else
      :button="{ text: 'View Partners' }"
      description="Ask your partners to start sharing Greenfield with you in order to run this report"
      title="Your partners are not sharing Greenfield with you"
      png-name="partner-not-sharing.png"
      class="border border-solid border-neutral-border"
      @button-clicked="router.push({ name: 'partners' })"
    />
    <DataShareRequestModal
      :data-share-request-modal-visible
      :org-ids
      :partner-pops
      @modal-closed="dataShareRequestModalVisible = false"
    />
  </div>
</template>

<script setup>
import {
  BittsCallout,
  BittsCheckbox,
  BittsEmptyState,
  BittsInput,
  BittsSegment,
  BittsSelect,
} from '@crossbeam/bitts';

import { sortBy, uniq } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, provide, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import PartnerSponsorTag from '@/components/billing/PartnerSponsorTag.vue';
import DataShareRequestModal from '@/components/partners/DataShareRequestModal.vue';
import AccountMappingMatrix from '@/components/partners/TheMatrix/AccountMappingMatrix.vue';
import ReportPopulationTree from '@/components/reports/ReportPopulationTree.vue';

import { crossbeamApi } from '@/api';
import usePartnerOverlapData from '@/composables/usePartnerOverlapData';
import {
  ERROR_TO_ALERT_STATE_LOOKUP,
  INELIGIBLE_HIDDEN_POPULATION,
  LIVE_PARTNER_DETAIL_TABS,
  NOT_CONFIGURED,
  NO_ALERT_STATE_CODES_REPORT_BUILDER,
  OWN_NOT_SHARING,
  PARTNER_NOT_SHARING,
  PARTNER_POPULATION_NOT_SHARED,
} from '@/constants/amm_grid';
import { GREENFIELD_SHARING } from '@/constants/data_shares';
import { SNOWFLAKE_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import { TEMP_EXEC_PARTNER_SPONSOR } from '@/constants/feature_flags';
import {
  ALL_PARTNERS_TYPE,
  CUSTOM_TYPE,
  GREENFIELD_CONSOLIDATED_TYPES,
  GREENFIELD_TYPE,
  MULTI_POPULATION_REPORT_TYPES,
  OWN_GREENFIELD,
  PARTNER_GREENFIELD,
  PARTNER_TAGS_TYPE,
  POTENTIAL_REVENUE_TYPE,
} from '@/constants/reports';
import { captureException } from '@/errors';
import {
  useDataSharesStore,
  useFeatureFlagStore,
  usePartnersStore,
  usePopulationsStore,
  usePotentialRevenueStore,
} from '@/stores';
import {
  CUSTOMERS_STANDARD_TYPE,
  PROSPECTS_STANDARD_TYPE,
} from '@/types/populations';

const props = defineProps({
  overlaps: {
    type: Array,
    default: () => [],
  },
  partnerInfo: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  selectedGreenfieldType: {
    type: String,
    default: OWN_GREENFIELD,
    validator(value) {
      return [...GREENFIELD_CONSOLIDATED_TYPES].includes(value);
    },
  },
  selectedPartnerOrg: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  initialPartnerTag: {
    type: String,
    default: () => '',
  },
  type: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits([
  'hide-pops-updated',
  'greenfield-type-updated',
  'selected-partner-updated',
  'selected-partner-tag-updated',
  'reset-report-values',
]);
const NO_TAGS = 'noTags';
const NO_PARTNERS_IN_TAG = 'noPartnersInTag';
const PARTNERS_NOT_SHARING = 'partnersNotSharing';

const STANDARD_POPULATIONS_FOR_TREE = [
  {
    name: 'Customers',
    standard_type: 'customers',
    id: 'customers',
  },
  {
    name: 'Open Opportunities',
    standard_type: 'open_opportunities',
    id: 'open_opportunities',
  },
  {
    name: 'Prospects',
    standard_type: 'prospects',
    id: 'prospects',
  },
];

const router = useRouter();
const partnersStore = usePartnersStore();
const populationsStore = usePopulationsStore();
const dataSharesStore = useDataSharesStore();
const ffStore = useFeatureFlagStore();
const potRevStore = usePotentialRevenueStore();

const { partnerOrgs } = storeToRefs(partnersStore);
const { populations } = storeToRefs(populationsStore);
const { greenfieldSharesLookup } = storeToRefs(dataSharesStore);

const hasPartnerSponsorshipFlag = computed(() =>
  ffStore.hasFeatureFlag(TEMP_EXEC_PARTNER_SPONSOR),
);

const orderedPartnerOrgs = computed(() => {
  const partnerOrgsWithDisabledValue = partnerOrgs.value.map((partner) => ({
    ...partner,
    disabled: !populationsStore.getPartnerPopulationsByOrg(partner.id).length,
    label: partner.name,
    value: partner.uuid,
  }));
  return sortBy(partnerOrgsWithDisabledValue, ['name']);
});
const orderedPartnerOrgsSharingGreenfield = computed(() => {
  const partners = partnerOrgs.value.reduce(
    (acc, org) => {
      const disabled = !greenfieldSharesLookup.value?.[org.id];
      const partner = { ...org, disabled, label: org.name, value: org.uuid };
      disabled
        ? acc.partnersNotSharing.push(partner)
        : acc.partnersSharing.push(partner);
      return acc;
    },
    { partnersSharing: [], partnersNotSharing: [] },
  );
  return [
    ...sortBy(partners.partnersSharing, ['name']),
    ...sortBy(partners.partnersNotSharing, ['name']),
  ];
});

const showGreenfieldEmptyState = computed(() => {
  if (!isGreenfieldType.value || !isPartnerGreenfield.value) return false;
  if (!greenfieldSharesLookup.value) return true;
  const orgsSharingGreenfield = Object.keys(greenfieldSharesLookup.value);
  return (
    !orgsSharingGreenfield.length ||
    orgsSharingGreenfield.every(
      (id) => !greenfieldSharesLookup.value[id].length,
    )
  );
});

const partnerSearch = ref('');
const partnersForCustomTrees = computed(() => {
  return orderedPartnerOrgs.value.filter((partner) => {
    if (!partnerSearch.value) return true;
    const { name } = partner;
    const nameNormalized = name.toLowerCase();
    const searchNormalized = partnerSearch.value.toLowerCase();
    return nameNormalized.includes(searchNormalized);
  });
});

const tagOptions = computed(() => {
  const tagsFormatted = partnersStore.partnerTags.map((tag) => ({
    name: tag.label,
    value: tag.id,
    icon: ['fak', 'tag'],
    ...tag,
  }));
  return sortBy(tagsFormatted, ['name']);
});

const selectedPartnerTag = ref(undefined);
const fullTagInfo = computed(() => {
  return tagOptions.value.find((tag) => tag.id === selectedPartnerTag.value);
});
const isPopTreeDisabled = computed(() => {
  return (
    (isPartnerTagsType.value && !selectedPartnerTag.value) ||
    (isGreenfieldType.value && !props.selectedPartnerOrg)
  );
});

const dropdownOptions = computed(() => {
  return {
    [ALL_PARTNERS_TYPE]: {
      disabled: true,
      options: [],
      placeholder: 'All Partners',
      update() {
        // do nothing
      },
    },
    [PARTNER_TAGS_TYPE]: {
      disabled: !tagOptions.value.length,
      modelValue: selectedPartnerTag.value,
      options: tagOptions.value,
      placeholder: !tagOptions.value.length
        ? 'No Partner Tags'
        : 'Select Partner Tag',
      prefixIcon: ['fak', 'tag'],
      update(tag, tagOptions) {
        onTagSelected(tag, tagOptions);
      },
    },
    [GREENFIELD_TYPE]: {
      disabledOptionContent: {
        text:
          props.selectedGreenfieldType === OWN_GREENFIELD
            ? 'This partner is not sharing data with you. Request data from this partner to compare data in a report.'
            : 'This partner is not sharing Greenfield accounts with you. Request data from this partner to run a Greenfield report.',
      },
      modelValue: props.selectedPartnerOrg?.uuid,
      options:
        props.selectedGreenfieldType === OWN_GREENFIELD
          ? orderedPartnerOrgs.value
          : orderedPartnerOrgsSharingGreenfield.value,
      optionType: 'company',
      placeholder: 'Select Partner',
      update(uuid) {
        emit('selected-partner-updated', uuid);
      },
      useDisabledOptions: true,
    },
  };
});
const dropdownInfo = computed(() => dropdownOptions.value[props.type.type]);

async function onTagSelected(tagVal) {
  if (!tagVal) {
    selectedPartnerTag.value = undefined;
    return;
  }
  selectedPartnerTag.value = tagVal;
  if (
    !noPartnersInTag.value &&
    (!incomingPopExclusions.value[fullTagInfo.value.id] ||
      !outgoingPopExclusions.value[fullTagInfo.value.id])
  ) {
    await getAllIncomingPopExclusions(fullTagInfo.value.id);
    await getAllOutgoingPopExclusions(fullTagInfo.value.id);
  }
  emit('selected-partner-tag-updated', fullTagInfo.value.id);
}

function setInitialPartnerTag() {
  const tag = partnersStore.getPartnerTagById(props.initialPartnerTag);
  selectedPartnerTag.value = tag.id;
}
async function onClickedViewPartners() {
  await router.push({ name: 'sharing_dashboard' });
}

async function onClickedUpdateSharing() {
  await router.push({
    name: 'partner_details',
    params: { partner_org_id: props.selectedPartnerOrg.id },
    query: { tab: 'sharing' },
  });
}

const incomingPopExclusions = ref({});
const outgoingPopExclusions = ref({});

async function getAllIncomingPopExclusions(tagId = null) {
  try {
    if (isPartnerTagsType.value && incomingPopExclusions?.value[tagId]) return;
    const { data, error } = await crossbeamApi.GET(
      '/v0.1/incoming-population-visibility/exclusions',
      {
        params: {
          query: { tag_id: tagId },
        },
      },
    );
    if (error) throw error;
    tagId
      ? (incomingPopExclusions.value[tagId] = data)
      : (incomingPopExclusions.value[ALL_PARTNERS_TYPE] = data);
  } catch (err) {
    captureException(err);
  }
}

async function getAllOutgoingPopExclusions(tagId = null) {
  try {
    if (isPartnerTagsType.value && outgoingPopExclusions?.value[tagId]) return;

    const { data, error } = await crossbeamApi.GET(
      '/v0.1/outgoing-population-visibility/exclusions',
      {
        params: {
          query: { tag_id: tagId },
        },
      },
    );
    if (error) throw error;
    tagId
      ? (outgoingPopExclusions.value[tagId] = data)
      : (outgoingPopExclusions.value[ALL_PARTNERS_TYPE] = data);
  } catch (err) {
    captureException(err);
  }
}

const isTreeOrg = computed(() => {
  if (props.type.type === CUSTOM_TYPE) return true;
  if (!isGreenfieldType.value) return false;
  return !!props.selectedPartnerOrg;
});
const treesForPopulationSelector = computed(() => {
  switch (props.type.type) {
    case CUSTOM_TYPE:
      return partnersForCustomTrees.value;
    case PARTNER_TAGS_TYPE: {
      return [
        {
          id: PARTNER_TAGS_TYPE,
          name: fullTagInfo.value?.label || 'Partner Tag',
        },
      ];
    }
    case GREENFIELD_TYPE: {
      const placeholder = [{ id: GREENFIELD_TYPE, name: 'Partner Name' }];
      if (!props.selectedPartnerOrg) return placeholder;
      const currentSelectedOrg = orderedPartnerOrgs.value.find(
        (org) => org.id === props.selectedPartnerOrg.id,
      );
      return currentSelectedOrg ? [currentSelectedOrg] : placeholder;
    }
    default: /* all partners */
      return [{ id: ALL_PARTNERS_TYPE, name: 'All Partners' }];
  }
});

const { allSettingsUnchecked, potentialRevenueSettingsSource } =
  storeToRefs(potRevStore);

const { canSeeOppsData } = usePartnerOverlapData();

const isUsingAMMGrid = computed(
  () => !MULTI_POPULATION_REPORT_TYPES.includes(props.type.type),
);
const isCustom = computed(() => props.type.type === CUSTOM_TYPE);
const firstSelectionNumber = computed(() => {
  if (!isGreenfieldType.value) return 1;
  return isPartnerGreenfield.value ? 3 : 2;
});
const firstSelectionType = computed(() =>
  isUsingAMMGrid.value ? 'Partner' : 'Your Populations',
);
const secondSelectionNumber = computed(() => {
  if (!isGreenfieldType.value || isPartnerGreenfield.value) return 2;
  return 3;
});
const secondSelectionType = computed(() => {
  switch (props.type.type) {
    case CUSTOM_TYPE:
      return 'Partners & Populations';
    case PARTNER_TAGS_TYPE:
    case ALL_PARTNERS_TYPE:
      return 'Partner Populations';
    case GREENFIELD_TYPE:
      return 'Partner';
    default:
      return 'Comparison';
  }
});

const GREENFIELD_TYPE_TABS = [
  {
    description:
      'View accounts that exist in your partner’s populations that don’t exist in your populations',
    icon: ['fak', 'venn-diagram-right-1'],
    id: PARTNER_GREENFIELD,
    title: 'New Accounts for You',
  },
  {
    description:
      'View accounts that exist in your populations that don’t exist in your partner’s populations',
    icon: ['fak', 'venn-diagram-left-1'],
    id: OWN_GREENFIELD,
    title: 'New Accounts for Your Partner',
  },
];
function toggleGreenfieldType(type) {
  emit('greenfield-type-updated', type);
  emit('selected-partner-updated', undefined);
}
const isGreenfieldType = computed(() => props.type.type === GREENFIELD_TYPE);
const isPartnerTagsType = computed(() => props.type.type === PARTNER_TAGS_TYPE);

const isPartnerGreenfield = computed(
  () => props.selectedGreenfieldType === PARTNER_GREENFIELD,
);

const infoMessage = computed(() => {
  switch (props.type.type) {
    case PARTNER_TAGS_TYPE:
      return 'As you add and remove partners to this tag, this report will automatically be updated to include the right partners';
    case ALL_PARTNERS_TYPE:
      return 'As you add and remove partners, this report will automatically be updated to include the right partners';
    default:
      return '';
  }
});

const isPartnerOverlapsOnly = computed(
  () => props.partnerInfo?.open_status_code === PARTNER_POPULATION_NOT_SHARED,
);
const hasPartnerPopulations = ref(true);
const hasPopulations = ref(true);
const notSharingAlertState = computed(() => {
  if (!hasPopulations.value) return OWN_NOT_SHARING;
  else if (!hasPartnerPopulations.value) return PARTNER_NOT_SHARING;
  return null;
});
const potentialRevenueAlertState = computed(() => {
  if (notSharingAlertState.value) return notSharingAlertState.value;
  if (props.type.type !== POTENTIAL_REVENUE_TYPE) return null;
  if (allSettingsUnchecked.value) return NOT_CONFIGURED;
  if (!props.partnerInfo) return PARTNER_NOT_SHARING;
  const statusCode = props.partnerInfo.open_status_code;
  if (!NO_ALERT_STATE_CODES_REPORT_BUILDER.includes(statusCode))
    return ERROR_TO_ALERT_STATE_LOOKUP[statusCode];
  return null;
});

const treeIdToPopulationsMap = computed(() => {
  const map = {};
  orderedPartnerOrgs.value.forEach(({ id }) => {
    const popsInOrder = populationsInOrder(id);
    if (isGreenfieldType.value && isPartnerGreenfield.value) {
      map[id] = filterByGreenfield(popsInOrder, id);
    } else map[id] = popsInOrder;
  });
  map[ALL_PARTNERS_TYPE] = STANDARD_POPULATIONS_FOR_TREE;
  map[PARTNER_TAGS_TYPE] = STANDARD_POPULATIONS_FOR_TREE;
  map[GREENFIELD_TYPE] = STANDARD_POPULATIONS_FOR_TREE;
  return map;
});
function populationsInOrder(id = null) {
  let pops;
  if (id) pops = populationsStore.getPartnerPopulationsByOrg(id);
  else pops = populations.value;
  let hasCustomersPop = false;
  return pops
    .reduce(
      (populationsByType, pop) => {
        const { standard_type: standardType } = pop;
        if (!standardType) {
          populationsByType[1].push(pop);
          return populationsByType;
        }
        switch (standardType) {
          case CUSTOMERS_STANDARD_TYPE:
            populationsByType[0].unshift(pop);
            hasCustomersPop = true;
            break;
          case PROSPECTS_STANDARD_TYPE:
            populationsByType[0].push(pop);
            break;
          default: // open opps
            if (populationsByType[0].length === 2)
              populationsByType[0].splice(1, 0, pop);
            else if (hasCustomersPop) populationsByType[0].push(pop);
            else populationsByType[0].unshift(pop);
        }
        return populationsByType;
      },
      [[], []],
    )
    .flat();
}
function filterByGreenfield(pops, id) {
  return pops.filter((pop) =>
    greenfieldSharesLookup.value?.[id]?.includes(pop.id),
  );
}

const isPartnerTagTypeAndNoTagSet = computed(
  () => isPartnerTagsType.value && !fullTagInfo.value,
);

const standardPops = computed(() => {
  if (
    !Object.keys(incomingPopExclusions.value).length ||
    (isPartnerTagsType.value &&
      !incomingPopExclusions.value[fullTagInfo.value.id])
  )
    return [];
  if (isPartnerTagsType.value) {
    const tagInfo = incomingPopExclusions.value[fullTagInfo.value.id];
    return tagInfo
      ? uniq([
          ...tagInfo.no_visible_open_opportunities,
          ...tagInfo.no_visible_customers,
          ...tagInfo.no_visible_prospects,
        ])
      : [];
  }
  if (!incomingPopExclusions.value[ALL_PARTNERS_TYPE]) return [];
  return uniq([
    ...incomingPopExclusions.value[ALL_PARTNERS_TYPE]
      .no_visible_open_opportunities,
    ...incomingPopExclusions.value[ALL_PARTNERS_TYPE].no_visible_customers,
    ...incomingPopExclusions.value[ALL_PARTNERS_TYPE].no_visible_prospects,
  ]);
});

const partnersWithTag = computed(() => {
  if (!fullTagInfo.value) return;
  return partnersStore.partnerOrgs.filter((org) =>
    org.tags.some((tag) => tag.id === fullTagInfo.value.id),
  );
});
const noPartnersInTag = computed(() => {
  if (!fullTagInfo.value) return;
  return !partnersWithTag.value.length;
});

const ownNotSharing = computed(() => {
  if (isPartnerTagTypeAndNoTagSet.value) return false;
  if (isPartnerTagsType.value) {
    return partnersWithTag.value.every((partner) =>
      outgoingPopExclusions.value[
        fullTagInfo.value.id
      ]?.no_revealed_populations.includes(partner.id),
    );
  }
  return partnersStore.partnerOrgs.every((partner) =>
    outgoingPopExclusions.value[
      ALL_PARTNERS_TYPE
    ]?.no_revealed_populations.includes(partner.id),
  );
});

const partnersNotSharing = computed(() => {
  if (isPartnerTagTypeAndNoTagSet.value) return false;
  if (isPartnerTagsType.value) {
    return partnersWithTag.value.every((partner) =>
      incomingPopExclusions.value[
        fullTagInfo.value.id
      ]?.no_visible_populations.includes(partner.id),
    );
  }
  return partnersStore.partnerOrgs.every((partner) =>
    incomingPopExclusions.value[
      ALL_PARTNERS_TYPE
    ]?.no_visible_populations.includes(partner.id),
  );
});

const notAllPartnersSharing = computed(() => {
  if (isPartnerTagTypeAndNoTagSet.value) return false;
  if (isPartnerTagsType.value) {
    return (
      !partnersNotSharing.value &&
      !ownNotSharing.value &&
      partnersWithTag.value.some((partner) =>
        standardPops.value.includes(partner.id),
      )
    );
  }
  return (
    !partnersNotSharing.value &&
    !ownNotSharing.value &&
    partnersStore.partnerOrgs.some((partner) =>
      standardPops.value.includes(partner.id),
    )
  );
});

const isNotSharingOwnGreenfield = computed(() => {
  if (
    isGreenfieldType.value &&
    isPartnerGreenfield.value &&
    !!props.selectedPartnerOrg
  ) {
    const isUsingPartnerRule =
      dataSharesStore.outgoingSharingRuleMap?.partner &&
      !!dataSharesStore.outgoingSharingRuleMap?.partner[
        props.selectedPartnerOrg?.id
      ];
    const partnerRulePops = [];
    if (isUsingPartnerRule) {
      for (
        let i = 0;
        i <
        dataSharesStore.outgoingSharingRuleMap?.partner[
          props.selectedPartnerOrg?.id
        ].length;
        i++
      ) {
        const pop =
          dataSharesStore.outgoingSharingRuleMap?.partner[
            props.selectedPartnerOrg?.id
          ][i];
        if (pop.visibility === GREENFIELD_SHARING) {
          return false;
        }
        partnerRulePops.push(pop.population_id);
      }
    }

    const popRulePops = Object.keys(
      dataSharesStore.outgoingSharingRuleMap?.population,
    ).filter((pop) => !partnerRulePops.includes(Number(pop)));
    for (const defaultPop of popRulePops) {
      const populations =
        dataSharesStore.outgoingSharingRuleMap?.population[defaultPop];

      for (const pop of populations || []) {
        if (pop.visibility === GREENFIELD_SHARING) {
          return false;
        }
      }
    }

    return true;
  }
  return false;
});

const calloutProps = computed(() => {
  if (isNotSharingOwnGreenfield.value) {
    return {
      title: 'Heads up, you are not sharing Greenfield records',
      subtitle:
        'Update your sharing settings to allow partners to view new accounts',
      actionText: 'View Sharing',
      action: onClickedUpdateSharing,
    };
  }
  return {
    title: 'Not all partners are sharing data with you',
    subtitle: 'Request data from partners to compare data in your report',
    actionText: 'View Partners',
    action: onClickedViewPartners,
  };
});

const emptyState = computed(() => {
  if (isPartnerTagsType.value && !tagOptions.value.length) return NO_TAGS;
  if (isPartnerTagsType.value && noPartnersInTag.value)
    return NO_PARTNERS_IN_TAG;
  if (ownNotSharing.value) return OWN_NOT_SHARING;
  if (partnersNotSharing.value) return PARTNERS_NOT_SHARING;
  return false;
});

const emptyStateMapped = {
  [NO_TAGS]: {
    title: () => {
      return 'Create Partner Tags to compare data with partners';
    },
    description:
      'You don’t have any Partner Tags yet, create tags to manage partners and compare data',
    link: {
      text: 'Learn more about partner tags',
      url: 'https://help.crossbeam.com/en/articles/5467749-partner-tags',
    },
    button: {
      text: 'Create Partner Tags',
      path: 'partners',
    },
    pngName: 'no-tags-created.png',
  },
  [NO_PARTNERS_IN_TAG]: {
    title: (tagName) => {
      return `${tagName} tag has no associated partners`;
    },
    description: 'Add partners to this tag to compare data and create reports',
    link: {
      text: 'Learn more about partner tags',
      url: 'https://help.crossbeam.com/en/articles/5467749-partner-tags',
    },
    button: {
      text: 'Update Partner Tags',
      path: 'partners',
    },
    pngName: 'no-partners-in-tag.png',
  },
  [OWN_NOT_SHARING]: {
    title: () => {
      return 'Update sharing settings to compare data';
    },
    description:
      'You are not sharing data with partners, change your sharing settings to compare data',
    link: {
      text: 'Learn more about customizing sharing settings',
      url: 'https://help.crossbeam.com/en/articles/4236619-sharing-defaults-for-populations',
    },
    button: {
      text: 'View Populations',
      path: 'populations',
    },
    pngName: 'own-not-sharing.png',
  },
  [PARTNERS_NOT_SHARING]: {
    title: () => {
      return 'Compare data with partners';
    },
    description: 'Request data sharing with your partners to compare data',
    link: {
      text: 'Learn more about requesting partner data ',
      url: 'https://help.crossbeam.com/en/articles/3976078-requesting-shared-data-from-your-partner',
    },
    button: {
      text: 'View Partners',
      path: 'partners',
    },
    pngName: 'partner-not-sharing.png',
  },
};

async function emptyStateButtonClicked() {
  await router.push({ name: emptyStateMapped[emptyState.value].button.path });
}

async function pushToSharing(to, ownNoOppsData) {
  if (LIVE_PARTNER_DETAIL_TABS.some((tab) => tab.value === to)) {
    await router.push({
      name: 'partner_details',
      params: { partner_org_id: props.selectedPartnerOrg?.id },
      query: { tab: to },
    });
  } else if (ownNoOppsData) {
    const { feed_id: feedId, schema: sourceName } =
      potentialRevenueSettingsSource.value;
    const name = sourceName.includes(SNOWFLAKE_DATA_SOURCE_TYPE)
      ? 'snowflake-settings'
      : 'data-templates';
    await router.push(to({ feedId, name }));
  } else await router.push(to);
}

provide('clickToSelectGridCell', true);

const hideEmptyPopulations = ref(true);
function onHidePopsCheck() {
  hideEmptyPopulations.value = !hideEmptyPopulations.value;
  emit('hide-pops-updated', hideEmptyPopulations.value);
}

const dataShareRequestModalVisible = ref(false);
const orgIds = ref(null);
const partnerPops = ref(null);

function onRequestData({ orgId, partnerPop }) {
  dataShareRequestModalVisible.value = true;
  orgIds.value = [orgId];
  partnerPops.value = { [orgId]: [partnerPop] };
}

watch(
  () => props.type,
  async () => {
    emit('reset-report-values');
    selectedPartnerTag.value = undefined;

    if (isPartnerTagsType.value && !!props?.initialPartnerTag) {
      setInitialPartnerTag();
    }
    if (
      !isUsingAMMGrid.value &&
      !isPartnerTagsType.value &&
      (!incomingPopExclusions.value[ALL_PARTNERS_TYPE] ||
        !outgoingPopExclusions.value[ALL_PARTNERS_TYPE])
    ) {
      await getAllOutgoingPopExclusions();
      await getAllIncomingPopExclusions();
    }
  },
  { immediate: true },
);
watch(
  () => props.partnerInfo,
  () => {
    const partnerId = props.selectedPartnerOrg?.id;
    if (!partnerId) return;
    hasPopulations.value =
      props.partnerInfo?.open_status_code !== INELIGIBLE_HIDDEN_POPULATION &&
      !!populations.value.length;
    hasPartnerPopulations.value =
      !!treeIdToPopulationsMap.value[partnerId]?.length;
  },
  { immediate: true },
);
watch(
  () => props.initialPartnerTag,
  () => {
    selectedPartnerTag.value = props.initialPartnerTag;
  },
);
</script>

<style lang="pcss" scoped>
:deep(.bitts-select__option-label) {
  @apply w-auto;
}
:deep(.ant-select-item-option-active) {
  background-color: theme(colors.neutral.background-weak) !important;
}
.report-builder {
  @apply h-full flex flex-col;
}
.report-builder-amm-grid-header {
  @apply flex justify-between items-center mb-16;
}
.report-builder__greenfield-info {
  @apply text-neutral-text;
}
.report-builder__greenfield-selection {
  @apply p-16 bg-neutral-background-weak border border-solid
  border-neutral-border rounded-bts-md w-full text-left flex flex-col gap-5;
}
.report-builder__greenfield-selected {
  @apply bg-info-background-weak border-info-accent;
}
.report-builder__greenfield-type {
  @apply font-bold text-m text-neutral-text-strong;
}
.report-builder__number {
  @apply text-neutral-text-weak px-8 inline border text-base
    border-solid border-neutral-border rounded-bts-sm mr-8;
}
.report-builder__select-partner {
  @apply mb-24;
}
.report-builder__title {
  @apply text-neutral-text-strong font-bold text-m flex items-center;
}
</style>

<style lang="pcss">
.report-builder {
  .c-bitts-empty-state-large {
    @apply w-full;
  }
  .c-bitts-empty-state-large-border {
    @apply border-none;
  }
}
</style>
