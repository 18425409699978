<template>
  <Notification v-if="feed?.integration" :item="item">
    <template #logo>
      <BittsSvg :svg="feed.integration.type + 'Icon'" />
    </template>
    <template #message> Data Ready </template>
    <template #description>
      Your {{ feed?.integration.friendly_name }} data has finished processing
    </template>
    <template #action>
      <BittsButton
        text="Create Population"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="createPopulation"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'company']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script setup lang="ts">
import { BittsButton, BittsSvg } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import useNotifications from '@/composables/useNotifications';
import { useFeedsStore, useNotificationsStore } from '@/stores';
import { Notification as NotificationType } from '@/types/notifications';

const { item } = defineProps<{ item: NotificationType }>();

const emit = defineEmits<(e: 'action-clicked', action: string) => void>();

const { parseFeedIdFromFeedProcessedEvent } = useNotifications();

const feedsStore = useFeedsStore();
const feed = computed(() => {
  return feedsStore.getFeedById(parseFeedIdFromFeedProcessedEvent(item));
});

const router = useRouter();
const notificationsStore = useNotificationsStore();
async function createPopulation() {
  emit('action-clicked', 'Create Population');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  await router.push({ name: 'create_population' });
}
</script>
