<template>
  <div class="c-synctree-branch">
    <div class="flex items-center">
      <button
        class="c-synctree-branch__toggle"
        :disabled="
          opportunityContactRoleObjectDisabled ||
          dealContactAssociationsObjectDisabled ||
          isUnauthorized
        "
        @click="openBranch"
        type="button"
      >
        <FontAwesomeIcon
          :icon="['fak', 'chevron-right']"
          class="text-neutral-accent"
          :class="{
            'rotate-90 transition': isOpen,
            'rotate-[-90] transition': !isOpen,
          }"
          :style="{ height: '11px', width: '11px' }"
        />
        <h2 class="c-synctree-branch__title">
          {{ startCase(objectType) }}
        </h2>
      </button>
      <span
        v-if="syncingObjectFields.length > 0"
        class="text-sm text-neutral-text-weak mr-8"
      >
        {{ syncingFieldCountText }}
      </span>
      <component
        :is="!!sourceDisabledText ? BittsTooltip : 'div'"
        :mount-to-body="true"
      >
        <div>
          <BittsSwitch
            v-model="isObjectSyncing"
            :disabled="!!sourceDisabledText"
            data-testid="c-synctree-branch__object-switch"
            @change="(val) => handleObjectSyncToggle(val)"
          />
        </div>
        <template #title>
          {{ sourceDisabledText }}
        </template>
      </component>
    </div>
    <div
      v-if="isOpen"
      class="c-synctree-branch__leafs"
      :class="{ 'mt-8': isOpen }"
    >
      <div
        v-for="sourceField in filteredObjectFields"
        :key="sourceField.id"
        class="c-synctree-branch__leaf"
        data-testid="c-synctree-branch__leaf"
      >
        <SyncTreeLeaf
          v-if="!sourceField.relationship_id"
          :source-field="sourceField"
          :source-field-map="sourceFieldMap"
          :reference-field-ids="referenceFieldIds"
          :lookup-field-ids="lookupFieldIds"
          :checked-reference-ids="checkedReferenceIds"
          :checked-lookup-ids="checkedLookupIds"
          :required-fields="requiredFields"
          :is-last-base-table="isLastBaseTable"
          @update="handleFieldChecked"
        />
        <LookupSubtree
          v-else-if="!sourceField.copies_source_field_id"
          :search="normalizedSearch"
          :title="capitalize(sourceField.display_name)"
          :lookup-field-ids="lookupFieldIds"
          :checked-reference-ids="checkedReferenceIds"
          :relationship-id="sourceField.relationship_id"
          :source-field-map="sourceFieldMap"
          class="ml-8 mb-4"
          @update="handleFieldChecked"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BittsSwitch, BittsTooltip } from '@crossbeam/bitts';

import { capitalize, startCase } from 'lodash';
import { computed, inject, ref, watch } from 'vue';

import LookupSubtree from '@/components/data-sources/data-templates/LookupSubtree.vue';
import SyncTreeLeaf from '@/components/data-sources/data-templates/SyncTreeLeaf.vue';

import {
  HS3_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  PIPEDRIVE_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  SOURCE_STATUS_MAP,
} from '@/constants/data_sources';

const {
  objectType,
  objectStatus,
  sourceFieldMap = {},
  lookupFieldIds = [],
  checkedLookupIds = [],
  referenceFieldIds = [],
  checkedReferenceIds = [],
  search = '',
} = defineProps<{
  objectType: string;
  objectStatus: string | null;
  sourceFieldMap?: object;
  lookupFieldIds?: [];
  checkedLookupIds?: [];
  referenceFieldIds?: [];
  checkedReferenceIds?: [];
  search?: string;
}>();

const emit = defineEmits(['update', 'bulk-update']);

const crm = inject('crm');

const normalizedSearch = computed(() => search.toLowerCase());

const isUnauthorized = computed(() =>
  objectStatus ? SOURCE_STATUS_MAP.missing.includes(objectStatus) : false,
);

const objectFields = computed(() => {
  return Object.values(sourceFieldMap)
    .filter((sourceField) => sourceField.object === objectType)
    .sort((a, b) => (a.display_name > b.display_name ? 1 : -1));
});
const requiredFields = computed(() =>
  objectFields.value.filter((sourceField) => sourceField.isRequiredForObject),
);
const syncingObjectFields = computed(() =>
  objectFields.value.filter((sourceField) => sourceField.isChecked),
);
const allFields = computed(() => Object.values(sourceFieldMap));

const syncingFieldCountText = computed(() => {
  const fieldLength = syncingObjectFields.value.length;
  if (fieldLength > 1) return `${fieldLength} fields`;
  return `${fieldLength} field`;
});

const filteredObjectFields = computed(() => {
  return objectFields.value.filter((sourceField) => {
    if (sourceField.object !== objectType) return false;
    if (normalizedSearch.value === '') return true;
    return sourceField.searchName.includes(normalizedSearch.value);
  });
});

const hasLockedReferenceFields = computed(() => {
  return syncingObjectFields.value.some(
    (sourceField: { id: number; isChecked: boolean }) => {
      if (
        sourceField.isChecked &&
        (checkedReferenceIds as number[])?.includes(sourceField.id)
      ) {
        for (const checkedLookupId of checkedLookupIds) {
          const pointer = (
            sourceFieldMap[checkedLookupId] as {
              copies_source_field_id: number;
            }
          ).copies_source_field_id;
          if (sourceField.id === pointer) return true;
        }
      }
      return false;
    },
  );
});

const hasPopulationFilter = computed(() =>
  syncingObjectFields.value.some((f) => f.blockedPops.length > 0),
);

/* The last base table cannot be turned off */
const isLastBaseTable = computed(() => {
  if (!isObjectSyncing.value) return false;
  const baseTables = {
    [SALESFORCE_DATA_SOURCE_TYPE]: ['Account', 'Lead'],
    [HS3_DATA_SOURCE_TYPE]: ['companies'],
    [MD_DATA_SOURCE_TYPE]: ['accounts', 'leads'],
    [PIPEDRIVE_DATA_SOURCE_TYPE]: ['organization'],
  };
  const crmTables = baseTables[crm as keyof typeof baseTables];
  if (crmTables.includes(objectType)) {
    // This is the last base table we cannot turn it off
    if (crmTables.length === 1) return true;
    // There is more than one base table we have to check if the other one has any checked fields
    // before turning the current base table off
    const matchingObject =
      crmTables.at(0) === objectType ? crmTables.at(1) : crmTables.at(0);
    return !allFields.value.some(
      (field) => field.object === matchingObject && field.isChecked,
    );
  }
  return false;
});

/* Salesforce
The Contact and Opportunity objects cannot be turned off if OpportunityContactRole
is enabled. The OpportunityContactRole cannot be disabled if either of them is turned on. */
const opportunitySyncing = computed(() =>
  allFields.value.some(
    (field) => field.object === 'Opportunity' && field.isChecked,
  ),
);
const contactSyncing = computed(() =>
  allFields.value.some(
    (field) => field.object === 'Contact' && field.isChecked,
  ),
);
const opportunityContactRoleSyncing = computed(() =>
  allFields.value.some(
    (field) => field.object === 'OpportunityContactRole' && field.isChecked,
  ),
);
const opportunityContactRoleObjectDisabled = computed(
  () =>
    objectType === 'OpportunityContactRole' &&
    (!contactSyncing.value || !opportunitySyncing.value),
);
const oppOrContactObjectDisabled = computed(
  () =>
    ['Opportunity', 'Contact'].includes(objectType) &&
    opportunityContactRoleSyncing.value,
);

/* HubSpot
The contacts and deals objects cannot be turned off if deal_contact_associations
is enabled. The deal_contact_associations cannot be disabled if either of them is turned on. */
const dealsSyncing = computed(() =>
  allFields.value.some((field) => field.object === 'deals' && field.isChecked),
);
const contactsSyncing = computed(() =>
  allFields.value.some(
    (field) => field.object === 'contacts' && field.isChecked,
  ),
);
const dealContactAssociationsSyncing = computed(() =>
  allFields.value.some(
    (field) => field.object === 'deal_contact_associations' && field.isChecked,
  ),
);
const dealContactAssociationsObjectDisabled = computed(
  () =>
    objectType === 'deal_contact_associations' &&
    (!contactsSyncing.value || !dealsSyncing.value),
);
const dealsOrContactsObjectDisabled = computed(
  () =>
    ['deals', 'contacts'].includes(objectType) &&
    dealContactAssociationsSyncing.value,
);

/* Disabled text for the tooltip */
const sourceDisabledText = computed(() => {
  if (isUnauthorized.value) {
    return "Your integration user doesn't have access to this object. Edit your Salesforce permissions to be able to import it to Crossbeam.";
  }
  if (isLastBaseTable.value) {
    let msg = 'This object is required for Crossbeam to function';
    if (crm === SALESFORCE_DATA_SOURCE_TYPE)
      msg += `. Turn on ${objectType === 'Account' ? 'Lead' : 'Account'} to disable this object`;
    return msg;
  }
  if (hasLockedReferenceFields.value)
    return 'Includes a field powering a lookup field';
  if (hasPopulationFilter.value)
    return 'Includes a field used in a population filter';
  if (
    opportunityContactRoleObjectDisabled.value &&
    !opportunityContactRoleSyncing.value
  )
    return 'This object requires the Opportunity and Contact objects';
  if (oppOrContactObjectDisabled.value)
    return 'Please unsync the Opportunity Contact Object to disable this object';
  if (
    dealContactAssociationsObjectDisabled.value &&
    !dealContactAssociationsSyncing.value
  )
    return 'This object requires the Deals and Contacts objects';
  if (dealsOrContactsObjectDisabled.value)
    return 'Please unsync the Deal Contact Associations to disable this object';
  return null;
});

const isObjectSyncing = computed(() => syncingObjectFields.value.length > 0);

function handleObjectSyncToggle(enabled: boolean) {
  if (!enabled) uncheckAllFields();
  else checkAllRequiredFields();
}

function handleFieldChecked({
  sourceField,
  isChecked,
}: {
  sourceField: object;
  isChecked: boolean;
}) {
  const updatedField = { ...sourceField, isChecked };
  emit('update', { sourceField: updatedField });

  if (isChecked) {
    handleObjectSyncToggle(true);
  } else if (syncingObjectFields.value.length === 0)
    handleObjectSyncToggle(false);
}

function uncheckAllFields() {
  emit('bulk-update', {
    sourceFields: objectFields.value.map((f) => ({ ...f, isChecked: false })),
  });
}

function checkAllRequiredFields() {
  emit('bulk-update', {
    sourceFields: requiredFields.value.map((f) => ({ ...f, isChecked: true })),
  });
}

/* State of dropdowns */
const isOpen = ref(false);
watch(normalizedSearch, (newValue) => {
  if (newValue === '') {
    isOpen.value = false;
    return;
  }

  if (filteredObjectFields.value.length > 0) {
    isOpen.value = true;
  } else {
    isOpen.value = false;
  }
});

function openBranch() {
  if (opportunityContactRoleObjectDisabled.value) return;
  if (dealContactAssociationsObjectDisabled.value) return;
  isOpen.value = !isOpen.value;
}

watch(opportunityContactRoleObjectDisabled, () => {
  if (opportunityContactRoleObjectDisabled.value) isOpen.value = false;
});

watch(dealContactAssociationsObjectDisabled, () => {
  if (dealContactAssociationsObjectDisabled.value) isOpen.value = false;
});
</script>

<style lang="pcss" scoped>
.c-synctree-branch {
  @apply border border-neutral-border rounded-8 px-16 py-8 mb-8;
}

.c-synctree-branch__title {
  @apply text-neutral-text-strong font-bold;
}

.c-synctree-branch__leafs {
  @apply border-l border-neutral-border ml-4 pl-10;
}

.c-synctree-branch__leaf {
  @apply flex justify-between items-start;
}
</style>
<style lang="pcss">
.c-synctree-branch__toggle {
  @apply flex flex-1 items-center justify-start gap-8;
}

/* Checkbox label */
.c-synctree-branch__leaf .ant-checkbox + span {
  @apply flex-1;
}
</style>
