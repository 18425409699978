<template>
  <BittsButton
    :text="label"
    :loading="isRedirecting"
    size="small"
    type="neutral"
    variant="outline"
    @click="installSlack"
  >
    <template #icon>
      <BittsSvg svg="slackIcon" class="mr-6 w-12 h-12" />
    </template>
  </BittsButton>
</template>

<script setup lang="ts">
import { BittsButton, BittsSvg } from '@crossbeam/bitts';

import axios from 'axios';
import { computed, ref } from 'vue';

import { ls } from '@/local_storage';
import urls from '@/urls';

const { nextUrl, isSlackEnabled = false } = defineProps<{
  nextUrl?: string;
  isSlackEnabled?: boolean;
}>();

const isRedirecting = ref(false);

const label = computed(() =>
  isSlackEnabled ? 'Reauthorize Connection' : 'Connect to Slack',
);

async function installSlack() {
  isRedirecting.value = true;
  ls.nextUrl.set(nextUrl);
  const slackResponse = await axios.get(urls.slack.installUrl);
  window.location.href = slackResponse.data.slack_install_url;
}
</script>
