<template>
  <div class="population-detail">
    <div class="sidebar">
      <BittsCard class="h-full overflow-auto">
        <BittsLoading :is-loading="loadingInitialState">
          <div class="population-detail__sidebar-content">
            <BittsTabs
              :tabs="tabs"
              :full-width="false"
              :current-value="activeTab"
              class="mt-16 ml-24 z-0"
              @change:tab="changeTab"
            />
            <div
              v-if="activeTab === OVERVIEW_TAB"
              class="population-detail__sidebar-section"
            >
              <div v-if="isEditMode" class="p-16 pt-24">
                <BittsSelect
                  v-if="offlinePartnerId"
                  data-testid="offline-partner-name"
                  :disabled="true"
                  form-label="Offline Partner"
                  option-type="company"
                  class="mb-24"
                >
                  <template #placeholder>
                    <div class="flex items-center justify-start gap-8">
                      <BittsAvatar
                        size="x-small"
                        :show-initials="true"
                        :org="offlinePartner"
                      />
                      <span class="text-neutral-text">
                        {{ offlinePartner?.name }}
                      </span>
                    </div>
                  </template>
                </BittsSelect>
                <BittsRadioGroupCards
                  v-if="allowCustomOrStandardChoice"
                  :options="POPULATION_TYPES"
                  :initial-value="initialPopulationType"
                  class="mb-24"
                  @change="onPopulationTypeChange"
                />
                <BittsInput
                  v-if="!hasStandardType"
                  v-model="populationName"
                  form-label="Population Name"
                  name="population-name"
                  placeholder="New Population"
                  class="mb-16"
                  :status="isPopulationNameValid ? 'default' : 'danger'"
                  :danger-text="populationNameErrorText"
                />
                <BittsSelect
                  v-else
                  v-model="standardPopulationTypeSelection"
                  :disabled="isUserEditing"
                  :options="missingStandardPopulations"
                  :allow-clear="false"
                  :searchable="false"
                  placeholder="Select Standard Type"
                  form-label="Population Name"
                  class="mb-24"
                  @update:model-value="handleStandardPopChange"
                />
                <BittsTextArea
                  v-model="population.description"
                  class="mb-24"
                  :form-label="{
                    title: 'Population Description',
                    secondaryText: 'Optional',
                  }"
                  placeholder="Add a description to help your team and your partners know what to expect in this Population"
                  :status="isDescriptionInvalid ? 'danger' : 'default'"
                  danger-text="Description cannot be more than 200 characters."
                />
                <BittsSelect
                  v-if="!offlinePartnerUuid"
                  v-model="selectedFeedId"
                  :disabled="isUserEditing"
                  :options="feedOptions"
                  option-type="svg"
                  placeholder="Select connected data source"
                  :searchable="false"
                  form-label="Data Source"
                  class="mb-24"
                  @update:model-value="onFeedChange"
                >
                  <template #suffix="{ option }">
                    <div
                      v-if="
                        hasMultiSelectSalesforce &&
                        option.integrationType === SALESFORCE_DATA_SOURCE_TYPE
                      "
                      class="population-detail__salesforce-url"
                    >
                      {{ option.nickname }}
                    </div>
                  </template>
                </BittsSelect>
                <div>
                  <BittsSelect
                    v-model="selectedSource"
                    :disabled="isUserEditing || !selectedFeed || !feedProcessed"
                    :options="sourceOptions"
                    :form-label="sourceSelectionLabel"
                    @update:model-value="onSourceChange"
                  />
                  <BittsAlert
                    v-if="processingWarning.show"
                    :message="processingWarning.message"
                    :color="processingWarning.isError ? 'error' : 'warning'"
                    class="mt-16 text-warning-text"
                  >
                    <template #body>
                      <BittsSvg
                        v-if="isUpdatingSources"
                        svg="spinner"
                        class="animate-spin text-warning-accent w-20"
                      />
                      <p
                        v-else-if="processingWarning.isError"
                        data-id="processing-error"
                        class="text-danger-text"
                      >
                        An error is stopping
                        {{ selectedFeed.integration.friendly_name }} data from
                        being loaded into Crossbeam. Click
                        <button
                          data-id="processing-error-button"
                          class="text-brand-blue cursor-pointer"
                          @click="redirectToDatasource"
                          type="button"
                        >
                          here
                        </button>
                        to see the error on the data sources page.
                      </p>
                      <p v-else-if="!isCSVSelected || !feedProcessed">
                        Click
                        <span
                          class="refresh-sources-link"
                          @click="onCheckSources"
                          >here</span
                        >
                        to check again
                      </p>
                    </template>
                  </BittsAlert>
                </div>
              </div>
              <div v-else>
                <div class="py-24 px-16 flex flex-col">
                  <span
                    :class="!population.description ? 'mb-16' : null"
                    class="text-neutral-900 text-lg font-bold"
                    >{{ population.name }}</span
                  >
                  <span
                    v-if="population.description"
                    class="text-neutral-600 mb-16"
                  >
                    {{ population.description }}
                  </span>
                  <div class="flex flex-row justify-between">
                    <div class="flex flex-col w-full">
                      <span class="population-detail__subtitle">
                        {{ recordType.toUpperCase() }}
                      </span>
                      <BittsLoading :is-loading="loadingCount" :icon-width="24">
                        <span
                          v-if="isUserEditing"
                          class="text-m font-bold text-neutral-500"
                        >
                          {{
                            populationPreviewCountTimeout
                              ? '-'
                              : populationPreviewCount
                          }}
                        </span>
                      </BittsLoading>
                    </div>
                    <div
                      v-if="!offlinePartnerUuid"
                      class="flex flex-col items-start w-full"
                    >
                      <span class="population-detail__subtitle">
                        Sharing Default
                      </span>
                      <div v-if="isUserEditing" class="flex items-center">
                        <SharingSetting
                          placement="bottomRight"
                          :data-share="sharingSetting.dataShare"
                        />
                        <BittsButton
                          v-if="hasWritePopulationPermissions"
                          type="neutral"
                          variant="outline"
                          class="ml-8"
                          size="x-small"
                          :center-icon="['fak', 'edit']"
                          center-icon-classes="text-neutral-400"
                          @click="goToEditSharing"
                        />
                      </div>
                      <div
                        v-else
                        class="text-sm text-neutral-500 px-8 py-2 rounded-bts-sm bg-neutral-100"
                      >
                        Save Population to set
                      </div>
                    </div>
                  </div>
                </div>
                <BittsDivider class="m-0" />
                <div class="p-16 flex flex-col">
                  <div class="flex items-center">
                    <BittsSvg
                      v-if="selectedFeed?.integration"
                      :svg="selectedFeed.integration.type + 'Icon'"
                      class="mr-16 w-40"
                    />
                    <div class="flex flex-col">
                      <span class="text-neutral-900">
                        {{ populationSchemaName }}
                      </span>
                      <span class="text-sm text-neutral-500">
                        {{ population.base_table }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PopulationFilters
              v-else
              class="population-detail__sidebar-section p-16"
              :population="population"
              :filter-group-list="filterGroupList"
              :can-edit-population="canEditPopulation"
              :can-user-add-filter-group="canUserAddFilterGroup"
              :selected-feed="selectedFeed"
              :complete-filter-sources="completeFilterSources"
              @add-filter-group="onAddFilterGroup"
              @delete-filter-group="onDeleteFilterGroup"
              @change-filter-group-list="onChangeFilterGroupList"
            />
            <PopulationDetailFooter
              :loading="loading"
              :active-tab="activeTab"
              :population="population"
              :population-has-changed="populationHasChanged"
              :can-edit-population="canEditPopulation"
              :is-edit-mode="isEditMode"
              :is-user-editing="isUserEditing"
              :is-population-valid="isPopulationValid"
              :processing-warning="processingWarning"
              @edit="isEditMode = true"
              @continue="onContinueClick"
              @run="onRunPopulation"
              @save-and-run="onSaveAndRunClick"
            />
          </div>
        </BittsLoading>
      </BittsCard>
    </div>
    <PopulationTable
      :loading-data="loadingData"
      :loading-count="loadingCount"
      :population-preview-count="populationPreviewCount"
      :population-preview-count-timeout="populationPreviewCountTimeout"
      :data="data"
      :offline-partner-uuid="offlinePartnerUuid"
      :has-user-run-population="hasUserRunPopulation"
    />
  </div>
  <BittsModal
    title="Change Data Source"
    confirm-type="danger"
    :visible="showConfirmDataSourceChangeModal"
    cancel-text="No, Go Back"
    save-text="Yes, Change My Data Source"
    content-text="Changing your data source will reset your filters. Is that okay?"
    @saved="confirmChangeDataSource"
    @closed="revertChangeDataSource"
  />
  <BittsModal
    v-if="hasPopulationFilterModal"
    title="Are you sure you don't need any filters?"
    content-text="This Population is currently all the data from your data source, we recommend filtering down to a specific segment of your data."
    :visible="showEmptyFiltersModal && filtersEmpty"
    save-text="Save"
    confirm-type="primary"
    @saved="onSaveEmptyFilters"
    @closed="onCloseEmptyFilters"
  />
  <router-view-wrapper />
</template>

<script setup>
import {
  BittsAlert,
  BittsAvatar,
  BittsButton,
  BittsCard,
  BittsDivider,
  BittsInput,
  BittsLoading,
  BittsModal,
  BittsRadioGroupCards,
  BittsSelect,
  BittsSvg,
  BittsTabs,
  BittsTextArea,
} from '@crossbeam/bitts';

import axios from 'axios';
import { isEmpty, uniqBy } from 'lodash';
import { storeToRefs } from 'pinia';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  toRaw,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import PopulationDetailFooter from '@/components/populations/PopulationDetailFooter.vue';
import PopulationFilters from '@/components/populations/PopulationFilters.vue';
import PopulationTable from '@/components/populations/PopulationTable.vue';
import SharingSetting from '@/components/populations/SharingSetting.vue';

import { crossbeamApi } from '@/api';
import useAlerts from '@/composables/useAlerts';
import useAuth from '@/composables/useAuth';
import useOfflinePartner from '@/composables/useOfflinePartner';
import usePopulations from '@/composables/usePopulations';
import {
  GREENFIELD_SHARING,
  HIDDEN,
  PARTNER_RULE,
  POPULATION_RULE,
  VISIBILITY_OPTIONS_TEXT,
} from '@/constants/data_shares';
import {
  COMPLETED_STATUSES,
  DELETING_FEED_STATUS,
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  SOURCE_STATUS_MAP,
  WARNING_STATUSES,
} from '@/constants/data_sources';
import {
  MULTI_SELECT_SF,
  POPULATION_FILTER_MODAL,
} from '@/constants/feature_flags';
import { MDM_TYPES } from '@/constants/mdm';
import { emptyPopulation } from '@/constants/populations';
import {
  ALL_STANDARD_POPULATIONS,
  MAP_POP_NAME_TO_STANDARD_TYPE,
  MAP_STANDARD_TYPE_TO_POP_NAME,
} from '@/constants/standard_populations';
import { captureException } from '@/errors';
import {
  useBillingStore,
  useDataSharesStore,
  useFeatureFlagStore,
  useFeedsStore,
  useFileUploadsStore,
  useFlashesStore,
  usePartnersStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';
import { returnTrimmedDomain, sortByKey } from '@/utils';

const props = defineProps({
  populationId: {
    type: Number,
    default: null,
  },
});
const emit = defineEmits(['population-change', 'has-user-run-population']);
const FILTER_TAB = 'filters';
const OVERVIEW_TAB = 'overview';

const ADD_NEW_SOURCE = 'add-new-source';

const router = useRouter();
const route = useRoute();
const offlinePartnerId = computed(() => route.query.offlinePartnerId);
const offlinePartnerUuid = computed(() => route.query.offlinePartnerUuid);

const isUserEditing = computed(() => {
  return (
    route.name.includes('edit_population') ||
    route.name === 'delete-population-modal' ||
    route.name === 'offline-delete-population-modal'
  );
});
const queryStandardType = computed(() => route.query?.standardType);

const billingStore = useBillingStore();
const dataSharesStore = useDataSharesStore();
const feedsStore = useFeedsStore();
const fileUploadsStore = useFileUploadsStore();
const populationsStore = usePopulationsStore();
const sourcesStore = useSourcesStore();
const flashesStore = useFlashesStore();
const partnersStore = usePartnersStore();
const loadingInitialState = ref(true);

const { customPopulations } = storeToRefs(populationsStore);

const featureFlagStore = useFeatureFlagStore();
const hasMultiSelectSalesforce = computed(() =>
  featureFlagStore.hasFeatureFlag(MULTI_SELECT_SF),
);
const hasPopulationFilterModal = computed(() =>
  featureFlagStore.hasFeatureFlag(POPULATION_FILTER_MODAL),
);

const { isFreeTier, customPopulationLimit } = storeToRefs(billingStore);

const { currentUploadNames: liveCurrentUploadNames } =
  storeToRefs(fileUploadsStore);
const { feeds: liveFeeds } = storeToRefs(feedsStore);
const { populations: livePopulations } = storeToRefs(populationsStore);
const { sources: liveSources, isPolling: sourcesPolling } =
  storeToRefs(sourcesStore);

/* Offline partner state */
const {
  offlineFeeds,
  offlineSources,
  offlinePopulations,
  offlineCurrentUploadNames,
  refreshOfflineData,
  offlineFilterableSources,
  deleteOfflinePopulation,
} = useOfflinePartner();

provide('offlinePopulations', offlinePopulations);
provide('deleteOfflinePopulation', deleteOfflinePopulation);
provide('offlineSources', offlineSources);

/* This component's state is all derived more or less from feeds, sources, upload names,
and populations. When we are managing an offline partner's population we use the data from our
composable rather than from our Pinia stores */
const feeds = computed(() =>
  offlinePartnerUuid.value ? offlineFeeds.value : liveFeeds.value,
);
const sources = computed(() =>
  offlinePartnerUuid.value ? offlineSources.value : liveSources.value,
);
const currentUploadNames = computed(() =>
  offlinePartnerUuid.value
    ? offlineCurrentUploadNames.value
    : liveCurrentUploadNames.value,
);
const populations = computed(() =>
  offlinePartnerUuid.value ? offlinePopulations.value : livePopulations.value,
);

const {
  INITIAL_DATA,
  savePopulation,
  previewPopulation,
  previewPopulationCount,
  makePopulationPayload,
} = usePopulations(null, offlinePartnerUuid.value);

const population = ref(emptyPopulation({}));
const isEditMode = ref(true);

const loadingCount = ref(true);
const loadingData = ref(true);

/* Permissions */
const { currentOrg, hasScope } = useAuth();

const hasWritePopulationPermissions = computed(() =>
  offlinePartnerUuid.value
    ? hasScope('write:offline-partners')
    : hasScope('write:populations'),
);
const isEarlyAdopterFreePop = computed(() => {
  if (customPopulationLimit.value === Infinity) return false;
  return !hasStandardType.value && isFreeTier.value;
});
const canEditPopulation = computed(
  () => hasWritePopulationPermissions.value && !isEarlyAdopterFreePop.value,
);

/* The starting hook here does a number of checks and populates the component with initial preset data */
onMounted(async () => {
  await Promise.all([
    dataSharesStore.refreshDataSharesStore(),
    feedsStore.refreshFeedsStore(),
    sourcesStore.refreshSourcesStore(),
    populationsStore.refreshPopulationsStore(),
  ]);

  /* For offline partners, we do not make them select the feed.
  We do this part for them automatically since CSVs are the
  only supported data source right now */
  if (offlinePartnerUuid.value) {
    await refreshOfflineData(offlinePartnerUuid.value);
    selectedFeedId.value = offlineFeeds.value.at(0).id;
  }

  try {
    /* Check that population was not deleted */
    if (props.populationId) {
      population.value = populations.value.find(
        (p) => p.id === props.populationId,
      );
      const feed = feeds.value.find(
        (feed) => feed.schema_name === population.value.base_schema,
      );
      const isGoogle =
        feed?.integration?.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE;
      const isCSV = feed?.integration?.type === FILE_UPLOAD_DATA_SOURCE_TYPE;
      if (
        (isGoogle &&
          !sources.value.some((s) => s.id === population.value.source_id)) ||
        (isCSV &&
          !currentUploadNames.value.includes(population.value.base_table))
      ) {
        flashesStore.addWarningFlash({
          message: 'Data source could not be found',
        });
        await router.push({ name: 'populations' });
        return;
      }
    }

    /* If routing to populations w/out queryStandardType and no standard pops are available, leave the view */
    if (
      !showCustomPopulationOption.value &&
      !queryStandardType.value &&
      !props.populationId
    ) {
      if (!missingStandardPopulations.value.length) {
        await router.push({ name: 'populations' });
        return;
      }
      const standardType = missingStandardPopulations.value[0].standard_type;
      await router.push({
        name: 'create_population',
        query: { standardType },
      });
    }

    /* If we make it this far, set the form data */
    await setFormData();

    /* Set filter group list */
    if (hasSourceFilterExpression.value) setFilterData();

    /* Force selection of data source if only one option */
    if (
      feedOptions.value.length === 1 &&
      !population.value.id &&
      !selectedFeed.value
    ) {
      const [onlyFeedAvailable] = feedOptions.value;
      onFeedChange(onlyFeedAvailable.id, onlyFeedAvailable.id);
      if (relevantSources.value.length === 1) {
        const [onlyAvailableSource] = relevantSources.value;
        selectedSource.value = onlyAvailableSource.name;
        onSourceChange(onlyAvailableSource.name, onlyAvailableSource.name);
      }
    }

    loadingInitialState.value = false;

    /* Fetch preview data  if it already exists */
    if (population.value.id) await onPreviewPopulation();
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'Something went wrong with this population.',
      description:
        'Please try again or contact support@crossbeam.com for help.',
    });
  } finally {
    loadingCount.value = false;
    loadingData.value = false;
    loadingInitialState.value = false;
  }
});

/* Makes sure the population has a source, name, and feed, and the name/description is valid */
const isPopulationValid = computed(() => {
  const conditions = [
    populationName.value,
    selectedFeed.value,
    !isDescriptionInvalid.value,
    selectedSource.value,
    isPopulationNameValid.value,
  ];
  if (!offlinePartnerUuid.value)
    conditions.push(!isCustomPopulationNameStandard.value);
  return conditions.every((c) => c);
});

/* This function is responsible for actually setting all the presets in the form */
const selectedSourceId = ref(null);
async function setFormData() {
  await resetFilterData();
  selectedSourceId.value = population.value.source_id;

  const standardPopulationType =
    population.value.standard_type || queryStandardType.value;
  if (standardPopulationType) {
    populationTypeRadioSelection.value = 'standard';
    standardPopulationTypeSelection.value =
      MAP_STANDARD_TYPE_TO_POP_NAME[standardPopulationType];
  } else {
    populationTypeRadioSelection.value = 'custom';
  }
  if (standardPopulationName.value)
    populationName.value = standardPopulationName.value;
  if (population.value.base_schema && population.value.base_table) {
    /* Set selected schema */
    if (
      !hasMultiSelectSalesforce.value ||
      populationSchemaName.value?.toLowerCase() !== SALESFORCE_DATA_SOURCE_TYPE
    ) {
      selectedFeedId.value = feedOptions.value.find(
        (feed) => feed.data.schema_name === population.value.base_schema,
      )?.id;
    } else {
      const popSource = sources.value.find(
        (source) => source.id === population.value.source_id,
      );
      if (popSource) {
        const currentFeedId = popSource.feed_id;
        selectedFeedId.value = feedOptions.value.find(
          (feed) => feed.id === currentFeedId,
        )?.id;
      }
    }
    /* Set selected source */
    selectedSource.value = relevantSources.value.find(
      (source) => source.table === population.value.base_table,
    )?.name;
  }
  if (population.value.name) populationName.value = population.value.name;
}

/* Filter Group Logic */
const completeFilterSources = ref([]);
const canUserAddFilterGroup = ref(true);
const filterGroupList = ref([]);
const hasSourceFilterExpression = computed(
  () => !isEmpty(population.value?.source_filter_expressions),
);
function setFilterData() {
  const filters = population.value.source_filter_expressions;
  filterGroupList.value = [];
  for (const table in filters) {
    const filterGroup = {
      filter_parts: [],
      filter_expression: filters[table].filter_expression,
      tableName: table,
    };
    filterGroup.filter_expression.forEach((expressionItem) => {
      if (!['AND', 'OR'].includes(expressionItem)) {
        const associatedFilterPart = filters[table].filter_parts.find(
          (part) => part.label === expressionItem,
        );
        filterGroup.filter_parts.push(associatedFilterPart);
      }
    });
    filterGroupList.value.push(filterGroup);
  }
  canUserAddFilterGroup.value = false;
}

/* Tab logic */
const activeTab = ref(OVERVIEW_TAB);
function changeTab(val) {
  activeTab.value = val;
}
const disableFiltersTab = computed(
  () => !filterGroupList.value.length && !canEditPopulation.value,
);
const tabs = computed(() => {
  return [
    {
      name: 'Overview',
      value: OVERVIEW_TAB,
      icon: ['fak', 'information-circle'],
    },
    {
      name: 'Filters',
      value: FILTER_TAB,
      icon: ['fas', 'filter'],
      disabled:
        !selectedFeed.value || !selectedSource.value || disableFiltersTab.value,
    },
  ];
});
async function onContinueClick() {
  activeTab.value = FILTER_TAB;
  await onRunPopulation();
}

/* Standard or Custom Radio Selector */
const populationTypeRadioSelection = ref('');
const allowCustomOrStandardChoice = computed(() => {
  if (offlinePartnerId.value) return false;
  return (
    missingStandardPopulations.value.length &&
    !isUserEditing.value &&
    showCustomPopulationOption.value
  );
});
const showCustomPopulationOption = computed(() => {
  if (offlinePartnerUuid.value) return false;
  return customPopulations.value.length < customPopulationLimit.value;
});
const POPULATION_TYPES = ref([
  { label: 'This is a standard population', value: 'standard' },
  { label: 'This is a custom population', value: 'custom' },
]);
const initialPopulationType = computed(() => {
  const standardPopulationType =
    population.value.standard_type || queryStandardType.value;
  return standardPopulationType ? 'standard' : 'custom';
});
const currentStandardPopulations = computed(() =>
  populations.value.filter((pop) => pop.standard_type),
);
const missingStandardPopulations = computed(() =>
  ALL_STANDARD_POPULATIONS.map((pop) => ({
    ...pop,
    value: pop.standard_type,
    label: pop.name,
  })).filter(
    (stdPop) =>
      !currentStandardPopulations.value.find(
        (pop) => pop.standard_type === stdPop.standard_type,
      ),
  ),
);

async function onPopulationTypeChange(populationType) {
  populationTypeRadioSelection.value = populationType;
  const route = { name: 'create_population', query: {} };
  if (populationType === 'custom') {
    populationName.value = '';
    await router.push(route);
    return;
  }

  const standardType =
    MAP_POP_NAME_TO_STANDARD_TYPE[standardPopulationTypeSelection.value] ||
    queryStandardType.value ||
    missingStandardPopulations.value[0]?.standard_type;
  route.query.standardType = standardType;

  standardPopulationTypeSelection.value = missingStandardPopulations.value.find(
    (p) => p.standard_type === standardType,
  ).standard_type;

  populationName.value = MAP_STANDARD_TYPE_TO_POP_NAME[standardType];
  await router.push(route);
}

/* Population Name */
const populationName = ref('');
const isPopulationNameValid = computed(() => {
  if (!populationName.value) return true;
  if (isCustomPopulationNameStandard.value) return false;
  const popWithName = offlinePartnerUuid.value
    ? offlinePopulations.value.find((p) => p.name === populationName.value)
    : populationsStore.getPopulationByName(populationName.value, true);
  return !popWithName || population.value.id === popWithName.id;
});
const isCustomPopulationNameStandard = computed(() =>
  hasStandardType.value
    ? false
    : ALL_STANDARD_POPULATIONS.some((pop) => pop.name === populationName.value),
);
const populationNameErrorText = computed(() =>
  isCustomPopulationNameStandard.value
    ? 'Population name cannot be the same as any standard Population name.'
    : 'Population names must be unique.',
);
const standardPopulationName = computed(() => {
  if (!hasStandardType.value) return '';
  const type = population.value.standard_type || queryStandardType.value;
  return MAP_STANDARD_TYPE_TO_POP_NAME[type];
});

/* Population Description */
const isDescriptionInvalid = computed(
  () =>
    population.value.description && population.value.description.length > 200,
);

/* Standard Pop Type Selection (Customers, Open Opps, etc.) */
const standardPopulationTypeSelection = ref(null);
const hasStandardType = computed(
  () => populationTypeRadioSelection.value === 'standard',
);
async function handleStandardPopChange(standardType) {
  if (!standardType) return;
  const newRoute = {
    name: route.name,
    query: { ...route.query, standardType },
  };
  await router.push(newRoute);
}

/* Population Filters */
function onAddFilterGroup(bool) {
  canUserAddFilterGroup.value = bool;
}
function onDeleteFilterGroup(filterGroup) {
  if (!canEditPopulation.value) return;
  const filterGroupIndex = filterGroupList.value.findIndex(
    (group) => group.tableName === filterGroup.tableName,
  );
  filterGroupList.value.splice(filterGroupIndex, 1);
  if (!filterGroupList.value.length) canUserAddFilterGroup.value = true;
}
function onChangeFilterGroupList(list) {
  filterGroupList.value = list;
}

/* Sharing Settings */
const sharingSetting = computed(() => {
  const populationSetting = dataSharesStore
    .getOutgoingSharingRules({
      populationId: population.value.id,
      ruleType: POPULATION_RULE,
    })
    .map((dataShare) => {
      return {
        dataShare,
        visibilityText: { ...VISIBILITY_OPTIONS_TEXT[dataShare.visibility] },
        sourceFields: dataShare.source_field_ids
          .map((sourceFieldId) =>
            sourcesStore.getSourceFieldById(sourceFieldId),
          )
          .filter((sourceField) => !!sourceField)
          .sort(),
      };
    })[0];
  const noPopulationSettingDefault = {
    dataShare: { visibility: HIDDEN },
    visibilityText: { ...VISIBILITY_OPTIONS_TEXT.hidden },
  };
  return populationSetting || noPopulationSettingDefault;
});

/* Feed and Source Change Logic */
const selectedFeedId = ref(null);
const oldSelectedFeedId = ref(null);
const selectedFeed = computed(() =>
  selectedFeedId.value
    ? feeds.value.find((feed) => feed.id === selectedFeedId.value)
    : null,
);
const feedOptions = computed(() => {
  const options = feeds.value
    .filter((feed) => feed.data?.status !== DELETING_FEED_STATUS)
    .map((feed) => {
      const newFeed = {
        id: feed.id,
        label: feed.integration.friendly_name,
        name: feed.integration.friendly_name,
        value: feed.id,
        data: feed,
        svg: getDataSourceSvg(feed),
        integrationType: feed.integration.type,
      };
      if (hasMultiSelectSalesforce.value) {
        const nickname = feed.nickname
          ? feed.nickname
          : returnTrimmedDomain(feed.external_base_url);
        newFeed.nickname = nickname;
      }
      return newFeed;
    })
    .sort(sortByKey('label'));

  options.push({
    label: 'Add New Data Source',
    value: ADD_NEW_SOURCE,
    icon: ['fak', 'add'],
    iconColor: 'text-neutral-text-weak',
  });
  return options;
});
function getDataSourceSvg(data) {
  let name = toRaw(data.integration).type;
  if (name === 'hubspot_v3') name = 'hubspot';
  return `${name}Icon`;
}

async function onFeedChange(feedId, oldFeedId) {
  if (feedId === ADD_NEW_SOURCE)
    return await router.push({ name: 'data-sources' });
  if (feedId === oldFeedId) return;

  oldPopulation.value = population.value;
  oldSelectedFeedId.value = oldFeedId || feedId;

  /* Set the new source to the first available source, or to null */
  const sourceName =
    relevantSources.value.length === 1
      ? relevantSources.value.at(0)?.name
      : null;
  onSourceChange(sourceName, selectedSource.value, true);
}

function updatePopulation(data) {
  population.value = {
    ...emptyPopulation({}),
    ...data,
    id: props.populationId,
    name: populationName.value || null,
    description: population.value.description || null,
  };
}

/* Source selection logic */
const selectedSource = ref(null);
const oldSelectedSource = ref(null);
const sourceSelectionLabel = computed(() => {
  const isCsv =
    population.value?.base_schema === FILE_UPLOAD_DATA_SOURCE_TYPE ||
    isCSVSelected.value;
  if (isCsv && props.populationId) return 'CSV'; /* Pre-existing population */
  return isCsv ? 'Select CSV' : 'Object';
});
const sourceOptions = computed(() =>
  relevantSources.value.map((t) => ({ value: t.name, label: t.name })),
);
async function onSourceChange(newName, oldName) {
  selectedSource.value = newName;
  oldSelectedSource.value = oldName;

  const source = sources.value
    .filter(
      (source) =>
        source.feed_id === selectedFeedId.value &&
        source.table === newName &&
        source.is_base_table,
    )
    ?.at(0);

  selectedSourceId.value = source?.id;

  updatePopulation({
    base_schema: selectedFeed.value.schema_name,
    base_table: source?.table,
    source_id: source?.id,
    name: populationName.value,
    source,
  });

  /* If there was an old filter, prompt for confirmation and prepare to revert using old values */
  if (oldSelectedSource.value && filterGroupList.value.length > 0) {
    showConfirmDataSourceChangeModal.value = true;
    return;
  }

  /* If there was not an old filter, reset the filter data too right away */
  await resetFilterData();
}

/* Preview population */
const hasUserRunPopulation = ref(false);
const data = ref(INITIAL_DATA);
const populationPreviewCount = ref(0);
const populationPreviewCountTimeout = ref(false);
async function onPreviewPopulation() {
  loadingCount.value = true;
  loadingData.value = true;
  const isValidSource =
    population.value.base_schema && population.value.base_table;
  if (!isValidSource) {
    data.value = INITIAL_DATA;
    loadingCount.value = false;
    loadingData.value = false;
    return;
  }
  data.value = await previewPopulation(
    population.value,
    filterGroupList.value,
    offlinePartnerUuid.value,
  );
  loadingData.value = false;
  hasUserRunPopulation.value = true;
  emit('has-user-run-population', true);
  isEditMode.value = false;
  const { count, timeout } = await previewPopulationCount(
    population.value,
    filterGroupList.value,
    offlinePartnerUuid.value,
  );
  populationPreviewCount.value = count;
  populationPreviewCountTimeout.value = timeout;
  loadingCount.value = false;
}

/* Changing Source Modal */
const showConfirmDataSourceChangeModal = ref(false);
const showEmptyFiltersModal = ref(false);
const filtersEmpty = computed(() => !filterGroupList.value.length);
const oldPopulation = ref(null);
async function confirmChangeDataSource() {
  await setFormData();
  await resetFilterData();
  closeDataSourceChangeModal();
  nextTick(() => {
    canUserAddFilterGroup.value = true;
  });
}

async function resetFilterData() {
  filterGroupList.value = [];

  if (!population.value?.source_id) return;

  const { data, error } = await crossbeamApi.GET(
    '/v0.2/sources/{id}/filterable-sources',
    {
      params: {
        path: { id: population.value.source_id },
        ...(offlinePartnerUuid.value && {
          query: {
            offline_partner_org_uuid: offlinePartnerUuid.value,
          },
        }),
      },
    },
  );
  if (error) {
    flashesStore.addUnhandledError(new Error(error));
    return;
  }
  const forbiddenMdmTypes = [MDM_TYPES.DEAL_CONTACT];
  completeFilterSources.value = uniqBy(data.items, 'id').filter(
    (source) => !forbiddenMdmTypes.includes(source.mdm_type),
  );
}

/* Offline Partner Logic */
const offlinePartner = computed(() =>
  offlinePartnerId.value
    ? partnersStore.getPartnerOrgById(offlinePartnerId.value)
    : null,
);

function revertChangeDataSource() {
  selectedSource.value = oldSelectedSource.value;
  selectedFeedId.value = oldSelectedFeedId.value;
  population.value = oldPopulation.value;
  closeDataSourceChangeModal();
}

function closeDataSourceChangeModal() {
  showConfirmDataSourceChangeModal.value = false;
}

/* Saving and Running the Population */
async function onSavePopulation() {
  loadingCount.value = true;
  loadingData.value = true;
  try {
    population.value = await savePopulation(
      {
        ...population.value,
        name:
          populationName.value &&
          isPopulationNameValid.value &&
          !isCustomPopulationNameStandard.value
            ? populationName.value
            : population.value.name,
      },
      payload.value,
    );

    originalPayload.value = JSON.stringify(payload.value);

    await populationsStore.refreshPopulationsStore();
    if (offlinePartnerUuid.value) {
      if (!isUserEditing.value)
        await setFullVisibility(offlinePartnerUuid.value);
      await refreshOfflineData(offlinePartnerUuid.value);
      await dataSharesStore.refreshDataSharesStore();
    }

    populationHasChanged.value = false;
    emit('population-change', false);

    /* New population for non-offline partners */
    if (!isUserEditing.value && !offlinePartnerUuid.value) {
      await router.push({
        name: 'edit_population__sharing',
        params: {
          population_id: population.value.id,
        },
        query: {
          ...route.query,
          toEdit: true,
          newPopulation: true,
        },
      });
      return;
    }

    /* New population for offline partner */
    if (!isUserEditing.value) {
      await router.push({
        name: 'partner_details',
        params: { partner_org_id: offlinePartnerId.value },
        query: { tab: 'data' },
      });
    }
  } catch (err) {
    flashesStore.addErrorFlash({ message: 'Could not save population' });
    captureException(err);
  } finally {
    loadingCount.value = false;
    loadingData.value = false;
  }
}

function onRunPopulation() {
  // simulate "filter-clickaway" event which updates filterGroupList state
  const body = document.querySelector('body');
  body.click();
  nextTick(async () => {
    await onPreviewPopulation();
  });
}

async function onSaveAndRun() {
  loadingCount.value = true;
  loadingData.value = true;
  await onPreviewPopulation();
  await onSavePopulation();
  loadingCount.value = false;
  loadingData.value = false;
}

async function onSaveAndRunClick() {
  if (hasPopulationFilterModal.value && filtersEmpty.value) {
    showEmptyFiltersModal.value = true;
    return;
  }
  await onSaveAndRun();
}

async function onSaveEmptyFilters() {
  showEmptyFiltersModal.value = false;
  await onSaveAndRun();
}

function onCloseEmptyFilters() {
  showEmptyFiltersModal.value = false;
}

/* Offline Partner Sharing Settings */
async function setFullVisibility(offlineUuid) {
  try {
    const relevantSource = offlineSources.value.find(
      (source) => source.id === population.value.source_id,
    );

    await refreshOfflineData(offlinePartnerUuid.value);

    const sourceFieldIds = relevantSource.fields
      .filter((f) => f.is_visible)
      .map((f) => f.id);

    const filterableSources = offlineFilterableSources.value.filter(
      (source) => source.id === population.value.source_id,
    );

    const filterableSourceFieldIds = filterableSources.reduce((acc, source) => {
      const sourceFieldIds = source['filterable-sources'].reduce(
        (fieldIds, filterableSource) => {
          const ids = filterableSource.fields
            .filter((f) => f.is_visible)
            .map((f) => f.id);

          return [...ids, ...fieldIds];
        },
        [],
      );
      return [...sourceFieldIds, ...acc];
    }, []);

    const payload = {
      rule_type: PARTNER_RULE,
      partner_org_id: currentOrg.value.id,
      population_id: population.value.id,
      visibility: GREENFIELD_SHARING,
      source_field_ids: [...filterableSourceFieldIds, ...sourceFieldIds],
      offline_partner_org_uuid: offlineUuid,
    };
    await axios.post(urls.dataShares.outgoingRules.default, payload);
    await populationsStore.refreshPopulationsStore();
  } catch (err) {
    /* This should never occur... */
    captureException(err);
    flashesStore.addErrorFlash('Could not configure sharing rules');
  }
}

/* Router Handlers */
async function goToEditSharing() {
  await router.push({
    name: 'edit_population__sharing',
    params: {
      is_next_button_disabled: true,
    },
    query: {
      ...route.query,
      toEdit: true,
    },
  });
}

async function redirectToDatasource() {
  await router.push({ path: '/data-sources' });
}

/* Other garbage */
const isUpdatingSources = ref(false);
async function onCheckSources() {
  isUpdatingSources.value = true;
  setTimeout(() => {
    /* Delaying this to give better visual feedback to the user. Putting before the dispatches to allow the ui to finish the animation in case of error */
    isUpdatingSources.value = false;
  }, 500);
  if (!feedProcessed.value) {
    await feedsStore.refreshFeedsStore();
  }
  if (
    selectedFeed.value &&
    sourcesStore.getSourcesByFeedId(selectedFeed.value.id)?.length === 0
  ) {
    await sourcesStore.refreshSourcesStore();
  }
}

const recordType = computed(() => {
  if (population.value && population.value.population_type) {
    return (
      population.value.population_type[0].toUpperCase() +
      population.value.population_type.slice(1)
    );
  }
  return 'Records';
});
const populationSchemaName = computed(() => {
  if (
    population.value?.base_schema === FILE_UPLOAD_DATA_SOURCE_TYPE ||
    isCSVSelected.value ||
    offlinePartnerUuid.value
  )
    return 'CSV';
  if (population.value?.base_schema) {
    // the first word before _ is the source name
    // so in prod we'd have salesforce_[some random letters denoting schema]
    // this would return 'Salesforce' for the above, which is what we want
    const sourceName = population.value.base_schema.split('_')[0];
    return sourceName[0].toUpperCase() + sourceName.slice(1);
  }
  return '';
});

const relevantSources = computed(() => {
  if (!selectedFeed.value) return [];
  let sourcesWithSyncEnabled = sources.value
    .filter(
      (source) =>
        (source.feed_id === selectedFeedId.value ||
          source.schema === selectedFeed.value.schema_name) &&
        source.is_base_table,
    )
    .map((source) => ({
      ...source,
      name: source.table,
    }))
    .filter((source) => source.sync_enabled)
    .sort(sortByKey('name'));

  if (isCSVSelected.value) {
    sourcesWithSyncEnabled = sourcesWithSyncEnabled
      .filter((source) => currentUploadNames.value.includes(source.table))
      .map((source) => {
        const isDisabledAndLoading =
          SOURCE_STATUS_MAP.process.includes(source.status) ||
          SOURCE_STATUS_MAP.deleting.includes(source.status);
        return {
          ...source,
          $isDisabled: isDisabledAndLoading,
          loading: isDisabledAndLoading,
        };
      });
  }
  return sourcesWithSyncEnabled;
});

const { numAlerts } = useAlerts();
const topOffset = computed(() => {
  return `${92 + numAlerts.value * 40}px`;
});

const populationHasChanged = ref(false);
const payload = computed(() =>
  makePopulationPayload(
    population.value,
    populationName.value,
    filterGroupList.value,
    queryStandardType.value || population.value?.standard_type,
  ),
);
const valuePayload = computed(() => JSON.stringify(payload.value));
const originalPayload = ref(
  JSON.stringify(
    makePopulationPayload(
      population.value,
      populationName.value,
      filterGroupList.value,
      queryStandardType.value || population.value?.standard_type,
    ),
  ),
);

watch(valuePayload, (newPayload, oldPayload) => {
  if (!population.value.name || loadingInitialState.value) return;
  if (newPayload === oldPayload || newPayload === originalPayload.value) return;
  populationHasChanged.value = true;
  emit('population-change', true);
});

/* Warning and Processing State */
const csvSourcesStillProcessing = computed(() =>
  relevantSources.value.some((source) =>
    SOURCE_STATUS_MAP.process.includes(source.status),
  ),
);
const isCSVSelected = computed(
  () => selectedFeed.value?.integration?.type === FILE_UPLOAD_DATA_SOURCE_TYPE,
);
const feedProcessed = computed(() => selectedFeed.value?.initial_sync_complete);
const feedNotReady = computed(() => !feedProcessed.value || isError.value);
const csvNotReady = computed(
  () => isCSVSelected.value && csvSourcesStillProcessing.value,
);
const isError = computed(
  () =>
    ![...COMPLETED_STATUSES, ...WARNING_STATUSES].includes(
      selectedFeed.value?.status,
    ),
);
const sourcesDeleting = computed(() =>
  relevantSources.value.some((source) =>
    SOURCE_STATUS_MAP.deleting.includes(source.status),
  ),
);
const processingWarning = computed(() => {
  let message;
  if (sourcesDeleting.value) {
    message = 'Some sources are being deleted';
  } else if (isCSVSelected.value && feedProcessed.value) {
    message = 'Some CSVs are still processing';
  } else if (isError.value) {
    message = `Can't connect to ${selectedFeed.value?.integration?.friendly_name}`;
  } else {
    message = 'Data source is still processing';
  }
  return {
    show:
      !!selectedFeed.value &&
      (feedNotReady.value || csvNotReady.value || sourcesDeleting.value),
    isError: isError.value,
    message,
  };
});

const sourcesCouldChange = computed(() => {
  return (
    !!selectedFeed.value &&
    (sourcesDeleting.value ||
      (feedProcessed.value && isCSVSelected.value && csvNotReady.value))
  );
});

watch(sourcesCouldChange, () => {
  if (sourcesCouldChange.value && !sourcesPolling.value)
    sourcesStore.startPolling();
});

onBeforeUnmount(() => {
  sourcesStore.stopPolling();
});

/* This method may be called by the parent when someone leaves without saving */
defineExpose({ onSavePopulation });
</script>

<style lang="pcss" scoped>
.population-detail {
  @apply flex h-full w-full;
}

.population-detail__error {
  @apply text-sm text-danger-text mb-24;
}

.population-detail__salesforce-url {
  @apply text-neutral-text-weak text-sm pr-4 text-right inline-block truncate w-full;
}

.population-detail__subtitle {
  @apply text-xs text-neutral-500 uppercase tracking-wider mb-8;
}

/* Sidebar */
.sidebar {
  /* Necessary to avoid having the sidebar scroll under the top navigation */
  @apply border-neutral-200 bg-white w-[380px] sticky h-full;
  top: v-bind(topOffset);
  height: calc(100vh - v-bind(topOffset) - 48px);
  display: flex;
  flex-direction: column;
}

.population-detail__sidebar-content {
  @apply flex flex-col justify-between h-full;

  .population-detail__sidebar-section {
    @apply flex-1 mt-[-2px] border-neutral-background-disabled border-t-2;
  }
}
</style>

<style lang="pcss">
.population-detail {
  .bitts-overflow-menu,
  .sharing-setting__popover {
    @apply max-w-[285px];
  }
  .multiselect__tags {
    @apply border-2 border-solid border-neutral-200;
  }

  .multiselect__content-wrapper {
    @apply border-2 border-solid border-neutral-200 rounded-bts-base mt-4;
  }

  .multiselect__single {
    @apply text-base p-0;
  }

  .multiselect__input {
    font-size: 14px !important;
    padding: 0 !important;
    margin-left: 8px;
  }

  .multiselect__placeholder {
    margin: 0 !important;
    padding: 0 !important;
  }

  .multiselect__tag {
    @apply inline-flex items-center rounded-bts-lg text-neutral-800 border
    border-blue-100 text-sm bg-neutral-100;
    margin-bottom: 8px !important;
  }

  .multiselect__tag-icon {
    font-weight: 300;
  }

  .multiselect__tag-icon:hover {
    background: none;
  }

  .multiselect__tag-icon:after {
    font-size: 18px;
    @apply text-brand-blue;
  }

  .multiselect__tag-icon:hover:after {
    @apply text-brand-blue;
  }

  .multiselect__option {
    @apply rounded-none;
    padding: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    @apply text-neutral-800 text-base font-normal;

    span:not(.ant-checkbox-inner):not(.ant-checkbox) {
      padding: 8px;
    }
  }

  .multiselect__option--highlight {
    @apply bg-primary-background-weak text-neutral-800;

    &::after {
      display: none;
    }
  }

  .multiselect__option--selected {
    @apply text-neutral-800;
    background: none !important;

    &.multiselect__option--highlight {
      @apply text-neutral-800;

      &:hover {
        @apply text-neutral-800;
      }
    }

    &::after {
      display: none;
    }
  }
  .refresh-sources-link {
    @apply underline cursor-pointer;
  }
}
</style>
