<template>
  <div class="c-partners-table-cell-wrapper">
    <div class="overlaps-table-partner-cell">
      <BittsAvatar
        v-if="org"
        :org="org"
        :show-initials="true"
        size="medium"
        shape="square"
        class="mr-12"
      />
      <div>
        <p class="overlaps-table-partner-cell__name">
          {{ name }}
        </p>
        <p class="overlaps-table-partner-cell__domain">
          {{ org?.domain }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BittsAvatar } from '@crossbeam/bitts';
import { Nullable } from '@crossbeam/types';

import { ICellRendererParams } from '@ag-grid-community/core';
import { onMounted, ref } from 'vue';

import { Partner } from '@/types/partners';

const { params } = defineProps<{ params: ICellRendererParams }>();

const name = ref<Nullable<string>>(null);
const org = ref<Nullable<Partner>>(null);

onMounted(() => {
  org.value = params.data.org;
  name.value = params.data.name;
});
</script>

<style lang="pcss">
.overlaps-table-partner-cell {
  @apply flex items-center my-12;
}
.overlaps-table-partner-cell__domain {
  @apply text-base text-neutral-text;
}
.overlaps-table-partner-cell__name {
  @apply text-m text-neutral-text-strong text-left;
}
</style>
