<template>
  <BittsModalTwo
    :width="1400"
    :loading="sourceLoading || loading"
    :use-mask-to-close="true"
    class="c-create-population_modal"
    @closed="close"
    @action="handleAction"
    :disabled="saving || loading"
    :action-loading="saving"
  >
    <template #header>
      <div class="c-create-population_header">
        <h3 class="c-create-population_title"> {{ title }}</h3>
        <BittsTag
          v-if="recordCount !== null"
          color="secondary"
          size="small"
          variant="rounded"
        >
          {{ `${recordCount} ${pluralize(recordCount, 'record')}` }}
        </BittsTag>
      </div>
    </template>
    <div>
      <div class="mb-16">
        We recommend to include all your customers without exception
      </div>
      <div
        :key="`filter-group__${oId}`"
        v-for="(oId, oIndex) in filterGroupIds"
      >
        <FilterGroup
          v-if="filterGroups[oId]"
          class="c-create-population_filter-group"
          :class="getFilterGroupClass(oIndex, filterGroupIds.length)"
          :source="filterGroups[oId].source"
          :filters="filterGroups[oId].filters"
          @add-filter="addFilterToGroup"
          @group-deleted="deleteGroup"
          @remove-filter="deleteFilterFromGroup"
          @change-filter="updateFilter"
        >
          <template #title="{ source, isUpload }">
            <div class="c-create-population_filter-group_title">
              {{ source.table }}
              <div class="c-create-population_filter-group_info">
                &nbsp;&#183; {{ filterGroupTitle(source, isUpload) }}
              </div>
            </div>
          </template>
        </FilterGroup>
        <BittsDivider
          v-if="oIndex < filterGroupIds.length - 1"
          text="And"
          class="c-create-population__group-divider"
        />
      </div>
      <NewObjectFilter
        :show-button="canAddFilterGroup"
        :is-own="true"
        :sources="remainingSources"
        @filter-group-added="addNewFilterGroup"
        class="c-create-population__add-group"
      />
    </div>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import { BittsDivider, BittsModalTwo, BittsTag } from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import FilterGroup from '@/components/filters/FilterGroup.vue';
import NewObjectFilter from '@/components/reports/NewObjectFilter.vue';

import useDataSourceSettings from '@/composables/useDataSourceSettings';
import useFeeds from '@/composables/useFeeds';
import { Source } from '@/types/sources';

import usePopulationModal from '../composables/usePopulationModal';

type Props = {
  feedId: number;
  cancelDestination: string;
};

const { feedId, cancelDestination } = defineProps<Props>();

const router = useRouter();
const route = useRoute();
const { normalizeSchemaName } = useFeeds();
const { loading: sourceLoading, saving } = useDataSourceSettings();

const {
  remainingSources,
  filterGroupIds,
  filterGroups,
  recordCount,
  populationTypeName,
  loading,
  addNewFilterGroup,
  addFilterToGroup,
  deleteGroup,
  deleteFilterFromGroup,
  updateFilter,
} = usePopulationModal({ feedId });

const populationId = computed(() => route.params.population_id);

const title = computed(
  () =>
    `${populationId.value ? 'Edit' : 'Create'} "${populationTypeName.value}" Population`,
);

const canAddFilterGroup = computed(() => !!remainingSources.value.length);

async function close() {
  await router.push({ path: cancelDestination });
}
async function handleAction() {
  await console.log('Action');
}

function filterGroupTitle(source: Source, isUpload: boolean) {
  const { schema, table } = source;
  if (isUpload) return `All fields from the ${table} upload`;
  const normalizedSchemaName = normalizeSchemaName(schema);
  return `All fields from the ${normalizedSchemaName} ${table} table`;
}

function getFilterGroupClass(index: number, length: number) {
  const isLast = index === length - 1;
  const isFirst = !index;
  return {
    'border-t rounded-t-bts-md': isFirst,
    'border-b rounded-b-bts-md': isLast,
    'pb-24': isFirst && length > 1,
  };
}
</script>

<style lang="pcss">
.c-create-population_modal.o-bitts-modal.bitts-modal--primary {
  .o-bitts-modal__header {
    @apply p-24 flex-row border-b border-neutral-border;
  }
  .o-bitts-modal__content {
    @apply p-0 mt-20;
  }
  dialog {
    @apply overflow-visible;
  }
}
</style>

<style lang="pcss" scoped>
.c-create-population_header {
  @apply flex gap-8;
}
.c-create-population_title {
  @apply text-left text-neutral-text-strong font-bold text-lg text-center w-full;
}
.c-create-population__content {
  @apply flex flex-col;
}
.c-create-population_filter-group {
  @apply border-l border-r border-neutral-border flex flex-col items-stretch;
}
.c-create-population__group-divider {
  @apply pb-8 my-0 border-neutral-border border-l border-r;
}
.c-create-population__add-group {
  @apply mt-16;
}
.c-create-population_filter-group_info {
  @apply text-neutral-text-weak font-normal;
}
.c-create-population_filter-group_title {
  @apply text-neutral-text font-bold flex items-center text-sm;
}
</style>
