import { Nullable } from '@crossbeam/types';

import axios from 'axios';
import { differenceBy } from 'lodash';
import { HttpResponse, delay } from 'msw';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { computed, onBeforeMount, ref } from 'vue';

import { crossbeamApi } from '@/api';
import { captureException } from '@/errors';
import { enableMocks, typedHttp, worker } from '@/mocks';
import { useFeedsStore, useFlashesStore, useSourcesStore } from '@/stores';
import {
  NewExistingPopulationPrefix,
  OperandOption,
  SelectedOperand,
  SourceOption,
} from '@/types/business_settings';
import urls from '@/urls';

type BusinessSettingDefinition = {
  name: string;
  source_id: number;
  source_filter_expressions: Record<
    string,
    {
      filter_expression: string[];
      filter_parts: {
        source_field_id: number;
        label: string;
        operator: string;
        operand: string[];
      }[];
    }
  >;
};

type OperandsLookup = {
  newBusiness: SelectedOperand;
  existingBusiness: SelectedOperand;
};

export default function useBusinessSettings() {
  if (enableMocks) {
    worker.use(
      // @ts-expect-error - we don't have this route in OAS yet
      typedHttp.put('/v0.1/opportunity-filters', () => HttpResponse.json({})),
      // @ts-expect-error - we don't have this route in OAS yet
      typedHttp.get('/v0.1/opportunity-filters', async () => {
        await delay(1000);
        return HttpResponse.json([
          {
            name: 'xb:net-new-business-11',
            source_id: 61,
            source_filter_expressions: {
              Opportunity: {
                filter_expression: ['c182dedf-a2c3-4111-8c92-67a1c4e90dba'],
                filter_parts: [
                  {
                    source_field_id: 2627,
                    label: 'c182dedf-a2c3-4111-8c92-67a1c4e90dba',
                    operator: 'in',
                    operand: ['New Customer'],
                  },
                ],
              },
            },
          },
          {
            name: 'xb:recurring-business-11',
            source_id: 61,
            source_filter_expressions: {
              Opportunity: {
                filter_expression: ['ee62cb00-2e00-4a48-b53f-c5ccd95c3cbb'],
                filter_parts: [
                  {
                    source_field_id: 2627,
                    label: 'ee62cb00-2e00-4a48-b53f-c5ccd95c3cbb',
                    operator: 'in',
                    operand: [
                      'Existing Customer - Replacement',
                      'Existing Customer - Upgrade',
                    ],
                  },
                ],
              },
            },
          },
        ]);
      }),
    );
  }
  const sourcesStore = useSourcesStore();

  const loading = ref(false);
  const isOperandsLoading = ref(false);
  const selectedFeedId = ref<Nullable<number>>(null);
  const selectedOpportunityFieldId = ref<Nullable<number>>(null);

  const selectedOperandsLookup = ref<OperandsLookup>({
    newBusiness: [],
    existingBusiness: [],
  });
  const opportunityFieldOptions = ref<SourceOption[]>([]);
  const operandOptions = ref<OperandOption[]>([]); // operands are the values of the selected source field

  function resetOperands() {
    selectedOperandsLookup.value.newBusiness = [];
    selectedOperandsLookup.value.existingBusiness = [];
  }

  function resetAll() {
    resetOperands();
    selectedOpportunityFieldId.value = null;
    opportunityFieldOptions.value = [];
    operandOptions.value = [];
  }

  function filterOperandOptions(
    excludedValues: string[],
    options: OperandOption[],
  ) {
    return differenceBy(
      options,
      excludedValues.map((val) => ({ value: val })),
      'value',
    );
  }

  const isDataSourceInputDisabled = computed(() => crmFeeds.value.length === 1);

  const isMultiSelect = computed(
    () => selectedSourceField.value?.dataType === 'string',
  );

  const newBusinessOptions = computed(() =>
    isMultiSelect.value
      ? filterOperandOptions(
          selectedOperandsLookup.value.existingBusiness as string[],
          operandOptions.value,
        )
      : operandOptions.value,
  );

  const recurringBusinessOptions = computed(() =>
    isMultiSelect.value
      ? filterOperandOptions(
          selectedOperandsLookup.value.newBusiness as string[],
          operandOptions.value,
        )
      : operandOptions.value,
  );
  const selectedSourceField = computed(() =>
    opportunityFieldOptions.value.find(
      (field) => field.value === selectedOpportunityFieldId.value,
    ),
  );

  function getDataSourceSvg(integrationType: string) {
    let name = integrationType;
    if (name === 'hubspot_v3') name = 'hubspot';
    return `${name}Icon`;
  }

  const feedsStore = useFeedsStore();
  const { crmFeeds } = storeToRefs(feedsStore);
  const feedOptions = computed(() =>
    crmFeeds.value.map((feed) => ({
      id: feed.id,
      label: feed.integration.friendly_name,
      name: feed.integration.friendly_name,
      value: feed.id,
      svg: getDataSourceSvg(feed.integration.type),
    })),
  );

  const dealSourceFromSelectedFeed = computed(() =>
    sourcesStore.dealSources.find((source) => {
      return source.feed_id === selectedFeedId.value;
    }),
  );

  const isValidSettings = computed(() => {
    return (
      !!selectedFeedId.value &&
      !!selectedOpportunityFieldId.value &&
      selectedOperandsLookup.value.newBusiness.length > 0 &&
      selectedOperandsLookup.value.existingBusiness.length > 0
    );
  });

  const createFilterParts = (label: string, operand: SelectedOperand) => [
    {
      source_field_id: selectedOpportunityFieldId.value,
      label,
      operator: 'in',
      operand,
    },
  ];

  const createPayloadItem = (
    nameSuffix: NewExistingPopulationPrefix,
    uuid: string,
    operand: SelectedOperand,
  ) => ({
    name: `${nameSuffix}-${selectedFeedId.value}`,
    source_id: dealSourceFromSelectedFeed.value?.id,
    source_filter_expressions: {
      [`${dealSourceFromSelectedFeed.value?.table}`]: {
        filter_expression: [uuid],
        filter_parts: createFilterParts(uuid, operand),
      },
    },
  });

  function buildPayload() {
    const newBusinessUUID = uuidv4();
    const recurringBusinessUUID = uuidv4();

    return [
      createPayloadItem(
        'xb:net-new-business',
        newBusinessUUID,
        selectedOperandsLookup.value.newBusiness,
      ),
      createPayloadItem(
        'xb:recurring-business',
        recurringBusinessUUID,
        selectedOperandsLookup.value.existingBusiness,
      ),
    ];
  }

  const { addErrorFlash, addSuccessFlash } = useFlashesStore();
  async function saveSettings() {
    if (!isValidSettings.value) return;

    try {
      const payload = buildPayload();

      // @ts-expect-error - this route is not defined in OAS yet
      const { error } = await crossbeamApi.PUT('/v0.1/opportunity-filters', {
        body: payload,
      });

      if (error) {
        throw error;
      }

      addSuccessFlash({ message: 'Settings successfully saved' });
    } catch (error) {
      captureException(error);
      addErrorFlash({ message: 'Failed to save settings' });
    }
  }

  async function fetchFilterableSources(sourceId: number) {
    const { data, error } = await crossbeamApi.GET(
      '/v0.2/sources/{id}/filterable-sources',
      { params: { path: { id: sourceId } } },
    );

    if (error) {
      captureException(error);
      return [];
    }

    const dealSource = data?.items.find((source) => source.mdm_type === 'deal');
    return (
      dealSource?.fields
        ?.filter((field) =>
          ['boolean', 'string'].includes(field.data_type as string),
        )
        .map((field) => ({
          label: field.display_name,
          value: field.id as number,
          dataType: field.data_type as 'string' | 'boolean',
        })) ?? []
    );
  }

  async function fetchOperandOptions(fieldId: number) {
    const selectedField = opportunityFieldOptions.value.find(
      (field) => field.value === fieldId,
    );
    if (selectedField?.dataType === 'boolean') {
      return [
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' },
      ];
    }
    try {
      isOperandsLoading.value = true;
      const { data } = await axios.post(urls.filterSearch, { field: fieldId });
      return (
        data
          ?.map((label: string) => ({ label, value: label }))
          .sort((a: { label: string }, b: { label: string }) =>
            a.label.localeCompare(b.label),
          ) ?? []
      );
    } catch (err) {
      captureException(err);
      return [];
    } finally {
      isOperandsLoading.value = false;
    }
  }

  function updateOperandsLookup(
    businessSetting: BusinessSettingDefinition | undefined,
    type: 'newBusiness' | 'existingBusiness',
  ) {
    const filter =
      businessSetting?.source_filter_expressions?.Opportunity?.filter_parts[0];

    if (!filter) return;

    selectedOperandsLookup.value[type] = filter.operand ?? [];

    /* same source_field_id on both settings, just grab the first */
    if (type === 'newBusiness') {
      selectedOpportunityFieldId.value = filter.source_field_id ?? null;
    }
  }

  // TODO: cache this response and avoid consequent calls
  onBeforeMount(async () => {
    loading.value = true;
    try {
      // @ts-expect-error - this route is not defined in OAS yet
      const { data, error } = (await crossbeamApi.GET(
        '/v0.1/opportunity-filters',
      )) as {
        data: [BusinessSettingDefinition, BusinessSettingDefinition];
        error: string;
      };

      if (error || !data?.length)
        throw error || new Error('No settings defined');

      const [newBusinessSetting, existingBusinessSetting] = data;

      selectedFeedId.value =
        sourcesStore.getSourceById(newBusinessSetting.source_id)?.feed_id ||
        null;

      updateOperandsLookup(newBusinessSetting, 'newBusiness');
      updateOperandsLookup(existingBusinessSetting, 'existingBusiness');
    } catch (error) {
      captureException(error);
      resetAll();
    } finally {
      loading.value = false;
    }
  });
  return {
    buildPayload,
    selectedFeedId,
    selectedOpportunityFieldId,
    selectedOperandsLookup,
    feedOptions,
    opportunityFieldOptions,
    operandOptions,
    isValidSettings,
    newBusinessOptions,
    recurringBusinessOptions,
    loading,
    saveSettings,
    dealSourceFromSelectedFeed,
    isMultiSelect,
    resetAll,
    resetOperands,
    fetchFilterableSources,
    fetchOperandOptions,
    isDataSourceInputDisabled,
    isOperandsLoading,
  };
}
