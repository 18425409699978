import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { crossbeamApi } from '@/api';
import {
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { captureException } from '@/errors';
import { initStore } from '@/stores/store-utils';
import { Feed } from '@/types/feeds';

export const useFeedsStore = defineStore('Feeds', () => {
  const feeds = ref<Feed[]>([]);

  function setFeeds(f: Feed[]) {
    feeds.value = f.sort((a, b) => {
      if (a.integration.type === b.integration.type) return 0;
      return a.integration.type > b.integration.type ? 1 : -1;
    });
  }

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    const { data } = await crossbeamApi.GET('/v0.1/feeds');
    setFeeds(data?.items ?? []);
  });

  refresh({ useCache: true });

  function getFeedById(id: number) {
    return feeds.value.find((s) => s.id === id);
  }

  function getFeedByDataSourceType(integrationType: string) {
    return feeds.value.find((s) => s.integration.type === integrationType);
  }

  function getFeedsByDataSourceType(integrationType: string) {
    return feeds.value.filter((s) => s.integration.type === integrationType);
  }

  const syncingFeeds = computed(() => feeds.value.filter((s) => s.is_syncing));

  const feedsByIdLookup = computed(() => {
    return feeds.value.reduce(
      (result, feed) => {
        result[feed.id] = feed;
        return result;
      },
      {} as Record<number, Feed>,
    );
  });

  const crmFeeds = computed(() => {
    return feeds.value.filter((feed) => {
      const { type } = feed.integration;
      const isGoogle = type === GOOGLE_SHEETS_DATA_SOURCE_TYPE;
      const isCSV = type === FILE_UPLOAD_DATA_SOURCE_TYPE;
      return !isGoogle && !isCSV;
    });
  });

  const googleFeed = computed(() =>
    feeds.value.find(
      (feed) => feed.integration?.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE,
    ),
  );

  const hasCRM = computed(() => crmFeeds.value.length > 0);
  const hasMultipleCRMs = computed(() => crmFeeds.value.length > 1);
  const unpausedCRMs = computed(() =>
    crmFeeds.value.filter((feed) => !feed.is_paused),
  );

  async function refreshFeed(feedId: number) {
    const { data, error } = await crossbeamApi.GET('/v0.1/feeds/{id}', {
      params: {
        path: {
          id: feedId,
        },
      },
    });

    if (error) throw error;

    const updatedFeeds = feeds.value.filter((f) => f.id !== feedId);
    setFeeds(updatedFeeds);
    updatedFeeds.push(data);
  }
  async function syncFeed(feedId: number) {
    const { data, error } = await crossbeamApi.POST('/v0.1/feeds/{id}/sync', {
      params: {
        path: {
          id: feedId,
        },
      },
    });
    if (error) {
      captureException(error);
      throw error;
    }
    const updatedFeeds = feeds.value.filter((f) => f.id !== feedId);
    updatedFeeds.push(data);
    setFeeds(updatedFeeds);
  }

  async function removeFeed(feedId: number) {
    const { error } = await crossbeamApi.DELETE('/v0.1/feeds/{id}', {
      params: {
        path: {
          id: feedId,
        },
      },
    });

    if (error) {
      captureException(error);
      throw error;
    }

    const index = feeds.value.findIndex((s) => s.id === feedId);
    feeds.value.splice(index, 1);
  }

  function updateFeed(feed: Feed) {
    const index = feeds.value.findIndex((s) => s.id === feed.id);
    feeds.value[index] = feed;
  }

  return {
    error,
    ready,
    readySync,
    running,
    feeds,
    getFeedById,
    getFeedByDataSourceType,
    getFeedsByDataSourceType,
    syncingFeeds,
    feedsByIdLookup,
    crmFeeds,
    googleFeed,
    hasCRM,
    hasMultipleCRMs,
    unpausedCRMs,
    refreshFeedsStore: refresh,
    refreshFeed,
    syncFeed,
    removeFeed,
    updateFeed,
  };
});
