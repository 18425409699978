<template>
  <div class="c-new-and-recurring-business-settings">
    <BittsSelect
      v-model="selectedFeedId"
      :options="feedOptions"
      class="w-260 mb-24"
      :searchable="false"
      form-label="Data Source"
      placeholder="Select data source"
      option-type="svg"
      :disabled="isDataSourceInputDisabled"
      data-testid="data-source-select"
    />
    <BittsLoading
      :is-loading="loading"
      loading-container-class="h-240 w-300"
      :with-transition="false"
    >
      <BittsSelect
        v-if="selectedFeedId && opportunityFieldOptions.length"
        v-model="selectedOpportunityFieldId"
        :options="opportunityFieldOptions"
        class="w-260 mb-24"
        form-label="Opportunity Field"
        placeholder="Select opportunity field"
        data-testid="opportunity-field-select"
      />
      <div class="filter-groups" v-if="operandOptions.length">
        <BusinessSettingsFilterGroup
          v-model="selectedOperandsLookup.newBusiness"
          title="New Business"
          :operand-options="newBusinessOptions"
          :is-multi-select
          :loading="isOperandsLoading"
          class="mb-16"
        />
        <BusinessSettingsFilterGroup
          v-model="selectedOperandsLookup.existingBusiness"
          title="Existing Business"
          :operand-options="recurringBusinessOptions"
          :loading="isOperandsLoading"
          :is-multi-select
        />
      </div>
    </BittsLoading>

    <BittsButton
      text="Save changes"
      size="small"
      data-testid="save-settings-button"
      @click="saveSettings"
      :disabled="!isValidSettings"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsLoading, BittsSelect } from '@crossbeam/bitts';

import { watch, watchEffect } from 'vue';

import useBusinessSettings from '@/composables/useBusinessSettings';
import { useSourcesStore } from '@/stores';

import BusinessSettingsFilterGroup from './BusinessSettingsFilterGroup.vue';

const {
  selectedFeedId,
  selectedOperandsLookup,
  selectedOpportunityFieldId,
  opportunityFieldOptions,
  dealSourceFromSelectedFeed,
  feedOptions,
  operandOptions,
  newBusinessOptions,
  recurringBusinessOptions,
  isValidSettings,
  isDataSourceInputDisabled,
  isMultiSelect,
  isOperandsLoading,
  loading,
  resetAll,
  resetOperands,
  fetchFilterableSources,
  fetchOperandOptions,
  buildPayload, // just for exposing to tests
  saveSettings,
} = useBusinessSettings();

/* set selected feed if only one CRM */
watchEffect(() => {
  if (!selectedFeedId.value && feedOptions.value.length === 1) {
    selectedFeedId.value = feedOptions.value[0]?.id || null;
  }
});

const sourcesStore = useSourcesStore();
/* root input - if this is reset we should hydrate everything */
watch(
  selectedFeedId,
  async (newId, oldId) => {
    await sourcesStore.readySync;

    const dealSourceId = dealSourceFromSelectedFeed.value?.id;
    if (newId === oldId || !dealSourceId) return;

    if (oldId) resetAll();

    opportunityFieldOptions.value = await fetchFilterableSources(dealSourceId);
  },
  { immediate: true },
);

watch(selectedOpportunityFieldId, async (newFieldId, oldFieldId) => {
  if (!newFieldId) return;

  if (newFieldId !== oldFieldId && oldFieldId !== null) resetOperands();

  operandOptions.value = await fetchOperandOptions(newFieldId);
});

defineExpose({
  buildPayload,
});
</script>

<style scoped lang="pcss">
.filter-groups {
  @apply border-l-0 md:border-l border-neutral-border pl-0 md:pl-24 mb-24;
}
</style>
