<template>
  <BittsTable
    :columns="columnDefinitions"
    :compress-columns="true"
    :rows="rows"
    :row-height="72"
    :pagination="false"
    :class="columnsReady ? 'fade-in' : 'invisible'"
    class="overlaps-table"
    @columns-compressed="onColumnsCompressed"
  />
</template>

<script setup lang="ts">
import { BittsTable } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import OverlapsTableOverlapsCell from '@/components/billing/OverlapsTableOverlapsCell.vue';
import OverlapsTablePartnerCell from '@/components/billing/OverlapsTablePartnerCell.vue';

import { usePartnersStore } from '@/stores';
import { Partner } from '@/types/partners';

const { overlapCounts, partnerOrgs } = storeToRefs(usePartnersStore());

const columnsReady = ref(false);

const columnDefinitions = computed(() => {
  return [
    {
      autoHeight: true,
      cellRenderer: OverlapsTablePartnerCell,
      field: 'name',
      headerName: 'Partner',
      sortable: true,
      sort: 'asc',
      suppressMovable: true,
      width: 200,
    },
    {
      autoHeight: true,
      cellRenderer: OverlapsTableOverlapsCell,
      field: 'overlaps',
      headerName: 'Overlaps',
      sortable: true,
      suppressMovable: true,
    },
  ];
});

const partnerOverlapsLookup = computed<Record<number, number> | undefined>(
  () => {
    if (overlapCounts.value.overlap_usage) {
      return overlapCounts.value.overlap_usage.by_partner?.reduce(
        (acc, partner) => {
          acc[partner.partner_organization_id] = partner.shared_overlap_count;
          return acc;
        },
        {} as Record<number, number>,
      );
    }
    return {} as Record<number, number>;
  },
);

type PartnerData = {
  org: Partner;
  name: string;
  overlaps?: number;
};
const rows = computed(() => {
  return partnerOrgs.value.reduce(
    (partners: PartnerData[], partnerOrg: Partner) => {
      if (!partnerOverlapsLookup.value) return partners;
      const partnerData = {
        org: partnerOrg,
        name: partnerOrg.name,
        overlaps: partnerOverlapsLookup.value[partnerOrg.id],
      };
      partners.push(partnerData);
      return partners;
    },
    [],
  );
});

function onColumnsCompressed() {
  columnsReady.value = true;
}
</script>

<style lang="pcss">
.overlaps-table {
  .ag-cell-value {
    @apply w-full h-full;
  }
  .ag-header {
    @apply rounded-t-2xl;
  }
  .ag-row {
    @apply flex items-center;
  }
  .ag-row-last {
    @apply rounded-b-2xl;
  }
  .ag-root-wrapper {
    @apply rounded-2xl;
  }
  .ag-cell-wrapper {
    @apply w-full;
  }
}
</style>
