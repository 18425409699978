<template>
  <BittsModalTwo
    :width="600"
    name="file-uploads-modal-two"
    class="c-file-uploads-two"
    :use-mask-to-close="false"
    @closed="close"
  >
    <template #header>
      <BittsSvg svg="csv" class="c-csv-two__icon" />
      <h2 class="title">
        {{ sourceId ? 'Add to File' : 'Upload CSV File' }}
      </h2>
    </template>
    <BittsLoading :class="{ 'pb-36': loading }" :is-loading="loading">
      <div class="c-file-upload-form">
        <div v-if="submissionError">
          <BittsAlert
            :description="submissionError"
            color="error"
            message="Error"
          />
        </div>
        <div v-if="uploadTabActive">
          <div class="flex items-center justify-between">
            <div class="flex">
              <b class="text-neutral-text-strong">Upload CSV</b>
              <span class="ml-4 text-neutral-text-weak">
                (max {{ maxFileUploadLimit }}mb)
              </span>
            </div>
            <a
              class="download-button"
              href="https://s3.amazonaws.com/assets.crossbeam.com/Crossbeam-Template.csv"
            >
              <span>Download Template</span>
              <FontAwesomeIcon
                class=""
                :icon="['far', 'arrow-down-to-bracket']"
                :style="{ height: '14px', width: '14px' }"
              />
            </a>
          </div>
          <FilePicker
            file-type="text/csv"
            :file-size-limit="maxFileUploadLimit"
            @files-added="addFile"
            @error="resetForm"
          />
          <BittsLoading :is-loading="processingFileMetadata">
            <div v-if="(fileHeaders || processingFileMetadata) && !sourceId">
              <BittsInput
                v-model.trim="tableName"
                class="mt-16"
                form-label="CSV Name"
                data-testid="file-upload-input"
                :status="v$.tableName.$errors.length ? 'danger' : 'default'"
                :danger-text="v$.tableName.$errors?.at(-1)?.$message || ''"
                placeholder="Name your CSV"
                name="file_name"
                caption="Note: This name will be shared with partners when you share data"
                @update:model-value="() => v$.$touch()"
              />
              <BittsRadioGroupCards
                v-if="!offlinePartnerUuid"
                form-label="What type of data is this?"
                :options="uploadTypes"
                class="w-full mt-24"
                :class="{
                  'opacity-50': offlinePartnerUuid,
                }"
                orientation="horizontal"
                :initial-value="selectedUploadType"
                @change="(val) => (selectedUploadType = val)"
              />
            </div>
          </BittsLoading>
          <BittsCallout
            v-if="sourceId"
            class="mt-16"
            title="Adding data to a CSV merges these datasets"
            size="small"
            subtitle="This action can't be undone, make sure you are sure"
            type="warning"
          />
        </div>
        <div class="flex flex-col gap-8" v-else>
          <div class="flex">
            <div class="header w-[296px]">CSV Columns</div>
            <div class="header">Crossbeam Fields</div>
          </div>
          <FieldMapping v-if="showAccountFields" label="Company Name">
            <VuelidateWrapper property="selectedNameField" :errors="v$.$errors">
              <BittsSelect
                v-model="selectedNameField"
                :use-disabled-options="true"
                data-testid="name-selector"
                :options="availableColumns"
                placeholder="Select a field (Required)"
                :allow-clear="true"
              />
            </VuelidateWrapper>
          </FieldMapping>
          <FieldMapping v-if="showAccountFields" label="Company Website">
            <BittsSelect
              v-model="selectedWebsiteField"
              :use-disabled-options="true"
              data-testid="website-selector"
              :options="availableColumns"
              placeholder="Select a field"
              :allow-clear="true"
            />
          </FieldMapping>
          <FieldMapping v-if="showLeadFields" label="Email">
            <VuelidateWrapper
              property="selectedEmailField"
              :errors="v$.$errors"
            >
              <BittsSelect
                v-model="selectedEmailField"
                :use-disabled-options="true"
                :options="availableColumns"
                placeholder="Select a field (Required)"
                :allow-clear="true"
              />
            </VuelidateWrapper>
          </FieldMapping>
          <FieldMapping label="Account Owner Email">
            <VuelidateWrapper
              property="selectedAEEmailField"
              :errors="v$.$errors"
            >
              <BittsSelect
                v-model="selectedAEEmailField"
                :use-disabled-options="true"
                data-testid="ae-email-selector"
                :options="availableColumns"
                placeholder="Select a field"
                :allow-clear="true"
              />
            </VuelidateWrapper>
          </FieldMapping>
          <FieldMapping label="Account Owner Name">
            <BittsSelect
              v-model="selectedAENameField"
              :use-disabled-options="true"
              :options="availableColumns"
              placeholder="Select a field"
              :allow-clear="true"
            />
          </FieldMapping>
          <FieldMapping label="Account Owner Phone">
            <BittsSelect
              v-model="selectedAEPhoneField"
              :use-disabled-options="true"
              :options="availableColumns"
              placeholder="Select a field"
              :allow-clear="true"
            />
          </FieldMapping>
          <FieldMapping
            v-if="
              showAccountFields &&
              featureFlagStore.hasFeatureFlag(DUNS_MATCHING)
            "
            label="DUNS Number"
          >
            <BittsSelect
              v-model="selectedDUNSField"
              :use-disabled-options="true"
              data-testid="duns-selector"
              :options="availableColumns"
              placeholder="Select a field"
              :allow-clear="true"
            />
          </FieldMapping>
        </div>
      </div>
    </BittsLoading>
    <template #footer>
      <BittsButton
        v-if="activeTab === MAP_COLUMNS_TAB"
        text="Back"
        type="neutral"
        variant="outline"
        :left-icon="['fak', 'arrow-left']"
        size="large"
        :disabled="loading"
        :loading="loading"
        @click="back"
      />
      <div class="ml-auto flex gap-8">
        <BittsButton
          text="Close"
          type="neutral"
          variant="outline"
          size="large"
          :disabled="processingFileMetadata || loading"
          :loading="processingFileMetadata || loading"
          @click="close"
        />
        <BittsButton
          v-if="activeTab === ADD_FILE_TAB"
          :disabled="!tab1complete || v$.$errors.length > 0 || loading"
          data-testid="next-button"
          text="Next"
          size="large"
          :loading="processingFileMetadata || loading"
          @click="next"
        />
        <BittsButton
          v-else
          :disabled="disableSubmit || loading"
          :loading="loading"
          data-testid="upload-csv-button"
          text="Upload"
          size="large"
          @click="uploadFile"
        />
      </div>
    </template>
  </BittsModalTwo>
</template>

<script setup>
import {
  BittsAlert,
  BittsButton,
  BittsCallout,
  BittsInput,
  BittsLoading,
  BittsModalTwo,
  BittsRadioGroupCards,
  BittsSelect,
  BittsSvg,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import useVuelidate from '@vuelidate/core';
import { helpers, maxLength, required } from '@vuelidate/validators';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, inject, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import FieldMapping from '@/components/data-sources/FieldMapping.vue';
import FilePicker from '@/components/data-sources/FilePicker.vue';
import VuelidateWrapper from '@/components/VuelidateWrapper.vue';

import useCSV from '@/composables/useCSV';
import useIteratively from '@/composables/useIteratively';
import { DUNS_MATCHING } from '@/constants/feature_flags';
import {
  ACCOUNT_OWNER_EMAIL_DISPLAY,
  ACCOUNT_OWNER_NAME_DISPLAY,
  ACCOUNT_OWNER_PHONE_DISPLAY,
} from '@/constants/mdm';
import { captureException } from '@/errors';
import {
  useFeatureFlagStore,
  useFeedsStore,
  useFileUploadsStore,
  useFlashesStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';

const props = defineProps({
  sourceId: {
    type: Number,
    default: null,
  },
});

const featureFlagStore = useFeatureFlagStore();

const offlineSources = inject('offlineSources', null);
const offlineCurrentUploadNames = inject('offlineCurrentUploadNames', null);
const refreshOfflineData = inject('refreshOfflineData', null);

const route = useRoute();
const offlinePartnerUuid = computed(() => route.query?.offlinePartnerUuid);
const router = useRouter();

const feedsStore = useFeedsStore();
const fileUploadsStore = useFileUploadsStore();
const flashesStore = useFlashesStore();
const sourcesStore = useSourcesStore();
const { sources } = storeToRefs(sourcesStore);

useHead({ title: 'CSV Upload - Crossbeam' });

const { maxFileUploadLimit, processFileMetadata } = useCSV();
const { iteratively } = useIteratively();

const loading = ref(!!offlinePartnerUuid.value);

const selectedUploadType = ref(null);
const tableName = ref(null);
onMounted(async () => {
  try {
    if (offlinePartnerUuid.value && props.sourceId) {
      /* Set these values automatically if it's an offline partner */
      loading.value = true;
      await refreshOfflineData(offlinePartnerUuid.value);
      console.log(route.params);
      console.log(offlineSources.value);
      const source = offlineSources.value.find(
        (s) => s.id === Number(route.params?.id),
      );
      tableName.value = source.table;
      selectedUploadType.value = source.mdm_type;
    } else if (props.sourceId) {
      /* Set the table name since we're adding data to a CSV */
      const source = sourcesStore.getSourceById(props.sourceId);
      tableName.value = source.table;
      selectedUploadType.value = source.mdm_type;
    } else {
      /* Just set the default upload typeo otherwise */
      selectedUploadType.value = ACCOUNT;
    }
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash('Could not load data source information');
    await close();
  } finally {
    loading.value = false;
  }
});

/* Upload Types */
const ACCOUNT = 'account';
const LEAD = 'lead';
const uploadTypes = [
  {
    value: ACCOUNT,
    label: 'Companies',
    description: null,
  },
  {
    value: LEAD,
    label: 'People',
    description: null,
  },
];

/* CSV contents */
const submissionError = ref(null);
const fileOptions = ref(null);
const fileHeaders = ref(null);
const fileName = ref(null);

/* Tabs */
const MAP_COLUMNS_TAB = 'map_columns_tab';
const ADD_FILE_TAB = 'add_file';
const activeTab = ref(ADD_FILE_TAB);
const uploadTabActive = computed(() => activeTab.value === ADD_FILE_TAB);
const tab1complete = computed(
  () => fileHeaders.value?.length > 0 && tableName.value,
);
function back() {
  activeTab.value = ADD_FILE_TAB;
}
function next() {
  activeTab.value = MAP_COLUMNS_TAB;
}

const showAccountFields = computed(() => selectedUploadType.value === ACCOUNT);
const showLeadFields = computed(() => selectedUploadType.value === LEAD);
const disableSubmit = computed(() =>
  showLeadFields.value ? !selectedEmailField.value : !selectedNameField.value,
);

/* Field Mapping */
const selectedAEEmailField = ref(null);
const selectedAENameField = ref(null);
const selectedAEPhoneField = ref(null);
const selectedEmailField = ref(null);
const selectedNameField = ref(null);
const selectedWebsiteField = ref(null);
const selectedDUNSField = ref(null);
const selectedColumns = computed(() => [
  selectedNameField.value,
  selectedWebsiteField.value,
  selectedEmailField.value,
  selectedAEEmailField.value,
  selectedAENameField.value,
  selectedAEPhoneField.value,
  selectedDUNSField.value,
]);

const availableColumns = computed(() =>
  fileHeaders.value
    .map((col) => ({
      label: col.label,
      value: col.value,
      disabled: selectedColumns.value.includes(col.value),
    }))
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)),
);

/* Validation */
const alphaNum = (value) => Boolean(!value || value.match(/^[a-zA-Z0-9_ ]+$/));
const rules = computed(() => ({
  tableName: {
    required: helpers.withMessage('A file name is required', required),
    alphaNum: helpers.withMessage(
      'Only letters, numbers, spaces, and underscores are allowed',
      alphaNum,
    ),
    maxLength: helpers.withMessage(
      'Must be no more than 60 characters',
      maxLength(60),
    ),
    isUnique: helpers.withMessage(
      'CSV file name must be unique',
      (value, _) => {
        if (props.sourceId) return true;
        const name = offlinePartnerUuid.value
          ? offlineCurrentUploadNames.value.includes(value)
          : fileUploadsStore.getByCSVName(value || '');
        return Boolean(!name);
      },
    ),
  },
}));
const v$ = useVuelidate(rules, { tableName }, { $lazy: true });

/* Actions */
const processingFileMetadata = ref(false);
const file = ref(null);
async function addFile(files) {
  resetForm();
  try {
    processingFileMetadata.value = true;
    file.value = files[0]; // Limited to 1 file
    const processedData = await processFileMetadata(
      file.value,
      offlinePartnerUuid.value,
    );
    fileOptions.value = processedData.fileOptions;
    fileHeaders.value = processedData.fileHeaders;
    fileName.value = processedData.fileName;
    if (!props.sourceId) tableName.value = processedData.fileName;
    if (props.sourceId) {
      // If adding to an existing CSV, advanced to the next tab automatically
      next();
    }
  } catch (err) {
    captureException(err);
    setError(getErrorText(err));
  } finally {
    processingFileMetadata.value = false;
  }
}

async function close() {
  if (route.query?.cancelDestination) {
    await router.push({ name: route.query.cancelDestination });
    return;
  }
  const offlinePartnerId = route.query?.offlinePartnerId;
  const newRoute = offlinePartnerId
    ? {
        name: 'partner_details',
        params: { partner_org_id: offlinePartnerId },
        query: { tab: 'data' },
      }
    : { name: 'data-sources' };
  await router.push(newRoute);
}

async function uploadFile() {
  try {
    submissionError.value = null;
    loading.value = true;

    /* Build Payload */
    const fieldMappings = {};
    if (showAccountFields.value) {
      fieldMappings[selectedNameField.value] = 'Company Name';
      if (selectedWebsiteField.value)
        fieldMappings[selectedWebsiteField.value] = 'Company Website';
      if (selectedDUNSField.value)
        fieldMappings[selectedDUNSField.value] = 'Company DUNS Number';
    } else {
      fieldMappings[selectedEmailField.value] = 'Email';
    }

    if (selectedAENameField.value)
      fieldMappings[selectedAENameField.value] = ACCOUNT_OWNER_NAME_DISPLAY;
    if (selectedAEEmailField.value)
      fieldMappings[selectedAEEmailField.value] = ACCOUNT_OWNER_EMAIL_DISPLAY;
    if (selectedAEPhoneField.value)
      fieldMappings[selectedAEPhoneField.value] = ACCOUNT_OWNER_PHONE_DISPLAY;

    const baseDetailsPayload = {
      table_name: tableName.value.trim(),
      mdm_type: selectedUploadType.value,
      file_name: fileName.value,
      separator: fileOptions.value.separator,
      quote: fileOptions.value.quote,
      field_mappings: fieldMappings,
    };

    const detailsUrl = urls.fileUploads.jsonUploadV3;
    if (offlinePartnerUuid.value)
      baseDetailsPayload.offline_partner_org_uuid = offlinePartnerUuid.value;

    const upload = await axios.post(detailsUrl, baseDetailsPayload);
    const uploadId = upload.data.id;
    const uploadUrl = upload.data.upload_url;

    await axios.put(uploadUrl, file.value, {
      withCredentials: false,
      headers: { 'Content-Type': 'text/csv' },
      transformRequest: (data, headers) => {
        delete headers['XBeam-Organization'];
        return data;
      },
    });

    if (offlinePartnerUuid.value) {
      await axios.post(urls.fileUploads.processFileUpload(uploadId), {
        offline_partner_org_uuid: offlinePartnerUuid.value,
      });
    } else {
      await axios.post(urls.fileUploads.processFileUpload(uploadId), {});
    }

    const source = sources.value.find(
      (source) =>
        source.schema === 'file_uploads' && source.table === tableName.value,
    );

    if (source) {
      iteratively.userConnectedDataSource({
        data_source_type: 'file_uploads',
        feed_id: source.feed_id.toString(),
        custom_presets: [],
        initial_sync_selection: '',
        event_site: EVENT_SITES.FILE_UPLOAD_MODAL,
      });
    }

    flashesStore.addSuccessFlash(
      'Your CSV has been uploaded and is being processed. This may take a few minutes.',
    );

    if (!offlinePartnerUuid.value) {
      await Promise.all([
        feedsStore.refreshFeedsStore(),
        fileUploadsStore.refreshFileUploadsStore(),
        sourcesStore.refreshSourcesStore(),
      ]);
    } else {
      refreshOfflineData(offlinePartnerUuid.value);
    }
    close();
    if (offlinePartnerUuid.value)
      await refreshOfflineData(offlinePartnerUuid.value);
  } catch (err) {
    captureException(err);
    setError(getErrorText(err));
  }
}

/* Helpers */
function resetForm() {
  submissionError.value = null;
  fileOptions.value = null;
  fileHeaders.value = null;
  if (!props.sourceId) {
    fileName.value = null;
    tableName.value = null;
  }
}

function setError(error) {
  submissionError.value = error;
  loading.value = false;
}

function getErrorText(err) {
  if (
    err.response &&
    err.response.data &&
    err.response.data.errors &&
    err.response.data.errors.length > 0
  ) {
    return err.response.data.errors[0];
  }
  return 'We ran into an error processing your file. Please try again, or contact support.';
}
</script>
<style lang="pcss" scoped>
.title {
  @apply text-neutral-text-strong font-bold text-xl mb-4 px-16 text-center w-full;
}
.c-csv-two__icon {
  @apply w-60 h-60 mb-12 mt-12;
}
.header {
  @apply text-neutral-text-strong font-bold;
}
.download-button {
  @apply text-secondary-accent flex gap-6 text-sm font-bold px-8 py-4 rounded-4 items-center mb-[-2px];
  &:hover {
    @apply bg-neutral-50;
  }
}
</style>
<style lang="pcss">
.c-file-upload-form {
  .ant-select-selection-item > div {
    @apply opacity-100 text-neutral-text !important;
  }
}
</style>
