<template>
  <div class="filter-group">
    <div v-if="filters?.length" class="filter-group__filters">
      <div class="flex items-center justify-between">
        <slot name="title" :source="source" :is-upload="isUpload" />
        <BittsButton
          text="Delete group"
          v-if="canDelete"
          type="danger"
          size="x-small"
          variant="ghost"
          @click="emit('group-deleted', source.id)"
        />
      </div>
      <div v-for="(filter, fIndex) in filters" :key="getKeyName(fIndex)">
        <FilterSegment
          class="filter-group__segment"
          :fields="fields"
          :filter="filter"
          :is-own="isOwn"
          :data-source-type="dataSourceType"
          :position="fIndex"
          :is-quick-filter="isQuickFilter"
          @change-combinator="
            (combinator) => onChangeFilter('combinator', combinator, fIndex)
          "
          @change-operands="
            (operands) => onChangeFilter('operand', operands, fIndex)
          "
          @change-operator="
            (operator) => onChangeFilter('operator', operator, fIndex)
          "
          @change-source-field="
            (id) => onChangeFilter('source_field_id', id, fIndex)
          "
        >
          <template #actions>
            <div class="flex items-center gap-6">
              <BittsTooltip class="remove-segment-button">
                <BittsButton
                  :center-icon="['fak', 'x']"
                  variant="ghost"
                  size="x-small"
                  @click="deleteFilterFromGroup(fIndex)"
                />
                <template #title>Remove rule</template>
              </BittsTooltip>
              <BittsDropdown
                v-if="fIndex === filters.length - 1"
                parent-class="w-[60px]"
                :options="combinatorOptions"
                @selection="addFilterToGroup"
              >
                <template #prompt>
                  <BittsTooltip>
                    <BittsButton
                      size="x-small"
                      variant="outline"
                      :center-icon="['fak', 'add']"
                    />
                    <template #title> Add Rule </template>
                  </BittsTooltip>
                </template>
              </BittsDropdown>
            </div>
          </template>
        </FilterSegment>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsDropdown, BittsTooltip } from '@crossbeam/bitts';

import { orderBy } from 'lodash';
import { computed } from 'vue';

import {
  CombinatorOption,
  FilterType,
} from '@/components/data-sources/SettingsModal/PopulationsTab/types';
import FilterSegment from '@/components/filters/FilterSegment.vue';

import useFeeds from '@/composables/useFeeds';
import { FILE_UPLOAD_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import { Source } from '@/types/sources';

type Props = {
  source: Source;
  filters: FilterType[];
  isQuickFilter?: boolean;
  isOwn?: boolean;
  canDelete?: boolean;
};

const {
  source,
  filters,
  isQuickFilter = false,
  isOwn = true,
  canDelete = true,
} = defineProps<Props>();

const emit = defineEmits([
  'add-filter',
  'group-deleted',
  'remove-filter',
  'change-filter',
]);

const combinatorOptions: CombinatorOption[] = [
  { value: 'AND', display: 'and' },
  { value: 'OR', display: 'or' },
];

const { dataSourceTypeFromSchema } = useFeeds();
const dataSourceType = computed(() => {
  return dataSourceTypeFromSchema(source.schema);
});

const isUpload = computed(
  () => !!source.schema?.includes(FILE_UPLOAD_DATA_SOURCE_TYPE),
);

function addFilterToGroup(option: CombinatorOption) {
  emit('add-filter', source.id, option.value);
}
function deleteFilterFromGroup(index: number) {
  emit('remove-filter', source.id, index);
}
function onChangeFilter(property: string, value: unknown, index: number) {
  emit('change-filter', source.id, index, property, value);
}

const fields = computed(() => {
  const fields = source.fields.map((field) => {
    return {
      value: field.id,
      label: field.display_name,
    };
  });
  return orderBy(fields, 'label');
});

function getKeyName(index: number) {
  if (!isOwn) return `partner-filter__${source.id}__${index}`;
  return `filter__${source.id}__${index}`;
}
</script>

<style scoped lang="pcss">
.filter-group {
  @apply p-12;
}
.filter-group__header {
  @apply flex justify-between items-center;
}
.filter-group__info {
  @apply text-neutral-text-weak font-normal;
}
.filter-group__title {
  @apply text-neutral-text font-bold flex items-center text-sm;
}
.filter-group__filters {
  @apply flex flex-col gap-8;
}
</style>
