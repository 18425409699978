<template>
  <div class="c-roadwork">
    <div class="w-full max-w-[400px]">
      <BittsSvg svg="computer" />
    </div>
    <h1>Switch to your desktop</h1>
    <p>Some features aren't mobile friendly.</p>
    <p>Use a desktop for the best experience.</p>
    <BittsButton
      type="neutral"
      variant="outline"
      size="small"
      text="View Anyway"
      class="mt-16"
      @click="onViewAnyway"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsSvg } from '@crossbeam/bitts';

const emit = defineEmits<(e: 'confirmed') => void>();

function onViewAnyway() {
  emit('confirmed');
}
</script>

<style lang="pcss">
.c-roadwork {
  @apply flex flex-col items-center px-16 py-40;

  h1 {
    @apply mt-24 font-bold text-xl text-neutral-text-strong;
  }

  p {
    @apply text-neutral-text text-m text-center;
  }
}
</style>
