<template>
  <ReportCellWrapper
    class="pl-0 pr-36"
    :to="params.value.reportLink"
    :trial-over="params.value.trialOver"
  >
    <ReportOverflowMenu
      v-if="params.value.hasWritePermissions"
      :errors="params.value.report.status?.errors || []"
      :hide-export="params.value.hideExport"
      :is-on-reports-page="params.value.isOnReportsPage"
      :report="params.value.report"
      :report-type="params.value.reportType"
      :partner-org-ids="partnerOrgIds"
      :trial-over="params.value.trialOver"
    />
  </ReportCellWrapper>
</template>

<script setup lang="ts">
import { ICellRendererParams } from '@ag-grid-community/core';
import { computed } from 'vue';

import ReportCellWrapper from '@/components/reports/ReportCellWrapper.vue';
import ReportOverflowMenu from '@/components/reports/ReportOverflowMenu.vue';

import { Partner } from '@/types/partners';

const { params } = defineProps<{ params: ICellRendererParams }>();

const partnerOrgIds = computed(() => {
  if (!params.value.partnersInReport) return [];
  return params.value.partnersInReport.map((partner: Partner) => partner.id);
});
</script>
