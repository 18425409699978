<template>
  <BittsModal
    class="c-create-a-list-modal"
    :visible="visible"
    :width="modalWidth"
    :save-text="saveButtonText"
    :disabled="buttonDisabled"
    :destroy-on-close="true"
    :show-buttons="!isSuccessModal"
    :loading="!ready"
    :saving="isSaving"
    @closed="closeModal"
    @saved="onSave"
  >
    <template #title>
      <h1 class="title">
        {{ title }}
      </h1>
    </template>

    <template v-if="isCreateListModal && !isSuccessModal" #subtitle>
      <p class="text-neutral-text">
        Create a List of key accounts to collaborate in real-time with your
        partner
      </p>
    </template>

    <template #content>
      <div
        v-if="isCreateListModal && !isSuccessModal"
        class="c-create-a-list-modal__content"
      >
        <div class="c-create-a-list-modal__partner-select">
          <p class="c-create-a-list-modal__partner-select-label">
            Collaborator
          </p>

          <BittsSelect
            v-model="newListState.partnerOrganization"
            data-testid="partner-organizations"
            class="c-create-a-list-modal__organization-select"
            option-type="company"
            placeholder="Select Partner"
            :options="partners"
            :allow-clear="true"
            :prefix-icon="['fak', 'team']"
          />

          <BittsSelect
            v-model="newListState.partnerUser"
            data-testid="partner-organization-users"
            option-type="entity"
            :options="partnerOrganizationUsers"
            :disabled="partnerOrganizationUsers.length === 0"
            placeholder="Select User"
            :allow-clear="true"
            :prefix-icon="['fak', 'profile']"
          />
        </div>

        <div
          data-testid="c-list-modal__create-list__users-section"
          class="mb-24"
        >
          <p class="text-sm text-neutral-text-strong font-bold mb-8">
            List Access
          </p>
          <ListUserPermissionsCard :user="currentUser" :org="currentOrg" />
          <ListUserPermissionsCard
            v-if="newListState.partnerOwner !== undefined"
            :user="newListState.partnerOwner"
            :org="newListState.partnerOrg"
          />
        </div>
        <BittsInput
          v-model="newListState.name"
          :status="errors.name.error ? 'danger' : 'default'"
          :danger-text="errors.name.message"
          name="name-input"
          class="mb-12"
          form-label="List Name"
          :disabled="!newListState.partnerOrganization"
          :placeholder="defaultNamePrefix"
        />
        <div class="c-create-list-modal__description-section">
          <BittsTextArea
            v-model="newListState.description"
            data-testid="list-description-textarea"
            form-label="List Description"
            :status="errors.description.error ? 'danger' : 'default'"
            :danger-text="errors.description.message"
            placeholder="Add a description to help your partner know what to expect when collaborating on this List together"
          />
        </div>

        <BittsCallout
          class="mt-24"
          title="The sharing settings for Lists are independent of your default sharing settings"
          :subtitle="`Therefore, all information included in this List will be shared with your partner. By default, record name, website, and ${ACCOUNT_OWNER_NAME_DISPLAY} are added to the List. You can also share non-overlapping accounts with your partner.`"
          size="small"
          :icon="['fak', 'visible']"
        />
      </div>

      <div v-else-if="isSuccessModal" class="flex flex-col p-16">
        <div class="flex items-center justify-center mb-48">
          <BittsAvatarStack
            :org-list="[currentOrg, newListState.partnerOrg!]"
            size="large"
          />
        </div>
        <div class="mb-8 text-strong-text-strong font-bold text-xl text-center">
          {{ currentOrg.name }} & {{ newListState.partnerOrg?.name }} can now
          collaborate on accounts
        </div>
        <span class="text-neutral-text text-center items-center px-40 mb-40"
          >Your list was shared with your partner. Add accounts to start your
          partnership engines</span
        >
        <BittsButton
          :text="successModalText"
          @click="handleListButtonClicked"
        />
      </div>

      <div v-else>
        <p class="text-neutral-text-strong font-bold mb-8"> Shared List </p>
        <BittsSelect
          v-model="selectedListName"
          :allow-clear="true"
          :options="listsFormatted"
          :dropdown-button="true"
          dropdown-class-name="c-list-modal__list-name-dropdown"
          :list-height="150"
          none-remaining-message="You don’t have any Lists with this partner"
          class="mb-24 c-list-modal__list-name-bitts-select"
          placeholder="Select List"
          @update:model-value="handleUpdateOption"
        >
          <template #dropdownButton>
            <component
              :is="billingStore.isFreeTier ? UpsellTooltip : 'div'"
              :billing-interaction="{
                cta: SHARED_LIST_CTAS.SHARED_LISTS,
                event_site: EVENT_SITES.LIST_MODAL,
              }"
              placement="bottom"
              class="c-list-modal__dropdown-button-wrapper"
              @mousedown="(e: Event) => e.preventDefault()"
            >
              <template #title> Upgrade to create a Shared List </template>
              <BittsButton
                :disabled="billingStore.isFreeTier"
                text="Create New List"
                size="x-small"
                class="w-full"
                type="neutral"
                :left-icon="['fak', 'add']"
                @click.prevent="onCreateList"
              />
            </component>
          </template>
        </BittsSelect>
        <div
          v-if="selectedListName"
          data-testid="c-add-records-modal__users-section"
          class="mb-40"
        >
          <p class="text-sm text-neutral-text-strong font-bold mb-8">
            List Access
          </p>
          <ListUserPermissionsCard
            v-for="{ user, org } in usersWithAccess"
            :key="`list-user__${user.user_id}`"
            :user="user"
            :org="org"
          />
        </div>
        <BittsCallout
          title="The sharing settings for Lists are independent of your default sharing settings"
          :subtitle="`Therefore, all information included in this List will be shared with your partner. By default, record name, website, and ${ACCOUNT_OWNER_NAME_DISPLAY} are added to the List. You can also share non-overlapping accounts with your partner.`"
          size="small"
          :icon="['fak', 'visible']"
        />
        <BittsAlert
          v-if="
            selectedListName &&
            selectedRecordsForPayload.length > numRecordsStillAvailable
          "
          class="mt-24"
          color="warning"
          :message="tooManyRecordsSelectedMessage"
          :description="tooManyRecordsSelectedDescription"
        />
      </div>
    </template>
  </BittsModal>
</template>

<script setup lang="ts">
import {
  BittsAlert,
  BittsAvatarStack,
  BittsButton,
  BittsCallout,
  BittsInput,
  BittsModal,
  BittsSelect,
  BittsTextArea,
} from '@crossbeam/bitts';
import { EVENT_SITES, SHARED_LIST_CTAS } from '@crossbeam/itly';
import { AddSelectProperties, Nullable } from '@crossbeam/types';

import axios from 'axios';
import { find, isNull, isUndefined, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import UpsellTooltip from '@/components/billing/UpsellTooltip.vue';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import useSharedList from '@/composables/useSharedList';
import { ACCOUNT_OWNER_NAME_DISPLAY } from '@/constants/mdm';
import { captureException } from '@/errors';
import {
  useBillingStore,
  useCollaborateStore,
  useFlashesStore,
  usePartnersStore,
} from '@/stores';
import { Partner } from '@/types/partners';
import { User } from '@/types/root';
import {
  ListPostPayload,
  SharedListOrg,
  SharedListUser,
} from '@/types/shared_lists';
import urls from '@/urls';

import ListUserPermissionsCard from './ListUserPermissionsCard.vue';

type SelectedRecord = {
  master_id: string;
  source_id: number;
};

type Props = {
  analyticsLocation?:
    | 'Account Mapping'
    | 'Collaborate'
    | 'IRP'
    | 'Partner Impact Cell'
    | 'Partner Impact Drawer';
  availablePartnerOrgsForList?: number[];
  selectedRecordsToAdd?: SelectedRecord[];
  text?: string;
  visible?: boolean;
};

const {
  analyticsLocation = 'Account Mapping',
  availablePartnerOrgsForList = [],
  selectedRecordsToAdd = [],
  text = '',
  visible = true,
} = defineProps<Props>();

const emit = defineEmits<(e: 'closed') => void>();

onMounted(async () => {
  await partnersStore.readySync;
  if (!isCreateListModal.value) return;
  getPartnersForList();
});

const { iteratively } = useIteratively();
const billingStore = useBillingStore();
const collaborateStore = useCollaborateStore();
const partnersStore = usePartnersStore();
const flashesStore = useFlashesStore();
const router = useRouter();
const { maxListRowsLimit } = useSharedList();

const { ready, lists } = storeToRefs(collaborateStore);

const isCreateListModal = ref(text === 'Create List');
function onCreateList() {
  isCreateListModal.value = true;
  getPartnersForList();
  if (partners.value.length === 1)
    newListState.partnerOrganization = partners.value[0]?.uuid;
}

const saveButtonText = computed(() =>
  isCreateListModal.value ? 'Share List' : 'Add to List',
);
const title = computed(() =>
  !isCreateListModal.value
    ? 'Add records to a List'
    : isSuccessModal.value
      ? ''
      : 'Create a Shared List',
);

const showModal = ref(false);
const isSuccessModal = ref(false);
const modalWidth = computed(() => (isSuccessModal.value ? 480 : 600));
const buttonDisabled = computed(
  () =>
    (isCreateListModal.value &&
      (isUndefined(newListState.name) ||
        isUndefined(newListState.partnerOrganization) ||
        isUndefined(newListState.partnerUser))) ||
    (!isCreateListModal.value && !selectedListName.value) ||
    errors.name.error === true ||
    errors.description.error === true ||
    (selectedListName.value !== undefined &&
      numRecordsStillAvailable.value < 1),
);

const partners = ref<SelectPartner[]>([]);
function getPartnersForList() {
  partners.value = sortBy(partnersStore.partnerOrgs, ['name'])
    .map((org) => ({
      ...org,
      label: org?.name,
      value: org?.uuid,
    }))
    .filter((org) => !org.offline_partner);
  if (availablePartnerOrgsForList.length) {
    partners.value = partners.value.filter((org) =>
      availablePartnerOrgsForList.includes(org.id),
    );
  }
}

const selectedRecordsForPayload = computed(() =>
  selectedRecordsToAdd.map((record) => {
    return {
      master_id: record.master_id,
      source_id: record.source_id,
    };
  }),
);

const successModalText = computed(() => {
  const numRecords = selectedRecordsForPayload.value?.length;
  if (numRecords === 1) {
    return 'Add this record to this List';
  }
  if (numRecords > 1) {
    return 'Add Selected Records to List';
  }
  return 'View List';
});

const selectedListId = ref<Nullable<string>>(null);

type SharedListForSelect = AddSelectProperties<{ id: string }>;

// for input selects
type UsersForSelect = AddSelectProperties<User & { name: string }, number>;
const partnerOrganizationUsers = ref<UsersForSelect[]>([]);
const selectedListName = ref<string | undefined>(undefined);
const listsFormatted = computed(() => {
  const availableLists: SharedListForSelect[] = [];
  collaborateStore.lists.forEach((list) => {
    const partnerOrgsInList = list.permissions.map((org) => {
      if (org.organization_id !== currentOrg.value.id)
        return org.organization_id;
    }) as number[];
    const partnersInListFromReport = partnerOrgsInList.filter((partner) =>
      availablePartnerOrgsForList.includes(partner),
    );
    if (!partnersInListFromReport.length) return;
    availableLists.push({
      id: list.id,
      value: `${list.id}__${list.name}`,
      label: list.name,
    });
  });
  return availableLists.length ? availableLists : [];
});

type UserWithAccess = {
  user: SharedListUser;
  org: SharedListOrg;
};
const usersWithAccess = ref<UserWithAccess[]>([]);

const errors = reactive({
  name: {
    error: false,
    message: '',
  },
  description: {
    error: false,
    message: '',
  },
});

// for list name
const { currentOrg, currentUser } = useAuth();
const INSERT_PARTNER_ORGANIZATION_NAME = 'Partner';
const defaultNamePrefix = `${currentOrg.value.name} & ${INSERT_PARTNER_ORGANIZATION_NAME} ${DateTime.now().toFormat('MMM yyyy')}`;

type SelectPartner = AddSelectProperties<Partner>;
type NewListState = {
  name: string | undefined;
  description: string;
  partnerOrg: SelectPartner | undefined;
  partnerOrganization: string | undefined;
  partnerUser: number | undefined;
  partnerOwner: User | undefined;
};
const newListState: NewListState = reactive({
  name: undefined,
  description: '',
  partnerOrganization: undefined,
  partnerOrg: undefined,
  partnerUser: undefined,
  partnerOwner: undefined,
});

// run logic once the user selects a partner organization
watch(
  () => newListState.partnerOrganization,
  (newPartnerOrganization) => {
    if (isUndefined(newPartnerOrganization) || isNull(newPartnerOrganization)) {
      // reset the available list of users
      partnerOrganizationUsers.value = [];
    } else {
      // map out options for the users inside this organization
      const selectedPartnerOrganization = find(partners.value, [
        'uuid',
        newPartnerOrganization,
      ]);
      newListState.partnerOrg = selectedPartnerOrganization;
      fetchPartnerUsers(selectedPartnerOrganization?.uuid as string);
      // recreate the name of the list with the new partner organization's name
      newListState.name = defaultNamePrefix.replace(
        INSERT_PARTNER_ORGANIZATION_NAME,
        selectedPartnerOrganization?.name as string,
      );
    }
  },
);

async function fetchPartnerUsers(partnerUUID: string) {
  try {
    const { data } = await axios.get(
      urls.partners.usersWithListAccess(partnerUUID),
    );
    const { items: users } = data as { items: User[] };
    partnerOrganizationUsers.value = sortBy(
      users.map((user) => {
        return {
          ...user,
          name: `${user.first_name} ${user.last_name}`,
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
        };
      }),
      ['first_name'],
    );
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'Something went wrong, reach out to our support team for help',
    });
  }
}

// validating when creating a list that it does not have the same name as a list already created
watch(
  () => newListState.name,
  (newListName) => {
    errors.name.error = false;
    const isListNameDuplicated = lists.value.some((list) => {
      const owner = collaborateStore.getOwnerByListId(list.id);
      return (
        list.name === newListName && owner?.user_id === currentUser.value.id
      );
    });
    if (isListNameDuplicated) {
      errors.name.error = true;
      errors.name.message = 'List names must be unique';
    }
    if (newListName && newListName.length > 100) {
      errors.name.error = true;
      errors.name.message = 'List names cannot exceed 100 characters';
    }
  },
  { immediate: true },
);

// validating when creating a list the description is less than 200 characters
watch(
  () => newListState.description,
  (newListDescription) => {
    errors.description.error = false;
    if (newListDescription && newListDescription.length > 200) {
      errors.description.error = true;
      errors.description.message =
        'List description cannot exceed 200 characters';
    }
  },
  { immediate: true },
);
// we need to make sure we grab the correct user
watch(
  () => newListState.partnerUser,
  (newPartnerUser) => {
    newListState.partnerOwner = partnerOrganizationUsers.value.find(
      (user) => user.id === newPartnerUser,
    );
  },
  { immediate: true },
);

const numRecordsInList = ref(0);

function handleUpdateOption(option: string) {
  if (!option) return;
  const [listId, listName] = option.split('__');
  selectedListName.value = listName;
  selectedListId.value = listId ?? null;
  const fetchedList = collaborateStore.getListById(selectedListId.value || '');
  if (!fetchedList) return;
  numRecordsInList.value = fetchedList.row_count;
  usersWithAccess.value = fetchedList.permissions.map((org) => ({
    user: org.users[0] as SharedListUser,
    org,
  }));
}

async function closeModal() {
  isSuccessModal.value = false;
  showModal.value = false;
  newListState.description = '';
  newListState.partnerOrg = undefined;
  newListState.partnerOrganization = undefined;
  newListState.partnerOwner = undefined;
  newListState.partnerUser = undefined;
  newListState.name = undefined;
  selectedListName.value = undefined;
  isCreateListModal.value = text === 'Create List';
  emit('closed');
  await collaborateStore.refreshCollaborateStore();
}

async function handleListButtonClicked() {
  if (selectedRecordsForPayload.value.length) {
    await postRecordsToList();
    closeModal();
  } else {
    await collaborateStore.refreshCollaborateStore();
    await router.push({
      name: 'collaborate_list',
      params: { id: selectedListId.value },
    });
  }
}

const numRecordsStillAvailable = computed(
  () => maxListRowsLimit.value - numRecordsInList.value,
);
const recordsToAdd = computed(() =>
  selectedRecordsForPayload.value.slice(0, numRecordsStillAvailable.value),
);
const tooManyRecordsSelectedDescription = computed(() => {
  if (numRecordsStillAvailable.value > 1) {
    return `Only the first ${numRecordsStillAvailable.value} selected accounts will be added to this List`;
  }
  if (numRecordsStillAvailable.value === 1) {
    return 'Only the first selected account will be added to this List';
  }
  return 'No more records can be added to this List';
});
const tooManyRecordsSelectedMessage = computed(() =>
  numRecordsStillAvailable.value >= 1
    ? `This List will hit the ${maxListRowsLimit.value} record limit`
    : `This List has hit the ${maxListRowsLimit.value} record limit`,
);

const newNumRecordsInList = computed(
  () => numRecordsInList.value + recordsToAdd.value.length,
);

async function postRecordsToList() {
  if (!selectedListId.value) return;
  await crossbeamApi.POST('/v0.1/lists/{list_id}/members', {
    params: {
      path: { list_id: selectedListId.value },
    },
    body: recordsToAdd.value,
  });
  iteratively.userUpdatedListRecords({
    previous_value: numRecordsInList.value.toString(),
    new_value: newNumRecordsInList.value.toString(),
    action: 'added records',
    event_site: EVENT_SITES.LIST_MODAL,
    action_location: analyticsLocation,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);
  flashesStore.addSuccessFlash({
    action: {
      async onClick() {
        collaborateStore.refreshCollaborateStore();
        await router.push({
          name: 'collaborate_list',
          params: { id: selectedListId.value },
        });
      },
      text: 'View List',
    },
    message:
      recordsToAdd.value.length > 1
        ? `${recordsToAdd.value.length} Records successfully added to List`
        : '1 Record successfully added to List',
  });
}

const isSaving = ref(false);

async function onSave() {
  if (isCreateListModal.value) {
    isSaving.value = true;
    try {
      const body = {
        name: newListState.name,
        description: newListState.description,
        permissions: [
          {
            organization_id: newListState.partnerOrg?.id,
            org_access: 'shared',
            users: [
              { user_id: newListState.partnerUser, user_access: 'owner' },
            ],
          },
        ],
      } as ListPostPayload;
      const { data } = await crossbeamApi.POST('/v0.1/lists', { body });
      selectedListId.value = data?.id ?? null;
      isSuccessModal.value = true;
      iteratively.userCreatedList({
        event_site: EVENT_SITES.LIST_MODAL,
        collaborator_id: newListState.partnerOrg?.id as number,
        collaborator_user_id: newListState.partnerUser as number,
        location: analyticsLocation,
        list_name: newListState.name as string,
        list_description: newListState?.description || '',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({
        message: 'Something went wrong, reach out to our support team for help',
      });
    }
    isSaving.value = false;
  } else {
    try {
      await postRecordsToList();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (
        err.response.data.errors[0] ===
        `Number of rows in list cannot exceed ${maxListRowsLimit.value}`
      ) {
        flashesStore.addErrorFlash({
          message: "You've reached the record limit in this list",
          description: `These records weren't added because the list was already at its ${maxListRowsLimit.value} record limit`,
        });
      } else {
        captureException(err);
        flashesStore.addErrorFlash({
          message:
            'Something went wrong, reach out to our support team for help',
        });
      }
    }
    closeModal();
  }
}
</script>
<style lang="pcss">
.c-list-modal__dropdown-button-wrapper {
  @apply flex justify-center px-4 mt-4 w-full;
  .bitts-tooltip {
    @apply w-full;
  }
}
.c-list-modal__list-name-bitts-select {
  .ant-select-disabled
    > .ant-select-selector
    > .ant-select-selection-placeholder
    > .bitts-select__placeholder {
    @apply inline-flex;
  }
}
.c-list-modal__list-name-dropdown {
  .ant-btn {
    @apply m-4;
  }
}
.c-list-modal__no-partners-tooltip {
  left: 120px !important;
  .ant-tooltip-content {
    @apply w-[320px];
  }
}
</style>

<style scoped lang="pcss">
.c-create-a-list-modal {
  h1.title {
    @apply text-xl font-bold;
  }

  .c-create-a-list-modal__content {
    @apply flex flex-col justify-between;
  }

  .c-create-a-list-modal__partner-select-label {
    @apply text-sm font-bold;
  }

  .c-create-a-list-modal__partner-select {
    @apply mb-24;

    .c-create-a-list-modal__organization-select {
      @apply my-8;
    }
  }

  .c-create-a-list-modal__callout {
    @apply mt-48 mb-24;
  }
}
</style>
