<template>
  <div>
    <ReportNotificationsModal
      v-model="notifications"
      :report-name="report && report.name"
      :show-report-notifications-modal="showReportNotificationsModal"
      :partner-population-ids="report && report.partner_population_ids"
      @notifications-modal-hidden="hideNotificationsModal"
      @input="reportsStore.refreshReportsStore"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ReportNotificationsModal from '@/components/reports/ReportNotificationsModal.vue';

import { BASE_ROUTES } from '@/constants/routes';
import { ReportNotificationConfig } from '@/interfaces/reports';
import { useReportsStore } from '@/stores';

const showReportNotificationsModal = ref(false);

const route = useRoute();
const folderId = computed(() => route.params.folder_id);

const reportsStore = useReportsStore();
const { reports } = storeToRefs(reportsStore);
const reportId = computed(() => route.query.id);
const report = computed(() =>
  reports.value.find((report) => report.id === reportId.value),
);

const notifications = computed<ReportNotificationConfig[]>(
  () =>
    (report.value?.notification_configs as ReportNotificationConfig[]) || [],
);

onMounted(async () => {
  await reportsStore.refreshReportsStore();
  showReportNotificationsModal.value = true;
});

const router = useRouter();
async function hideNotificationsModal() {
  if (!folderId.value) {
    await router.push({ name: BASE_ROUTES.REPORTS });
  } else {
    await router.push({
      name: BASE_ROUTES.REPORT_FOLDER,
      params: { folder_id: folderId.value },
    });
  }
}
</script>
