<template>
  <Notification v-if="feed" :item="item">
    <template #logo>
      <BittsSvg v-if="feed" :svg="feed.integration.type + 'Icon'" />
      <BittsAvatar
        v-else
        :is-icon="true"
        icon-slot-bg-color="bg-beta-background-medium"
        size="medium"
      >
        <template #icon>
          <FontAwesomeIcon
            :icon="['fak', 'company']"
            :style="{ height: '20px', width: '20px', color: 'white' }"
          />
        </template>
      </BittsAvatar>
    </template>
    <template #message> Connection Error </template>
    <template #description>
      <span>
        Your {{ feed.integration.friendly_name }} connection encountered an
        error
      </span>
    </template>
    <template #action>
      <BittsButton
        text="View Error"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="viewError"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'data-source']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-danger-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script setup lang="ts">
import { BittsAvatar, BittsButton, BittsSvg } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import { useFeedsStore, useNotificationsStore } from '@/stores';
import { Notification as NotificationType } from '@/types/notifications';

const { item } = defineProps<{ item: NotificationType }>();

const emit = defineEmits<(e: 'action-clicked', action: string) => void>();

const feedsStore = useFeedsStore();
const feed = computed(() => feedsStore.getFeedById(item.data?.feed_id || 0));

const router = useRouter();
const notificationsStore = useNotificationsStore();
async function viewError() {
  emit('action-clicked', 'View Error');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  if (feed.value) {
    await router.push({
      name: 'data-sources-settings',
      params: { id: feed.value.id },
    });
  } else {
    // This is for when the feed is deleted or we can't fetch the feed for some reason
    await router.push({
      name: 'data-sources',
    });
  }
}
</script>
