<template>
  <BittsLoading
    v-if="dataShare && partnerOrg?.name"
    :is-loading="loading"
    :fast-transition="true"
  >
    <template #default>
      <Notification :item="item">
        <template #logo>
          <component
            :is="avatarComponent"
            size="medium"
            class="partner-avatar"
            :org="partnerOrg"
            icon-slot-bg-color="bg-gradient-to-b from-primary-background-weak to-white"
            :is-icon="isOffline"
            :is-user-main-avatar="false"
            :show-initials="true"
          >
            <template #icon>
              <FontAwesomeIcon
                v-if="isOffline"
                :icon="['fak', 'offline']"
                class="text-primary-accent h-10 w-10"
              />
            </template>
          </component>
        </template>
        <template #message> Data Shared </template>
        <template #description>
          {{ descriptionText }}
        </template>
        <template #action>
          <BittsButton
            :text="notifInfo.buttonText"
            type="neutral"
            variant="outline"
            size="x-small"
            @click="notifInfo.buttonAction"
          />
        </template>
        <template #icon>
          <FontAwesomeIcon
            :icon="['fak', 'population']"
            :style="{ height: '12px', width: '12px', color: 'currentColor' }"
            class="text-neutral-accent ml-4"
          />
        </template>
      </Notification>
    </template>
  </BittsLoading>
</template>
<script setup lang="ts">
import {
  BittsAvatar,
  BittsAvatarPip,
  BittsButton,
  BittsLoading,
} from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { Ref, computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import { GREENFIELD_SHARING } from '@/constants/data_shares';
import { GREENFIELD_TYPE, SINGLE_OVERLAPS_TYPE } from '@/constants/reports';
import {
  IncomingDataShare,
  IncomingSharingRule,
} from '@/interfaces/data_shares';
import {
  useBillingStore,
  useDataSharesStore,
  useNotificationsStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';
import { Notification as NotificationType } from '@/types/notifications';

const { item } = defineProps<{ item: NotificationType }>();

const emit = defineEmits<(e: 'action-clicked', action: string) => void>();

const loading = ref(true);

const dataSharesStore = useDataSharesStore();
const dataShare = computed(() => {
  const objectId = item.object_id as { data_share_id?: number };
  const dataShareId = objectId?.data_share_id || item.object_id;
  return dataSharesStore.getDataShareById(dataShareId as number);
}) as Ref<IncomingDataShare | IncomingSharingRule>;

onMounted(async () => {
  if (!dataShare.value) await dataSharesStore.refreshDataSharesStore();
  loading.value = false;
});

const partnersStore = usePartnersStore();
const partnerOrg = computed(() => {
  if (!dataShare.value) return null;
  return partnersStore.getPartnerOrgById(dataShare.value?.partner_org_id || 0);
});
const isOffline = computed(() => !!partnerOrg.value?.offline_partner);

const { greenfieldSharesLookup } = storeToRefs(dataSharesStore);
const isGreenfieldNotif = computed(() => {
  return item?.data?.current_visibility === GREENFIELD_SHARING;
});
const isPartnerOrgInLookup = computed(
  () => !!greenfieldSharesLookup.value[dataShare.value?.partner_org_id],
);

const router = useRouter();
const { isFreeTier } = storeToRefs(useBillingStore());
const notificationsStore = useNotificationsStore();
async function compareWithShare() {
  emit('action-clicked', 'View Overlaps');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  await router.push({
    name: 'account_mapping.create',
    query: {
      type: SINGLE_OVERLAPS_TYPE,
      partner_id: dataShare.value.partner_org_id,
    },
  });
}
async function routeToCreateReport() {
  emit('action-clicked', 'Create Report');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  const query: {
    partner_id?: number;
    type: string;
  } = { type: GREENFIELD_TYPE };
  if (isPartnerOrgInLookup.value)
    query.partner_id = dataShare.value.partner_org_id;
  await router.push({
    name: 'account_mapping.create',
    query,
  });
}
async function routeToPartnerPage() {
  emit('action-clicked', 'View Sharing');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  await router.push({
    name: 'partner_details',
    params: { partner_org_id: dataShare.value.partner_org_id },
    query: { tab: 'shared_with_you', from: 'partners' },
  });
}
const notifInfo = computed(() => {
  if (isGreenfieldNotif.value && isFreeTier.value) {
    return {
      buttonText: 'View Sharing',
      descText: 'shared Greenfield accounts',
      buttonAction: routeToPartnerPage,
    };
  }
  if (isGreenfieldNotif.value) {
    return {
      buttonText: 'Create Report',
      descText: 'shared Greenfield accounts',
      buttonAction: routeToCreateReport,
    };
  }
  return {
    buttonText: 'View Overlaps',
    descText: 'shared data',
    buttonAction: compareWithShare,
  };
});

const populationsStore = usePopulationsStore();
const partnerPopulation = computed(() => {
  if (!dataShare.value) return null;
  return populationsStore.getPartnerPopulationById(
    dataShare.value.partner_population_id,
  );
});

const avatarComponent = computed(() =>
  isOffline.value ? BittsAvatarPip : BittsAvatar,
);
const descriptionText = computed(() => {
  if (isOffline.value)
    return `Your offline partner ${partnerOrg.value?.name} has a new ${partnerPopulation.value?.name} Population`;
  return `${partnerOrg.value?.name} has ${notifInfo.value.descText} from their ${partnerPopulation.value?.name} Population with you`;
});
</script>
