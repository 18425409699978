<template>
  <BittsLoading :is-loading="loading">
    <div class="c-team-access-tab">
      <div class="c-team-access-tab__section">
        <div class="c-team-access-tab__section-title">Roles</div>
        <div v-if="hasManageVisibilityPermission" class="text-neutral-text">
          If you enable access to a role, their permissions will be inherited
          from Roles & Permissions.
        </div>
        <div
          v-for="role in roles"
          :key="role.role_id"
          class="c-team-access-tab__row"
        >
          <div class="flex items-center gap-8">
            <div class="c-team-access-tab__role-icon">
              <FontAwesomeIcon :icon="['fad', 'user-group']" />
            </div>
            <div class="font-bold">{{ role.role_name }}</div>
          </div>
          <div class="flex items-center gap-20">
            <div class="text-neutral-text">
              {{ getPermissionsText(role.role_id) }}
            </div>

            <BittsSwitch
              v-model="roleToggles[role.role_name]"
              :disabled="
                !role.permission_removable ||
                loading ||
                !hasManageVisibilityPermission
              "
            />
          </div>
        </div>
      </div>
      <div class="c-team-access-tab__section">
        <div class="c-team-access-tab__section-title">People</div>
        <div v-if="hasManageVisibilityPermission" class="text-neutral-text">
          If you add a user, their permissions will be inherited from Roles &
          Permissions.
        </div>
        <div
          v-if="hasManageVisibilityPermission"
          class="c-team-access-tab__search-wrapper"
        >
          <BittsInput
            ref="userSearchTemplateRef"
            v-model="searchUserInputValue"
            type="search"
            data-testid="user-search"
            name="user-search"
            placeholder="Search people"
            @focus="checkToOpenDropdown"
            :disabled="loading"
          />
          <div
            v-if="showUserDropdown && searchUserInputValue.trim().length > 0"
            class="c-team-access-tab__search-dropdown"
          >
            <div
              v-if="filteredUsers.length === 0"
              class="c-team-access-tab__search-item"
            >
              No user found
            </div>
            <div
              v-else
              v-for="(user, idx) in filteredUsers"
              :key="idx"
              data-testid="user-search-item"
              @click="onSelectPeople(user.user)"
              @mouseover="currentIdx = idx"
            >
              <BittsTooltip :placement="Placement.TopLeft">
                <div
                  class="c-team-access-tab__search-item"
                  :class="[
                    isUserAlreadyAdded(user.user) &&
                      'c-team-access-tab__search-item--disabled',
                  ]"
                >
                  <div class="flex items-center gap-8">
                    <BittsAvatar :user="user.user" size="x-small" />
                    <div>
                      {{ getUserName(user.user) }}
                    </div>
                    <div class="text-neutral-text-weak">
                      {{ user.user.email }}
                    </div>
                  </div>
                  <div class="flex items-center gap-8">
                    <BittsTag
                      :text="getRoleName(user.role_id)"
                      size="x-small"
                    />
                    <div class="text-neutral-text">
                      {{ getPermissionsText(user.role_id) }}
                    </div>
                  </div>
                </div>
                <template v-if="isUserAlreadyAdded(user.user)" #title>
                  User already added
                </template>
              </BittsTooltip>
            </div>
          </div>
        </div>
        <div
          v-for="(authorizedUser, idx) in authorizedUserList"
          class="c-team-access-tab__row"
          :key="authorizedUser.user_id"
        >
          <div class="flex items-center gap-8">
            <BittsAvatar
              :user="authorizedUser.authorization?.user"
              size="copilot"
            />
            <div class="flex flex-col gap-4">
              <div class="flex items-center gap-8">
                <div class="font-bold">{{
                  getUserName(authorizedUser.authorization?.user)
                }}</div>
                <BittsTag
                  :text="getRoleName(authorizedUser.authorization?.role_id)"
                  size="x-small"
                />
              </div>
              <div class="text-neutral-text text-12">
                {{ authorizedUser.authorization?.user.email }}
              </div>
            </div>
          </div>
          <div class="flex items-center gap-20 text-neutral-text">
            {{ getPermissionsText(authorizedUser.authorization?.role_id) }}
            <BittsButton
              v-if="hasManageVisibilityPermission"
              text="Remove"
              size="x-small"
              variant="outline"
              @click="onRemovePeople(idx)"
              :disabled="loading"
            />
          </div>
        </div>
      </div>
    </div>
  </BittsLoading>
</template>

<script setup lang="ts">
import {
  BittsAvatar,
  BittsButton,
  BittsInput,
  BittsLoading,
  BittsSwitch,
  BittsTag,
} from '@crossbeam/bitts';
import BittsTooltip from '@crossbeam/bitts/src/BittsTooltip/BittsTooltip.vue';
import { Placement } from '@crossbeam/bitts/src/types';

import { computed, ref } from 'vue';

import useAuth from '@/composables/useAuth';
import { usePartnershipTeamAccess } from '@/composables/usePartnershipTeamAccess';
import { TeamAuthorizationUser } from '@/types/team';

const { partnerUuid } = defineProps<{
  partnerUuid: string | undefined;
}>();

const {
  authorizedUserList,
  filteredUsers,
  isUserAlreadyAdded,
  loading,
  onRemovePeople,
  onSelectPeople,
  roles,
  roleToggles,
  searchUserInputValue,
  showUserDropdown,
} = usePartnershipTeamAccess(partnerUuid, true);

const { hasPermission } = useAuth();
const hasManageVisibilityPermission = computed(() =>
  hasPermission('manage:partner-visibility'),
);

const currentIdx = ref(-1);
const userSearchTemplateRef = ref(null);

function checkToOpenDropdown() {
  showUserDropdown.value = true;
}

/* Helpers */

function getPermissionsText(roleId: string | null | undefined) {
  const role = roles.value.find((role) => role.role_id == roleId);
  const getLevel = (resource: string) =>
    role?.permissions.find((perm) => perm.resource === resource)?.level;

  const permissions = [];

  const partnerVisibilityLevel = getLevel('partner-visibility');
  if (!partnerVisibilityLevel) {
    permissions.push('No access');
  } else {
    permissions.push(
      partnerVisibilityLevel === 'manage' ? 'Manage access' : 'View',
    );
    const sharingDataLevel = getLevel('sharing');
    if (sharingDataLevel === 'manage') permissions.push('Share Data');
  }

  return permissions.join(', ');
}

function getRoleName(roleId: string | null | undefined) {
  const role = roles.value.find((role) => role.role_id == roleId);
  return role?.role_name;
}

function getUserName(user: TeamAuthorizationUser | undefined) {
  return `${user?.first_name} ${user?.last_name}`;
}
</script>

<style lang="pcss">
.c-team-access-tab {
  @apply flex flex-col gap-24 p-24;
}

.c-team-access-tab__section {
  @apply flex flex-col gap-6;
}

.c-team-access-tab__section-title {
  @apply text-neutral-text-strong font-bold text-lg;
}

.c-team-access-tab__row {
  @apply flex justify-between py-9;
}
.c-team-access-tab__role-icon {
  @apply w-40 h-40 flex justify-center items-center rounded-full bg-secondary-background-weak text-16;
}

.c-team-access-tab__search-wrapper {
  @apply relative;
}

.c-team-access-tab__search-dropdown {
  @apply w-full absolute shadow-component bg-white border border-neutral-border rounded-8 top-44 z-20 p-4;
}

.c-team-access-tab__search-item {
  @apply w-full flex justify-between items-center px-8 py-4 cursor-pointer hover:bg-denim-50 hover:rounded-4;
}

.c-team-access-tab__search-item--disabled {
  @apply text-neutral-text-weak cursor-not-allowed hover:bg-transparent;
}
</style>
