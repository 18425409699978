<template>
  <div class="c-template-tooltip">
    <div
      v-if="!isSatisifed && !missingObjects.length > 0"
      data-testid="missing-title"
      class="mb-8"
    >
      We don't have read access for some fields in this preset
    </div>
    <div
      v-for="(object, i) in missingObjects"
      :key="`${object}__${i}`"
      class="missing-object-title"
    >
      <span
        >❌ You must have the {{ startCase(object) }} object to use this
        preset</span
      >
    </div>
    <div class="flex flex-col gap-8 p-8">
      <div
        v-for="([object, columns], i) in presentObjects({
          info: template[crm],
          columnToFriendlyNameMap,
        })"
        :key="object"
      >
        <div
          class="present-object-title"
          v-if="!missingObjects.includes(object)"
        >
          {{ startCase(object) }}
        </div>
        <div class="flex flex-wrap justify-start items-center gap-4">
          <div
            v-for="(column, j) in objectColumnsSortedByNickname({
              object,
              columns,
              columnToFriendlyNameMap,
            })"
            :key="`${i}_${j}`"
          >
            <BittsTag
              v-if="fieldMissing({ column, object })"
              class="c-template_tooltip_content__tag missing-field"
              variant="rounded"
              size="x-small"
              color="danger"
            >
              <template #pre>
                <FontAwesomeIcon
                  :icon="['fak', 'x']"
                  class="text-danger-accent text-12"
                />
              </template>
              {{ columnToFriendlyNameMap[object][column] || column }}
            </BittsTag>
            <BittsTag
              v-else
              class="c-template_tooltip_content__tag"
              variant="rounded"
              size="x-small"
            >
              {{ columnToFriendlyNameMap[object][column] }}
            </BittsTag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { BittsTag } from '@crossbeam/bitts';

import { startCase } from 'lodash';
import { computed, inject } from 'vue';

import useDataTemplates from '@/composables/useDataTemplates';

const props = defineProps({
  isSatisifed: {
    type: Boolean,
    default: false,
  },
  template: {
    type: Object,
    required: true,
  },
});

const { objectColumnsSortedByNickname, presentObjects } = useDataTemplates();

const crm = inject('crm');
const unsatisfiedTemplateMetadata = inject('unsatisfiedTemplateMetadata');
const columnToFriendlyNameMap = inject('columnToFriendlyNameMap');

const missingObjects = computed(() => {
  const t = props.template[crm];
  return Object.keys(t).filter(
    (object) =>
      !columnToFriendlyNameMap.value[object] ||
      Object.keys(columnToFriendlyNameMap.value[object]).length === 0,
  );
});

function fieldMissing({ column, object }) {
  const template = unsatisfiedTemplateMetadata.value.find(
    (t) => t.type === props.template.type,
  );
  if (!template) return false;
  const matchingObject = template.missingTemplateInfo[object];
  if (!matchingObject) return false;
  return matchingObject.includes(column);
}
</script>

<style lang="pcss" scoped>
.c-template-tooltip {
  @apply p-16;
  .present-object-title {
    @apply text-neutral-text-weak text-sm mb-4;
  }
}
</style>

<style lang="pcss">
.c-template_tooltip_content__tag.bitts-tag {
  .ant-tag {
    @apply flex items-center gap-4;
  }
}
</style>
