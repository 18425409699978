<template>
  <Notification v-if="partnerPop" :item="item">
    <template #logo>
      <BittsAvatar :org="partnerOrg" :show-initials="true" size="medium" />
    </template>
    <template #message> Population Revealed </template>
    <template #description>
      {{ partnerOrg?.name }} has revealed their {{ partnerPop.name }} Population
      to you
    </template>
    <template #action>
      <BittsButton
        text="Request Data"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="requestData"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'visible']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script setup lang="ts">
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import {
  useDataRequestsStore,
  useNotificationsStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';
import { Notification as NotificationType } from '@/types/notifications';

const { item } = defineProps<{ item: NotificationType }>();

const emit = defineEmits<(e: 'action-clicked', action: string) => void>();

const populationsStore = usePopulationsStore();
const partnerPop = computed(() => {
  return populationsStore.getPartnerPopulationById(
    item.data?.population_id || 0,
  );
});

const partnersStore = usePartnersStore();
const partnerOrg = computed(() => {
  if (!partnerPop.value) return null;
  return (
    partnersStore.getPartnerOrgById(partnerPop.value?.organization_id) ?? null
  );
});

const dataRequestsStore = useDataRequestsStore();
const shareRequestExists = computed(() => {
  if (!partnerOrg.value || !partnerPop.value) return;
  return !!dataRequestsStore.getOutboundShareRequestByPartnerAndPop(
    partnerOrg.value?.id,
    partnerPop.value?.id,
  );
});

const router = useRouter();
const notificationsStore = useNotificationsStore();
async function requestData() {
  emit('action-clicked', 'Request Data');
  await notificationsStore.markRead({ id: item.id, isRead: true });
  const query: {
    tab: string;
    partnerPopId?: number;
  } = { tab: 'shared_with_you' };
  if (!shareRequestExists.value && !!partnerPop.value?.id)
    query.partnerPopId = partnerPop.value.id;
  await router.push({
    name: 'partner_details',
    params: { partner_org_id: partnerOrg.value?.id },
    query,
  });
}
</script>
